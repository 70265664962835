import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    providerInfo: localStorage.getItem('providerInfo')
      ? JSON.parse(localStorage.getItem('providerInfo'))
      : {}
  },
  mutations: {
    setProviderInfo(state, data) {
      console.log(data)
      localStorage.setItem('providerInfo', JSON.stringify(data))
      state.providerInfo = data
    }
  },
  actions: {}
})
