import api from '@/api'

export const exit = function() {
  window.localStorage.clear()
  this.$router.push({ name: 'login', params: { num: 1, force: true } })
  api
    .exitLogin()
    .then(res => res.data)
    .then(() => {
      window.localStorage.clear()
      var num = Math.ceil(Math.random() * 10)
      this.$router.push({ name: 'login', params: { num, force: true } })
    })
}

export const addCommas = function(value) {
  if (value == undefined) {
    return ''
  }
  value += ''
  const x = value.split('.')
  var x1 = x[0]
  const x2 = x.length > 1 ? '.' + x[1] : ''
  const rgx = /(\d{3})(?=\d)/g
  var x3 = ''
  if (rgx.test(x1)) {
    var matchs = x1.match(/[^\d]/)
    x1 = parseInt(x1).toLocaleString()
    if (matchs && matchs.length > 0) {
      x3 = matchs[0]
    }
  }
  return x1 + x2 + x3
}

export const handleAuthUpload = function() {}

// 存用户保险卡信息
export const bindCardList = data => {
  let cardList = []
  data.forEach(e => {
    cardList.push(e)
    if (e.subCardList && e.subCardList.length) {
      cardList.push(...e.subCardList)
    }
  })
  localStorage.setItem('cardList', JSON.stringify(cardList))
}
