import Vue from 'vue'
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import { notification, message } from "ant-design-vue";

export default () => {
Vue.use(Antd);

window.notification = notification;
window.message = message;

}