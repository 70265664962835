import gwEn from './gw-en-us'

export default {
  ...gwEn,
  title: 'MediLink-Global (Beijing) TPA Services Co. Ltd.',
  login: {
    a: 'Welcome to the management platform of medical institutions',
    b: 'powerful ability of data reduction',
    c: 'Professional management platform for medical institutions',
    d: 'Registered NO. ',
    e: 'username send password-reset email',
    f: 'send password-reset email',
    g: 'click the link to reset password',
    h: 'Send a password-reset email',
    dl: 'Log in',
    wj: 'Forgot your password?',
    yhm: 'Username',
    mm: 'Password',
    yzm: 'Verification code',
    xgmm: 'Change your password',

    // 中间带公众号PC

    welcome: 'Welcome to the MediLink membership service system',
    lWelcome:
      '<p style="text-align: center;position: absolute;left: -40px;top: -65px;">Welcome<br/>to the</br>MediLink<br/>membership<br/>service system</p>',
    loType: 'Account password login',
    aPlaceholder: 'Please enter the Insured number beginning with 8000',
    apwd:
      'Please enter your password (the initial password is the last six digits of your id)',
    remember: 'Remember the login',
    forgot: 'Forgot password',
    login: 'login',
    loginout: 'Log out'
  },
  dashboard: {
    upload: 'Claim Batch upload',
    documents:
      'Please put claim documents as below sequence: claim form (with claim number) + bill (invoice) + medical record (test report) + others',
    format: 'Please use JPG format',
    claimDocuments: 'Claim documents',
    selectFile: 'Select file',
    remark: 'Remark',
    confirm: 'Confirm',
    cancel: 'Cancel',
    zipNotEmpty: 'File cannot be empty',
    tips: 'upload successfully.'
  },
  menus: [
    'Image Operation',
    'Create Bar Code',
    'ORC Discern',
    'Benefit Queries',
    'Medical Records',
    'Prior Authorization',
    'Business queries'
  ],
  labels: [
    'Search by item', //0
    'Healthcard No',
    'I/C No.',
    'Patient name',
    'Policy No.', // 4
    'Medical record No',
    'All records',
    'Not recieved',
    'RFE(Request for evidence)',
    'NO invoice',
    'Transaction No. of Medilink', // 10
    'Service date',
    'All authorizations', // 全部授权
    'Issued for approval',
    'Approved', // 14
    'Refused', //15
    'Validity Period',
    'Exp.',
    'Direct billing area',
    'Direct billing or not',
    '-', //20
    '+',
    'Upper limit each time',
    'Pre-authorization',
    'Annual quota',
    'Deductible', //25
    'Compensation ratio',
    'Times limit',
    'Expense list',
    'Sign out',
    'Business Search', //30
    'Welfare Search',
    'Medical record',
    'No limit',
    'ID card',
    'Passport', //35
    'other',
    'Medical Dedicated Line',
    'Enter disease (ICD) codes or key words',
    'Print payment reciept',
    'Please provide medical records and AIDS and syphilis examination reports.', //40
    'Choose',
    'Sub-limit.',
    'The following expenses are subject to annul limit',
    'choose the department',
    'Waiting period', //45
    'Surplus',
    'Share Limits',
    'The following fees are shared ',
    'Daily ',
    'Annual ', //50
    'Monthly ',
    'Limit ',
    'Used Quota ',
    'Used ',
    'physicain', // 55
    'Export payment reciept',
    'Reconsideration Reason',
    'Private Remark',
    'Files',
    'Member remark', // 60
    '',
    '',
    'Member remark',
    '',
    '', // 65
    'Vaccine cost: ',
    'Consumed {amount} CNY'
  ],
  tips: [
    'It is recommended that you change your password regularly to ensure account security.', //0
    'upload sucessfully',
    'Current password',
    'Type the current password.',
    'new password',
    'confirm the new password', //5
    'last step',
    'confirm password change',
    'years old',
    'Upload',
    'Please enter the healthcard No', //10
    'Type ID No.',
    'Type client name',
    'Type policy No.',
    'Type medical record No.',
    'Member', //15
    'Hospital',
    'Save',
    'View details',
    'Edit',
    'Error', //20
    'please enter',
    'Discount amount',
    'Amount after discount',
    'Submit',
    'Quantity', //25
    'Edit successfully',
    'Start time',
    'End time',
    'Type to search',
    'annual hospital stays', //30
    'Imaging',
    'Bar code bulk generation',
    'OCR imaging correction',
    'Cancel successful',
    'No result', //35
    'Type amount charged',
    'Choose',
    'Interface data return abnormal',
    'Password changed, please log in again',
    'Type numbers after "#" on the APP voucher', //40
    'E-mail sent, resending available in 60 sec',
    'Go back', //42
    'Choose file for upload',
    'Generate bar code',
    'Download template', //45
    'Choose file for upload',
    'Amount occurred', //47
    'Image type',
    'Type case number for image',
    'Image list', //50
    'Refresh',
    'Expense list',
    'Problem only',
    'Expense description',
    'Edit major expense', //55
    'Major expense code',
    'Expense detail affiliation code',
    'Unit price',
    'Specification',
    'Claim Number', //60
    'Example'
  ],
  judge: [
    'RFE', //0
    'Approve authorization',
    'Refuse authorization'
  ],
  btns: [
    'Query', //0
    'Manual service',
    'Welfare Check',
    'Check all records',
    'Export data', // 4
    'Bulk print claim explanations', //5
    'Print payment receipt', // 打印
    'Cancel claim',
    'Yes',
    'No', // 9
    'Upload invoices', //10
    'Upload materials',
    'Change amount',
    'print claim explanations',
    'More', // 14
    'settle account',
    'print pre-authorization form', // 授权
    'print rejection notice of prior authorization',
    'Reset',
    'Reconsider', // 19
    'Batch Confirmation',
    'Cancel'
  ],
  p: [
    'After the claim is cancelled,it becomes invalid without regret. Whether cancel?', //0
    'After the claim is cancelled,it becomes invalid without regret. Do you wish to continue?'
  ],
  cols: [
    'Patient name', //0
    'Date of Birth',
    'Gender',
    'I/C No.',
    'Healthcard No.', // 4
    'Policy No', //5
    'Payor',
    'Operation',
    'Corporate'
  ],
  claimCols: [
    'Claim No.', //0
    'Amount incurred',
    'Amount approved',
    'Self-paid',
    'Already paid by Insured', // 4
    'Paid by Insured receivable', //5
    'Date of payment by Medilink',
    'Isurer',
    'Isured liability',
    'Conclusion', // 9
    'Audit progress'
  ],
  preAuthCols: [
    'Athorization No', //0
    'Estimated date of treatment',
    'Estimated charge',
    'Estimated days',
    'Condition of authorization', // 4
    '',
    '',
    '',
    '',
    '', // 9
    '',
    'Pre-Athorization No'
  ],
  claimDetail: [
    'Medical information', //0
    'Hospital',
    'Days in hospital',
    'Discharge date',
    'Currency', // 4
    'Exchange rate', //5
    'Relationship',
    'Email',
    'Diagnosis',
    'Claims information', // 9
    'Compensatory conclusion', //10
    'Responsibility',
    'Fee',
    'Amount incurred',
    'Discount amount', // 14
    'Amount after discount', //15
    'Total',
    'Audit',
    'payment condition',
    'Date of payment', // 19
    'ID type', //20
    'Age',
    'Principle beneficiary', //22
    'Phone Number',
    'Pre-authorization information', // 24
    'pre-authorization No.', //25
    'Approval deposit',
    'Days approved',
    'Responsibility',
    'Department Details'
  ],
  Modals: [
    'settle the accounts of outpatients', //0
    'Amount change of outpatients',
    'I/C No.',
    'Operation date',
    'Department',
    'Default', //5
    'Billing information.',
    'Diease code',
    'Common diseases',
    'settle accouts',
    'Change', //10
    'Medical record',
    'print',
    'Cancel',
    'Confirm',
    'Pieces', //15
    'Days',
    'Times',
    'yuan',
    'Female',
    'Male', //20
    'Choose',
    'Authentication',
    'Outpatient Reimbursement Claim Form of Direct Billing Providers',
    'Inpatient Reimbursement Claim Form of Direct Billing Providers',
    'Policy information', //25
    'Beneficiary',
    'Diagnosis',
    'Payment endorsement',
    '',
    '', //30
    '',
    '',
    '',
    '',
    '', //35
    '',
    '',
    'Member Class', //38
    'Pre License Remark', //39
    'select Physicain',
    '',
    '',
    '',
    '',
    '', // 45
    '',
    '',
    'Apply for pre-authorization',
    'Continue to apply',
    'Be hospitalized', // 50
    'Application for hospitalization pre-authorization',
    'Admission date',
    'Discharge date',
    'Estimated amount',
    'Medical record data' // 55
  ],
  colon: ': ',
  //pc公众号-->
  index: [
    'My Information', //0
    'Effect',
    'Insurance Plans',
    'View',
    'Modify', // 4
    'View Claims and EOBS',
    'Pre-authorization',
    'Healthcare Providers',
    'Click to view the list of direct billing hospital',
    'Appointment registration / physical examination',
    'Click to query the reservation list', // 10
    '',
    '', //
    '',
    '', // 14
    '',
    '',
    '',
    '',
    '',
    '', // 20
    '',
    '',
    '',
    '',
    '', // 25
    '',
    '', // 27
    '',
    '',
    '', // 30
    '', // 31
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ],
  menuList: [
    'Home',
    'My Account',
    'My Information',
    'Insurance Plans',
    'Change Password',
    'Member Resources',
    'View Claims and EOBS',
    'Pre-authorization',
    'Healthcare Providers',
    'Other Functions',
    'File Download', //10
    'Contact Us',
    'System Settings',
    'Features',
    'Doctor Reviews and Rating ',
    'Appointment List'
  ],
  onlineChat: 'Contact Us',
  OnlineCusSerCenter: 'Online Customer Service',
  territory: 'China Hotline',
  abroad: 'International Hotline',
  enter: 'Online service:',
  moduleList: {
    Index: 'Home',
    Info: 'My Account/Personal Information',
    Account: 'My Account/Insurance Plans',
    UpdatePwd: 'My Account/UpdatePwd',
    ClaimsBusiness: 'Member Resources/ Claim Processing',
    Benefits: 'My Account/Insurance Plans/Benefit',
    ClaimApply: 'Member Resources/ Claim Processing /Claim Application',
    ClaimQuery: 'Member Resources/Claim Processing/Claim Status Query',
    PreAuthBusiness: 'Member Resources/ Pre-auth Processing',
    PreAuthApply: 'Member Resources/ Pre-auth Processing /Pre-auth Application',
    PreAuthQuery: 'Member Resources/Pre-auth Processing/Pre-auth Status Query',
    HospitalList: 'Member Resources/ HospitalList',
    HospitalDetail: 'Member Resources/ HospitalList/ Hodpital Detail',
    PreDetail: 'Pre-auth Processing /Pre-auth Status Query/Pre-auth Detail',
    PreAuthDetail: 'Pre-auth Processing /Pre-auth Status Query/Pre-auth Detail',
    ClaimDetail: 'Claim Processing /Claim Status Query/Claim Detail',
    AppClaimDetail: 'Claim Processing /Claim Status Query/Claim Detail',
    ClaimUnPaidQuery:
      'Claim Processing /Self-payment amount/Self-payment amount query',
    ClaimSupplement: 'Member Resources/Claim Processing/Supplement materials',
    ClaimSupplementDetail:
      'Member Resources/Claim Processing/Supplement materials/Supplement materials detail',
    MedicalRecords: 'Member Resources/Medical Records ',
    SubscribeLists: 'Member Resources/Appointment List ',
    SubscribeDetails: 'Member Resources/Appointment List/Appointment Details',
    Subscribe: 'Member Resources/Appointment List/Fill in the appointment form',
    MedicalEvaluation: 'Member Resources/Medical Records Submit',
    PreAuthSupplement:
      'Member Resources/Pre-auth Processing/Supplement materials',
    PreAuthSupplementDetail:
      'Member Resources/Pre-auth Processing/Supplement materials/Supplement materials detail',
    FileDownload: 'Other functions / Files Download',
    ClaimUnPaidDetail:
      'Claim Processing /Self-payment amount/Self-payment amount query /Self-payment amount detail'
  },
  InsuranceInfo: [
    'Valid policy', //0
    'Policy number',
    'Principle Insured',
    'Add the Insured of dependent insured',
    'Benefit', // 4
    'Member Card',
    'Policy-holder',
    'Insurance products',
    'Validity of policy',
    'Dependent Insure',
    'History policy', // 10
    'Input policy number/card number',
    'Please enter the Insured number beginning with 8000', //
    'Please enter your password (the initial password is the last six digits of your id)',
    'Save', // 14
    'Cancel',
    'Plan number',
    'Insurer',
    'Coverage area',
    'Direct Billing Area',
    'Policy currency type', // 20
    'lifetime limit (currency)',
    'Total annual limit (currency)',
    'Restrict of medical institutions',
    'fold',
    'unfold', // 25
    'Prior Authorization Notes',
    'Exclusions statement', // 27
    'Insurance liability',
    'Insurance clause',
    'Download', // 30
    'Liability category', // 31
    'Waiting period',
    'Compensation standards'
  ],
  claim: [
    'Please pay attention to the followings before submitting your application!', //0
    '1.Do not submit the claim for the same medical treatment repeatedly, The same disease of the same medical institution on the same day is regarded as one claim',
    '2.Online claims submission only applicate to claim amount less than 3000RMB (including). If the claims amount over 3000 RMB please submit offline. The detailed mailing address plese refer to Member Guide.',
    '3.To ensure that you receive your claim as soon as possible,Please fill in the bank information according to the rules of bank name + city + branch name Example: China merchants bank guangzhou huacheng sub-branch',
    '4.Please confirm the name of the insurer and submit claims in the correct insurer to ensure reinbursesstment received ASAP', // 4
    'Next',
    'Patient name',
    'Insurance period ',
    'Applicant',
    'Relationship to the Patient',
    'Please select Insurer', // 10
    'Provider Name ',
    'Please enter the name of the medical institution', //
    'Date of visit',
    'Please select the date of visit', // 14
    'Insurance liability',
    'Please select the insurance liability',
    'Amount Requested',
    'Amount Requested',
    'Bank Card Information',
    'Bank Info', // 20
    'the main card',
    'Uninstall main card',
    'delete',
    'Set main card',
    'Add bank card information', // 25
    'ON',
    'OFF', // 27
    'Please add your own bank card, and make sure that your name, ID number and bank card number are exactly the same as the bank reservation information.',
    'Account name:',
    'Please fill in account name', // 30
    'ID No:',
    'Please fill in ID No.',
    'Bank card number:',
    'Please fill in your bank card number', //34
    'Bank name:',
    'Location:',
    'Bank branch:', //37
    'Last',
    'Remark',
    'Requested Documents', //40
    'CLAIM REQUEST FORM',
    'Preview',
    'INVOICE',
    'MEDICAL RECORDS AND PRESCRIPTION, CERTIFICATE OF DIAGNOSIS', //44
    'STATEMENT OF EXPENSES', //45
    'EXAMINATION, LAB TEST RESULT REPORT',
    'PRE-AUTHORIZATION CONFIRMATION FORM',
    'VALID SCAN COPY',
    'Confirm to submit',
    'Return to modify', //50
    'To successfully pass the audit Please provide the following materials ,The dot item is mandatory input field',
    'Example',
    'Upload file',
    'Surrender date', //54
    'I have read and agree',
    'Anti-insurance fraud agreement ,',
    'Claim application confirmation clause',
    'Please agree to read the instructions',
    'OK',
    'To successfully pass the audit Please provide the following materials',
    'Currency:',
    'Please select currency',
    'Swift code:',
    'Please input swift Code',
    'Location Of Medical Institution',
    'Please choose the location of medical institution'
  ],
  bankInfo: [
    'Bank Name',
    'Sub-branch Name',
    'Bank card Number',
    'Operation',
    'Account Name', // 5
    'Bank Card Info',
    'Bank Area',
    'Select the currency',
    'Bank Name',
    'Bank Address',
    'Account Name',
    'Principal',
    'Guardian',
    'Currency',
    'USD',
    'RMB',
    'HK$',
    'Bank Country',
    'Bank Location Country',
    'Enter the swfit code',
    'Enter the bank name',
    'Please enter the bank address',
    'Bank area:'
  ],
  claimStep: [
    'Claims Instruction',
    'Fill in Request From',
    'Upload Documents',
    'Complete'
  ],
  relationship: ['oneself', 'spouse', 'children', 'parents'],
  medicalLocation: ['Domestic', 'Abroad'],
  personalInfo: [
    'Basic Information',
    'Save',
    'Name',
    'Gender',
    'Please fill in gender', //4
    'Country',
    'Please fill in country',
    'Occupation',
    'Please enter your Occupation',
    'Email', //9
    'Please enter your email address',
    'Mobile Phone',
    'Please fill in mobile phone number',
    'Address',
    'Province ', //14
    'City', //15
    'Area',
    'Detailed address',
    'Please fill in detailed address',
    'Certificate Information',
    'ID Type', //20
    'ID card',
    'passport',
    'Other',
    'ID Number',
    'Please fill in ID Number', //25
    'Validity of Certificate',
    'Please select',
    'Upload ID',
    'uploaded',
    'Not uploaded', //30
    'Please select your bank',
    'Please select bank province or cityy',
    'Please select or fill in branch',
    'Reset the password',
    'Please enter your Insured number', //35
    'Please enter your name',
    'Please enter the last six digits of your ID card',
    'Reset password',
    'Change login password',
    'Original password ', //40
    'New password ',
    '8-20 alphabetic and numeric combinations, case sensitive',
    'Confirm password',
    'Enter password again',
    'Please enter original password', //45
    'Please enter new password',
    'Please enter password again',
    'Permanent address',
    'If you log in for the first time, you cannot reset your password',
    'Please contact 24-hour online customer service for support',
    '24-hour online customer service：+8610 65525313'
  ],
  claimDetails: [
    'Reim',
    'NO.',
    'Change account',
    'Cancel application',
    'Back', //4
    'Case status',
    'Time',
    'Claim details',
    'New claim application',
    'Claims status', //9
    'Claims submission progress, history records list', //10
    'Claim application',
    'Outpatient benefit, Hospitalization benefit, Hospitalization allowance',
    'Self-payment amount',
    'To pay the amout for own expenses',
    'Self-Service Guideline', //15
    'Operation guide, Requested Materials, Workflow',
    'Supplement materials',
    "Additional uploading necessary materials requested by Claim's team regarding to certain issue",
    'Documents Download',
    'Necessary documents for claims application', //20
    'Total',
    'pages',
    'Please enter your Insured number',
    'Pre-auth', //24
    'Outstanding payment',
    'Total',
    'immediate payment',
    'Authorization number',
    'diagnosis', //29
    'Pre-auth details'
  ],
  alertPrompt: [
    'Note',
    'After the revocation, you will no longer be able to edit the case',
    'Revocate',
    'Cancel',
    'Please handle it as follow function button',
    'Service Guideline', //5
    'View the details',
    'Please enter your other requirements  ( 125 characters)',
    'Please upload those with . are required',
    'Please select the relationship with the insurer',
    'Medical institution, please enter 0~400 characters', //10
    'Please select the date of treatment',
    'Please select insurance liability',
    'Please fill in the application amount',
    '',
    '', //15
    'Please enter a number greater than 0',
    'Date of visit is not covered',
    'Remarks: Please input 0 ~ 125 Chinese characters, numbers or letters',
    'Please select pre-auth category',
    'Please enter estimated days of hospitalisation', //20
    'Please enter estimated cost for the treatment',
    'Please select diagnosis',
    'Please enter name',
    'Please select gender',
    'Please enter mobile phone',
    'Please enter verification code',
    'Please select ID type',
    'Please enter Id number',
    'Please select valid period'
  ],
  statusText: [
    'All',
    'Pending to submit',
    'Under review',
    'Case closed',
    'Audit completed',
    'A year', //5
    'Half a year',
    'Three months',
    'Wait Pay',
    'Paid',
    'Unsettlement', //10
    'Settled',
    'Withdrawn',
    'Compensation',
    'Dishonor'
  ],
  preauthText: [
    'Pre-auth Application',
    'Please refer to the requested items stipulated in the insurance clauses',
    'Supplemental Materials',
    'Additional Information requested for pre-authorzation application',
    'Pre-authorization Status',
    'Progress of Authorization, History Records', //5
    'Service Guide',
    'Operation Instruction, Requested Materials, Process Guidelines',
    'Documents Download',
    'Necessary documents for Pre-authorization application',
    '', //10
    '',
    '',
    '',
    '',
    '' //15
  ],
  medicalInstitutionSearch: {
    all: 'All',
    area: 'Location',
    plan: ' All Products',
    property: 'Nature',
    class: 'Category',
    level: 'Level',
    placeHolder: 'Hospital name',
    hProperty: 'Hospital nature',
    hClass: 'Hospital category'
  },
  time: {
    all: 'All',
    year: 'A year',
    halfYear: 'Half a year',
    month: 'Three months'
  },
  property: {
    all: 'All',
    p1: 'Private',
    p2: 'Public',
    p3: 'Foreign investment'
  },
  sublist: {
    appointing: 'Reservationing',
    appoined: 'Completed',
    orderNumber: 'Query Number',
    Name: 'Provider Name',
    Section: 'Department',
    Doctors: 'Doctor',
    Attendees: 'Insured',
    Time: 'Appointment Date and Time',
    Status: 'Status',
    Operation: 'Operation',
    look: 'View',
    cancel: 'Cancel',
    again: 'Again',
    cancelText: 'Can I cancel my appointment?',
    oktext: 'Yes',
    canceltext: 'No',
    tipmsg: 'Tips',
    tipContent: 'Are you sure you want to cancel this appointment?',
    InsuranceCard: 'Member Card',
    placeInsuranceCard: 'Please input Insured number',
    errorInsuranceCard: 'Insured cannot be empty!',
    thePatient: 'Insured',
    placethePatient: 'Please enter the Insured!',
    errorthePatient: 'Patient cannot be empty!',
    contact: 'Contact',
    placecontact: 'Contact information of the Insured',
    errorcontact: 'Contact information cannot be empty!',
    medicalInstitution: 'Provider',
    placemedicalInstitution: 'Please select a provider',
    errormedicalInstitution: 'Medical institution cannot be empty!',
    Department: 'Department',
    placeDepartment: 'Desired appointment department',
    errorDepartment: 'Department cannot be empty!',
    Doctor: 'Doctor',
    placeDoctor: 'Desired appointment doctor',
    errorDoctor: "Doctor can't be empty!",
    ExpectedDate: 'Date',
    placeExpectedDate: 'Appointment date',
    errorExpectedDate: 'Expected appointment date cannot be empty!',
    ExpectedTime: 'Time',
    placeExpectedTime: 'Appointment time',
    errorExpectedTime: 'Expected appointment time cannot be empty!',
    remarks: 'Remarks',
    placeremarks:
      'If there are alternative hospitals, alternative departments, alternative doctors, alternative date and time, please fill in here(not required,within 160 characters)',
    submitData: 'Submit appointment information',
    cancelapply: 'Cancel the appointment',
    cacelbtn: 'Cancel',
    P: 'Processing',
    I: 'Processing',
    O: 'Submitted',
    C: 'Completed',
    L: 'Cancel',
    F: 'Failed',
    yuyueBtn: 'Make an appointment',
    symptom: 'Symptom：',
    placesymptom: 'Please enter your symptoms (required,within 160 characters)',
    healthInfo1:
      'Contact history: Is the health status in the health code of the patient and the accompanying person as "no abnormality (green)"? Is there a recent history of medium-to-high risk overseas or domestic travel? Have you been in contact with someone suspected of being infected with the new coronavirus recently?',
    healthInfo2:
      "Suspected symptoms: Has the patient's body temperature ≥37.3°C within 3 days (except for long-term fever caused by a clear disease) or has suspected symptoms such as dry cough, sore throat, sputum, hyposmia and taste loss?",
    healthInfoFlag1: 'Yes',
    healthInfoFlag2: 'No',
    des1: 'Additional information',
    des2: 'Please briefly explain the situation',
    prompt:
      'This provider does not currently support weekend appointments, please select another date',
    Languageofvisit: 'Language',
    cardDate: 'Insurance period'
  },
  hdetail: {
    card: 'Insured',
    province: 'Select Province',
    city: 'Select City',
    detail: 'Details',
    nodata: 'No Data',
    des: ' Hospital introduction',
    des1: 'Hospital introduction',
    service: 'Related services',
    question1: 'How to arrive to the direct billing service department',
    question2: 'Expertise Speciality',
    question3: 'Inpatient Service',
    question4: 'Direct billing service flow',
    question5: 'Medical experience',
    question6: 'Other departments ',
    question7: 'View medical experience ',
    to: 'to',
    hospitalProperty: 'Hospital nature',
    fullhours: '24H',
    emergency: 'Emergency',
    hospitalClass: 'Hospital category',
    specificClass: 'Specialty category',
    openTime: 'Opening hours',
    docPrompt: 'Tips',
    baseInfo: 'BaseInfo',
    address: 'Hospital Address',
    phone: 'Phone',
    level: 'Hospital level',
    lanuage: 'Lanuage',
    fold: 'Fold',
    unfold: 'Unfold',
    unknowname: 'Anonymous',
    back: 'Back',
    medicalRecordQuery: 'Visit record',
    medicalRecordQuery2: 'Visit record',
    writeExperience: 'Medical experience',
    Insured: 'Patient',
    date: 'Visit date',
    diagnosis: 'diagnosis',
    tit: 'Service satisfaction',
    placeHolder: 'Talk about your medical experience within 200 words',
    submit: 'Submit anonymously',
    submitBtn: 'Submit',
    nomore: 'No more'
  },
  preAuth: {
    tit: 'Multi-case Pre-authorization',
    place: 'Provider Name ',
    material: 'Upload claim materials',
    des: 'EOB',
    des1: 'Explain for the claims',
    materialStatus: 'Materials to be supplemented',
    serNum1: 'Claim number',
    preAuditCon: 'Audit conclusion',
    offcialserNum: 'Claim No',
    serCard: 'Member Card',
    claimProgress: 'Processing status', //理赔进度
    pay: 'Pay',
    reasonDes: 'Reason',
    claimMoney: 'Amount of compensation', //赔付金额
    paidMoney: 'Selfpay amount', //自负金额
    paidRemark: 'Identification of payment', //付款标识
    claimsResult: 'Audit conclusion', //理赔结论
    preDoctorTime: 'Visit date',
    preDoctorTime1: 'Expected visit date',
    preDoctorTime2: 'Expect date of hospitalized', //预计住院日期
    judge: 'Diagnosis', //诊断
    inHospitalTime: 'Estimated days of hospitalisation',
    medicalPay: 'Estimated cost for the treatment', //预估医疗费用
    authCategory: 'Authorization category', //授权类别
    reviewProgress: 'Audit progress', //审核进度
    reviewResult: 'Audit conclusion', //审核结论
    needSupple: 'Need to supplement', //需要补充
    preAuthNo: 'Authorization number', //授权编号
    sickInfo: 'Patient information',
    insured: 'Insured',
    predate: 'Expected visit date',
    preDetail: 'Pre-auth detail',
    newApply: 'New pre-auth application',
    serPerson: 'Policy number/insured',
    need: 'Need to supplement'
  },
  checkout: {
    tit: 'MediLink-Global Checkout', //中间带收银台
    name: 'Product name', //商品名称
    ordername: 'Amount to be paid', //自付额支付
    order: 'Order', //订单编号
    amount: 'amount', //订单金额
    date: 'Order date', //订单日期
    weChat: 'WeChat Payment', //微信支付
    alipay: 'Alipay Payment', //支付宝支付
    card: 'Card payment', //银行卡支付
    mode: 'Please choose the mode of payment', //请选择支付方式
    paymentof: 'Payment of', //待支付
    confirm: 'confirm payment', //确认支付
    unit: '',
    symbol: '¥'
  },
  cashierDesk: [
    'Add bank card',
    'Bank Card List',
    'Single limit {0}，Daily limit {1}',
    'Debit Card',
    'Credit Card', // 5
    'Verify bank card information',
    'Enter the bank card number',
    'Banks reserve mobile phone numbers',
    'Enter the phone number reserved by the bank',
    'SMS verification code', // 10
    'Enter SMS verification code',
    'Wrong number. Please enter the correct number',
    'Have read and agree to the Express Payment Agreement',
    'Save to a common card',
    'Card Payment', // 15
    'Send code',
    'SMS verification code has been sent to your bound phone {0}',
    'My usual card',
    'Please fill in your bank card information',
    'Check the supported banks', // 20
    'Cardholder name',
    'Expiry date of the card',
    'ID Type',
    'Please select ID Type',
    'ID Number', // 25
    'The bank card number',
    'Debit Card',
    'Credit Card',
    'View the supporting bank',
    'Expiration date', // 30
    'The Card Validation Code',
    'Please Input The Card Validation Code',
    'Month/year such as 09/23',
    'Last three digits of the signature line',
    'Have read and agree to the ', // 35
    'Express Payment Agreement',
    'Confirm',
    'Cancel',
    'Two-way Permit',
    'Taiwan Pass', // 40
    'To resend',
    'Fast Payment Service Agreement',
    'Agreed',
    'The expiry date of a credit card is usually written below the card number on the front of the card', // 39 需补充
    'Also known as the credit security code, or CVV2, refers to the 3 or 4 digits on the signature line on the back of a credit card following the end of the card number, as shown in the picture above. The positions of individual cards vary.' // 41 需补充
  ],
  pay: {
    pay1: 'Personally deductible',
    pay2: 'Family deductible',
    status: 'Deductible Status',
    type: 'Deductible Type',
    wait: 'Payable',
    paid: 'Paid'
  },
  ruleCon: [
    'Insurance Fraud Notification',
    'Important Information – please find below the applicable anti-fraud regulations dictated by the Chinese insurance laws:',
    "Criminal Law: An applicant, insured person or beneficiary who is guilty of committing insurance fraud shall be sentenced to fixed-term imprisonment for a maximum of 10 years, and concurrently be sentenced to a fine or confiscation of property. Any appraiser, assessor or attester, who deliberately provides false certification documents, thus providing conditions for the applicant, insured person or beneficiary to carry out insurance fraud, shall be charged as an accomplice of insurance fraud (see Article 198 of the Criminal Law of the People's Republic of China)",
    "Administrative Law: An applicant, insured person or a beneficiary who commits an insurance fraud act (which does not constitute a crime) shall face a detention of maximum 15 days or a maximum fine of 5,000 Yuan. Any appraiser, assessor or attester who deliberately provides false certification documents, thus providing conditions for the applicant / the insured person / beneficiary to carry out insurance fraud, shall be charged accordingly (see Article 16 and Article 21 of the 'Decision of the Standing Committee of the National People's Congress on Punishment of the Crimes of Undermining the Financial Order').",
    "Civil Law: If the applicant fails, due to intention or gross negligence, to perform his/her obligation of making a full and accurate disclosure, or the applicant / insured person intentionally causes the occurrence of an insured event, the insurer has no obligation to make any indemnity or payment of insurance benefit. If the applicant / the insured person / beneficiary, following the occurrence of an insured event, provides forged or altered relevant evidence, information or other proofs or falsifies the cause of the occurrence of the insured event or overstates the extent of the loss, the insurer has no obligation for indemnity or to make payment of the insurance benefit for the portion which is falsified or overstated (see Article 16 and Article 27 of the Insurance Law of the People's Republic of China)", //4
    'Statement of Consent',
    'I agree that, for any reason, I will pay the full amount of any fees that my insurance policy does not cover during my direct billing service. I agree that Medilink or my insurance company contact me and collect the fees from me via my bank transfer. I further agree that if I do not pay these fees in time, I will be personally responsible for all costs of the collection, which include any reasonable legal costs, related costs incurred by insurance company, Medilink and /or the medical facility.',
    'For the necessity of underwriting, claim and related service, I agree that insurance company, medical facility and Medilink share my related insurance benefit, health and/or medical information. I authorize any physician, medical facility, pharmacist, insurance company, employer, labor union, or association to release such information to Medilink and my insurance company. The information includes copies of medical records, concerning advice, care or treatment plan. I hereby confirm that the above statements are true and complete. For the necessity of claim and related service,I agree that the insurance company or Medilink handle the claims money on my behalf.I agree to provide any additional information as further required.',
    'For Direct Billing case or guaranteed case which the medical treatment received in the pre-appointed provider,I hereby authorize the provider or pre-appointed third party to directly bill my insurance company which should make payment of any benefit payable to the provider or pre-appointed third party.',
    'A photocopy of the above authorization shall be considered as effective and valid as original document.',
    '' //10
  ],
  category: ['Outpatient', 'Be hospitalized'],
  fileList: [
    'Medilink pre-authorization form ',
    'MGU pre-authorization form',
    'Medilink member reimbursement claim form',
    'MGU member reimbursement claim form',
    'Form download',
    'User manual',
    'member portal user guide view',
    'Membership Service System Manual'
  ],
  wordsDes: [
    'what is self-service claim',
    'Self-help claim is the traditional reimbursement model, the insured person to pay the fully amount to medical institution, and submit the invoice, medical records, the examination report, reimbursement request form eg. to the insurance company or the third party authorized by an insurance company to get the reimbursement amount.',
    'What is pre-authorization',
    '1. Whether it is a direct payment service or an after-sales claim, the insurance company requires you to obtain authorization and approval in advance for certain medical treatment projects, otherwise it may cause you to spend some unreasonable and necessary expenses beyond the insurance coverage Compensation. Even authorized and unauthorized cases will result in downgraded claims.',
    '2,Normally need pre-authorization application’s medical treatment: for all hospitalisation treatment; complex dental treatment; outpatient radiotherapy and chemotherapy for tumor; polyclinic surgery under general anesthesia; home nursing; amniocentesis(under 35years old pregnant women); purchase walking stick, wheel chair and hearing aid; contrast-enhanced CT/stereotactic radio therapy(gamma knife); other treatment that need pre-authorization in the plan.',
    '3,You need to inform the hospital to submit the pre-authorization request form for us before get above treatment, and let our mediLink global customer services team to get the request form at least 3 working days before you schedule the treatment date. You will get feedback within 48 hours since our mediLink global team and insurance company received the pre-authorization request form. If your cost was higher than normal range or the length of stay in hospital was too long, we may need more time to audit, normally you will get feedback within 5 working days. And, we may need other medical history documents for this request, that’s you and your attending physician may help us to provide, and let the audit get result as soon as possible.'
  ],
  unpaid: {
    totalMoney: 'Total amount',
    payMoney: 'Amount of compensation',
    personMoney: 'Selfpay amount',
    waitPay: 'Amount to be paid',
    detail: 'Self-payment amount detail'
  },
  cardUpload: {
    unDate: 'Expired, re-upload',
    upload: 'Upload',
    tit1: 'Upload ID photo',
    tit2: 'Manual entry',
    type1: 'ID card',
    type2: 'Passport',
    type3: 'Others',
    upload1: 'Upload the front of the ID card',
    upload2: 'Upload reverse side of ID card',
    upload3: 'Upload passport',
    upload4: 'Upload Others',
    upload1p: 'Please upload the front of the ID card',
    upload2p: 'Please upload reverse side of ID card',
    upload3p: 'Please upload passport',
    upload4p: 'Please upload Others photos'
  },
  extra: ['Please note if the currency is not RMB'],
  FirstPDate: 'First Insurance Date',
  message: {
    upgrade:
      'During system upgrade, please click OK to print the file in the new page using crtl+P！', // "系统升级中，请点击确认后在新的页面中使用crtl+P打印文件"
    doctorRequired: "Attention：Please be sure to enter the doctor's name！", // "立案时请务必录入医生！",
    existErr:
      'Attention：There are outpatients who do not enjoy direct payment！' //  "存在不享受直付的门诊"
  },
  common: {
    confirm: 'Confirm', // "确定"
    cancel: 'Cancel', //"取消"
    index: 'Index', // 序号
    confirmCancelTip: 'Confirm deleting this data entry?'
  },
  uploadModal: {
    remark: 'Remark:',
    input: 'Please enter...',
    select: 'Please select',
    needFill:
      'If there is any information to contact customer service for supplement:'
  },
  paramLabel: {
    expend: 'expend', // 消耗
    remain: 'remain', // "剩余"
    times: 'times'
  },
  // 优惠券模块
  coupons: {
    labels: ['全部', '生效中', '已失效', '卡名称', '卡号', '有效期'],
    btns: ['查看详情', '立即使用']
  }
}
