import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from '@/api'
import './registerServiceWorker'
import i18n, { getLanguageL } from '@/lang'
import globalComponentsRegister from '@/components/index.js'
import { addCommas, bindCardList } from '@/utils/business'

Vue.config.productionTip = false
globalComponentsRegister()

Vue.prototype.$apis = api // 统一api
Vue.prototype.$CardList = bindCardList
Vue.filter('addCommas', addCommas)
getLanguageL()

window.router = router
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
