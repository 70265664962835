<template>
  <a-popover overlayClassName="user_info_popover_" placement="bottom">
    <template slot="content">
      <ul class="list_">
        <li>
          <router-link to="/changepwd">{{ $t("login.xgmm") }}</router-link>
        </li>
        <li>
          <a href="javascript:;" @click="exit">{{
            $t("labels[29]") || "退出登录"
          }}</a>
        </li>
      </ul>
    </template>
    <!-- <template slot="title">
        <div class="username_" style="text-align: center;">
          {{ userName }}
        </div>
      </template> -->
    <a-avatar
      style="backgroundColor:#00C55D; margin-left: auto;position: absolute; right: 72px;top: 13px;"
      icon="user"
    />
  </a-popover>
</template>
<style lang="scss" scoped>
.list_ {
  li {
    line-height: 42px;
    padding: 0 24px;
    border-bottom: 1px solid #eee;
    a {
      color: #46464f;
    }
  }
}
</style>
<script>
import { getUserInfo } from "@/utils/authorized";
import { exit } from "@/utils/business";

export default {
  data() {
    return {
      userName: ""
    };
  },
  mounted() {
    const userInfo = getUserInfo() || {};
    this.userName = userInfo.userName || "Me";
  },
  methods: {
    exit
  }
};
</script>
