// 导出页面为PDF格式
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";

const getPdf = function(title, id, cb) {
  var pdfDom = document.querySelector(id);
  html2Canvas(pdfDom, {
    allowTaint: true,
    scale: 2
  })
    .then(function(canvas) {
      let contentWidth = canvas.width;
      let contentHeight = canvas.height;
      let pageHeight = (contentWidth / 592.28) * 841.89;
      let leftHeight = contentHeight;
      let position = 0;
      let imgWidth = 595.28;
      let imgHeight = (592.28 / contentWidth) * contentHeight;
      let pageData = canvas.toDataURL("image/jpeg", 1.0);
      let PDF = new JsPDF("", "pt", "a4");
      if (leftHeight < pageHeight) {
        PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
      } else {
        while (leftHeight > 0) {
          PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
          leftHeight -= pageHeight;
          position -= 841.89;
          if (leftHeight > 0) {
            PDF.addPage();
          }
        }
      }
      PDF.save(title + ".pdf");
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      cb && cb();
    });
};
export default getPdf;
