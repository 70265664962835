export default {
  HOME: [
    "",
    "",
    "",
    "",
    "", //5
    "",
    "",
    "",
    "",
    "", //10
    "",
    "",
    "",
    "",
    "", //15
    "",
    "",
    "",
    "",
    "", //20
    "",
    "",
    "",
    "",
    "", //25
    "",
    "",
    "",
    "",
    "", //30
    "",
    "",
    "",
    "",
    "", //35
    ""
  ],
  about: ["", "", "", "", "", "", "", ``, "", "", "", "", "", "", ""],
  about1: [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  ],
  videoCenter: ["", ""],
  PAS: ["", "", "", "", "", "", "", "", "", ``, "", ``, ``, ``, ``, ``, ``, ``],
  PAS1: [
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``
  ],
  PAS2: [
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``
  ],
  PAS3: [
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``,
    ``
  ],
  TCMN: ["", "", ``, ``, ``, ``, ``, ``],
  SOS: ["", "", "", "", "", ""],
  BFM: ["", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  BFM1: ["", "", "", "", "", "", "", "", "", ""],
  EMA: [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  ],
  IC: [
    "",
    "",
    "",
    "",
    "",
    ``, //5
    "",
    "",
    "",
    "",
    "", //10
    ``,
    "",
    "",
    "",
    "", //15
    "",
    ``,
    "",
    "",
    "", //20
    ``,
    ``,
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  ],
  FAQ: ["", "", "", ``, ``, ``, ``, ``, ``, ``, "", "", "", "", ""],
  LOGIN: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  AMG: [
    "",
    "",
    "",
    "",
    "",
    "",
    "", // 6
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  ],
  ML: [
    "",
    "",
    "",
    "",
    "", // 5
    "",
    "",
    "",
    "",
    "", // 10
    "",
    "",
    "",
    "",
    "", // 15
    "",
    "",
    "",
    "",
    "", // 20
    "",
    "",
    "",
    "",
    "", // 25
    "",
    "",
    "",
    "",
    "", // 30
    "",
    "",
    "",
    "",
    "", // 35
    "",
    "",
    "",
    ""
  ],
  ML1: [
    "",
    "",
    "",
    "",
    "", // 5
    "",
    "",
    "",
    "",
    "", // 10
    "",
    "",
    "",
    "",
    "", // 15
    "",
    "",
    "",
    "",
    "", // 20
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  ],
  JOIN: ["", "", "", "", "", "", "", "", "", "", ""],
  OVERSEAS: ["", ""],
  distanceList: [
    "5km以内",
    "10km以内",
    "15km以内",
    "20km以内",
    "30km以内",
    "30km以上"
  ]
};
