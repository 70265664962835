const SM4 = require("gm-crypt").sm4;
export default function(params) {
  if (typeof params == "undefined") {
    params = null;
  }
  // 动态生成key
  const nowDate = new Date();
  const m = dateZero(nowDate.getMonth() + 1);
  const d = dateZero(nowDate.getDate());
  const key = `Nanyan@s${nowDate.getFullYear()}${m}${d}`;
  let sm4Config = {
    //配置sm4参数
    key, // key
    mode: "ecb", // 加密的方式有两种，ecb和cbc两种，cbc的话下面还要加一个iv的参数，ecb不用
    cipherType: "text"
  };
  let sm4 = new SM4(sm4Config);
  let encryptData = sm4.encrypt(params);

  return encryptData.trim();
}

function dateZero(num) {
  return num < 10 ? "0" + num : num;
}
