/**
 * @description 文件预览
 * @param {Object} data 文件数据,包含相对路径 {filePath: ''}
 */
export const getPreviewFile = data => {
  if (!data || !data.url) {
    return
  }
  const fn = url => {
    if (!url) {
      return false
    }
    const a = document.createElement('a')
    a.href = url
    a.download = true
    a.target = '_blank'
    a.click()
    a.remove()
  }
  fn(data.url)
  return true
}
// 通过后缀判断是否是图片链接
export const imgReg = new RegExp(
  '^.*.(bmp|jpg|png|tif|gif|pcx|tga|exif|fpx|svg|psd|cdr|pcd|dxf|ufo|eps|ai|raw|wmp|webp|jpeg|heic)$'
)
export const emailReg = /[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
