<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <a-config-provider :locale="zh_CN">
      <router-view v-if="isRouterAlice" />
    </a-config-provider>
    <div class="fixbg1" v-if="isLoading">
      <a-spin />
    </div>
  </div>
</template>

<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import en_US from "ant-design-vue/lib/locale-provider/en_US";
import Bus from "@/utils/Bus";
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlice: true,
      zh_CN: zh_CN,
      lanuage: "zh-cn",
      isLoading: false
    };
  },
  mounted() {
    Bus.$on("show-loading", () => {
      this.isLoading = true;
    });
    Bus.$on("hide-loading", () => {
      this.isLoading = false;
    });
  },
  watch: {
    "$i18n.locale"(newValue) {
      if (this.$i18n.locale == "zh-cn") {
        this.zh_CN = zh_CN;
      } else {
        this.zh_CN = en_US;
      }
    }
  },
  methods: {
    reload() {
      this.isRouterAlice = false;
      this.$nextTick(function() {
        this.isRouterAlice = true;
      });
    }
  }
};
</script>

<style lang="scss">
// 收银台页面
.comp__ {
  .ant-select-selection,
  .ant-select-selection:focus {
    border: 1px solid #d9d9d9 !important;
    box-shadow: inherit !important;
  }
}
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ant-popover {
  .ant-popover-inner {
    padding: 0 16px;
  }
}
.ant-upload-select-text {
  .ant-btn-default {
    background: #f4f5f6;
    color: #262626;
    font-weight: 500;
    text-align: center;
    width: 109px;
    font-family: Source Han Sans SC;
  }
}
.ant-modal-title {
  text-align: center !important;
}
.ant-spin-lg {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 666666;
}
.ant-modal-confirm {
  top: 40% !important;
}
.ant-table table {
  table-layout: fixed;
  td {
    word-break: break-all;
  }
}
.custom_table_style_ {
  th {
    background: #e7ecf3 !important;
    color: #333 !important;
  }
  td,
  th {
    padding: 5px !important;
    font-size: 10px !important;
    text-align: center !important;
  }
  .ant-table-footer {
    padding: 0 !important;
    border-top: none !important;
    &::before {
      background: #e7ecf3;
      display: none !important;
    }
  }
}
.ant-select-selection {
  border: none !important;
  box-shadow: none !important;
  &:focus {
    border: none !important;
    box-shadow: none !important;
  }
}
iframe {
  margin: 0;
  border: none;
}
body {
  margin: 0;
}
// .ant-radio-checked {
//   .ant-radio-inner {
//     background: #00ac51;
//   }
// }
// .ant-radio-inner {
//   border-radius: 4px !important;
// }
.ant-popover {
  &.no_pd_ {
    .ant-popover-inner {
      padding: 0;
    }
    .ant-popover-inner-content {
      padding: 0 16px !important;
    }
  }
}
.ant-popover-inner-content {
  padding: 0 !important;
  line-height: 32px;
}
.more_list_wrap_ {
  margin: 0;
  li {
    border-top: 1px solid #e9edf4;
    text-align: center;
    line-height: 44px;
    min-width: 160px;
    cursor: pointer;
    &:hover {
      color: #00ac51;
    }
  }
}
.horizontal_form_ {
  &.label_w108_ {
    .ant-form-item-label {
      width: 108px;
    }
  }
  &.label_w208_ {
    .ant-form-item-label {
      width: 268px;
    }
  }
  .item_hide_label_ {
    .ant-form-item-label {
      visibility: hidden;
    }
  }
  .ant-form-item {
    position: relative;
    display: flex;
    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}
.page_ {
  .viewer-canvas {
    background: #001529;
  }
  .ant-form label {
    font-size: 13px;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-btn-primary {
    // margin: 25.5px !important;
  }
  .tab_num_ {
    color: #e6002c;
    font-size: 15px;
    margin-left: 8px;
  }
  // 加背景条
  .c_form_item_ {
    padding-left: 15px;
    background: #f6f7f9;
    margin: 22px 0 !important;
    &.no_top_mrg_ {
      margin-top: 0 !important;
    }
    &.h58_ {
      padding: 9px 0 9px 9px;
      // padding: 12px 0 12px 9px;
    }
    &.pad15_ {
      padding-left: 15px;
    }
    &.no_mrgtop_ {
      margin-top: 0 !important;
    }
    .p_static_ {
      position: static;
    }
    .right_ {
      position: absolute;
      top: 0px;
      right: 18px;
    }
  }
}
#app {
  font-family: "Microsoft YaHei", "Source Han Sans SC", "Avenir", Helvetica,
    Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  height: 100%;
  min-height: 100vh;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

// 自定义样式
.myModal_ {
  .ant-modal-header {
    text-align: center;
    font-size: 23px;
    font-weight: 600;
    border-bottom: 0;
    font-weight: 600;
    color: rgba(38, 38, 38, 1);
  }
}

.myTableRow_ {
  .ant-table-row-expand-icon {
    display: none;
  }
  // .ant-table-tbody{
  //       .ant-table-expanded-row{
  //         td:first-child{
  //           background:#fff;
  //         }
  //       }
  // }
  // .myInnerTableRow_{
  //   .ant-table-tbody{
  //     tr{
  //       td{
  //          background: #fff;
  //       }
  //     }
  //   }
  // }
}

.myTabs_ {
  .ant-tabs-tab {
    border: 0 !important;
    border-bottom: 0 !important;
    border-radius: 0 !important;
    width: 100px !important;
    text-align: center !important;
  }
  .ant-tabs-tab-active {
    border-left: 4px solid #00bd59 !important;
  }
}
.con_ {
  color: #333;
  font-size: 11px;
  &.swiper {
    width: 1000px;
    margin: 0 auto;
    .swiper-container {
      width: 100%;
      height: 292px;
      position: relative;
      .swiper-slide {
        height: 292px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        // &::after{
        //   content:'';
        //   display:block;
        //   position:absolute;
        //   top:0;
        //   bottom:0;
        //   left:0;
        //   right:0;
        //   background-color:rgba(0,0,0,0.5);
        //   z-index:9;
        // }
        img {
          flex: 1;
          height: 292px;
          object-fit: cover;
        }
        .des_ {
          flex: 1;
          display: flex;
          padding: 0 34px;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          p:first-child {
            font-weight: bold;
            font-size: 15px;
            padding: 10px 0;
          }
          p:nth-child(2) {
            color: #333;
            font-size: 12px;
            text-align: left;
          }
          p:nth-child(3) {
            color: #999;
            font-size: 12px;
            padding: 15px 0;
          }
          a {
            width: 86px;
            height: 28px;
            line-height: 28px;
            border: 1px solid rgba(0, 0, 0, 1);
            text-align: center;
            color: #000;
            font-size: 12px;
          }
        }
      }
      .swiper-pagination {
        right: 30px;
        bottom: 20px;
        width: 100%;
        text-align: right;
        .swiper-pagination-bullet {
          width: 17px;
          height: 2px;
          background: rgba(0, 166, 60, 0.2);
          opacity: 1;
          margin-left: 7px;
          &.swiper-pagination-bullet-active {
            background: rgba(0, 166, 60, 1);
            opacity: 1;
          }
        }
      }
    }
    span.swiper-pagination-bullet {
      width: 16px;
      height: 1px;
      opacity: 0.2;
      background-color: #00a63c;
      margin-right: 5px;
      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
    .swiper-button-prev {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
      margin: auto 0;
      width: 24px;
      height: 46px;
      background: url(assets/medilink/videoCenter/prev.png) center center
        no-repeat;
      background-size: 24px 46px;
      opacity: 0.3;
      z-index: 10;
      &:hover {
        opacity: 0.8;
      }
      &:after {
        display: none;
        content: "";
      }
    }
    .swiper-button-next {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      margin: auto 0;
      width: 24px;
      height: 46px;
      z-index: 10;
      background: url(assets/medilink/videoCenter/next.png) center center
        no-repeat;
      background-size: 24px 46px;
      opacity: 0.3;
      &:hover {
        opacity: 0.8;
      }
      &:after {
        display: none;
        content: "";
      }
    }
  }
  &.swiper1 {
    .swiper-wrapper {
      margin-left: -2px;
    }
  }
}
.block_ {
  .ant-row.ant-form-item {
    padding: 0 150px;
    display: flex;
    justify-content: space-between;
    &.flex_r {
      flex-direction: column;
      textarea {
        display: block;
        width: 700px;
        padding: 10px 30px;
        height: 150px;
        resize: none;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
      }
    }
    .ant-form-item-label {
      width: 320px;
      white-space: nowrap;
      text-align: left;
      font-size: 12px;
      color: #000;
    }
    .ant-select {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      .ant-select-selection--single {
        height: 30px;
      }
    }
    .ant-radio-group {
      display: flex;
      flex-direction: column;
      .ant-radio-wrapper {
        margin-bottom: 10px;
        .ant-radio-inner {
          border-radius: 0 !important;
        }
        &.ant-radio-wrapper-checked .ant-radio-inner {
          background: url(./assets/medilink/check.png) center center no-repeat;
          background-size: 16px 16px;
          &::after {
            display: none;
          }
        }
      }
    }

    &.require_ {
      .ant-form-item-label label::after {
        content: "*";
        color: #ff0000;
        font-size: 12px;
      }
    }
    .ant-form-item-control-wrapper {
      width: 700px;
    }
  }
}
.btn_item .ant-form-item-control {
  text-align: center;
  margin-bottom: 100px;
}
.btn_ {
  width: 125px;
  height: 33px;
  line-height: 33px;
  margin: 0 auto;
  display: inline-block;
}
.animate {
  visibility: hidden;
  animation-duration: 0.8s;
}
.slideUpIn60 {
  visibility: visible;
  animation-name: slideUpIn60;
}
@keyframes slideUpIn60 {
  0% {
    transform: translate3d(0, 60%, 0);
    visibility: hidden;
  }
  to {
    transform: translateZ(0);
    visibility: visible;
  }
}
.swiperP .swiper-pagination {
  padding-right: 67px;
  bottom: 10px;
  right: 0;
  width: 1000px;
  left: 0;
  text-align: right;
  z-index: 10;
  span {
    width: 16px !important;
    height: 16px !important;
    color: #fff;
    display: inline-block;
    background-color: transparent !important;
    line-height: 16px;
    text-align: center;
    opacity: 0.5 !important;
    border-radius: 0 !important;
    font-weight: bold;
    &.swiper-pagination-bullet-active {
      opacity: 1 !important;
      border: 1px solid #fff;
      background-color: transparent !important;
      border-radius: 0 !important;
    }
  }
}
.page_ .ant-modal-content {
  height: 290px;
}
.date_ .ant-calendar-picker-input {
  border: 1px solid #eee;
  border-right: 0;
  height: 30px;
  outline: none;
}
.blue_ a {
  color: #2c5acc;
}
.blue_ .ant-btn-primary,
.blue_ .ant-btn-primary:hover,
.blue_ .ant-btn-primary:focus {
  background-color: #2c5acc;
  border-color: #2c5acc;
}
.blue_,
.modal_ {
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-btn:hover,
  .ant-btn:focus,
  a:hover,
  .ant-pagination-item-active a {
    color: #2c5acc;
  }
  .ant-btn.ant-btn-primary:hover,
  .ant-btn.ant-btn-primary:focus {
    color: #fff;
  }
  .ant-tabs-ink-bar {
    background-color: #2c5acc;
  }
  .ant-switch-checked {
    background-color: #2c5acc;
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-input:hover,
  .ant-input:focus,
  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
  .ant-pagination-item-active {
    border-color: #2c5acc;
  }
  .ant-btn-primary {
    background-color: #2c5acc;
    border-color: #2c5acc;
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    border-color: #2c5acc;
    background-color: #2c5acc;
    color: #fff;
  }
  .ant-radio-inner {
    border-radius: 16px !important;
    background-color: #fff;
  }
  .ant-radio-checked .ant-radio-inner,
  .ant-radio-inner:after {
    border-color: #2c5acc !important;
  }
  .ant-radio-checked .ant-radio-inner {
    background: url(./assets/medilinkPC/checkiconr.png) center center no-repeat;
    background-size: 10px 10px;
  }
  .ant-radio-checked .ant-radio-inner::after {
    background-color: #2c5acc;
    border-radius: 10px !important;
    width: 10px;
    height: 10px;
    left: 2px;
    top: 2px;
  }
  .ant-checkbox-checked:after {
    border: 1px solid #2c5acc;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2c5acc;
    border: 1px solid #2c5acc;
  }
  .acco_tab {
    .ant-tabs-tab {
      font-size: 16px;
      padding: 20px 10px 20px 0;
      text-align: left;
      &.ant-tabs-tab-active {
        font-weight: bold;
      }
    }
  }
}
.swiper_ff {
  position: relative;
  padding-bottom: 20px;
  .swiper-pagination {
    bottom: -10px;
    right: 0;
    width: 1000px;
    left: 0;
    text-align: left;
    z-index: 10;
    span {
      width: 16px !important;
      height: 16px !important;
      color: #fff;
      display: inline-block;
      background-color: #cccccc !important;
      line-height: 16px;
      text-align: center;
      opacity: 0.5 !important;
      border-radius: 0 !important;
      font-weight: bold;
      margin-right: 10px;
      font-size: 12px;
      outline: 0;
      &.swiper-pagination-bullet-active {
        opacity: 1 !important;
        border: 1px solid #2c5acc;
        background-color: #2c5acc !important;
        border-radius: 0 !important;
        color: #fff !important;
      }
    }
  }
}
.btn_box .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 35px;
}
.card_input {
  input.ant-input::placeholder {
    font-size: 14px;
  }
  input.ant-input {
    padding-left: 35px !important;
    line-height: normal;
    display: flex;
    align-items: center;
    height: auto !important;
    padding: 13px 15px 13px 35px !important;
  }
}
.model_b {
  .ant-modal-content {
    border-radius: 10px;
  }
}
.btn_box .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 35px;
}
.card_input {
  input.ant-input::placeholder {
    font-size: 14px;
  }
  input.ant-input {
    padding-left: 35px !important;
    line-height: normal;
    display: flex;
    align-items: center;
    height: auto !important;
    padding: 13px 15px 13px 35px !important;
  }
}
.model_b {
  .ant-modal-content {
    border-radius: 10px;
  }
}
.date_user .ant-calendar-picker-input {
  border: none;
}
.fixbg1 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  .ant-spin-spinning {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    left: 0;
    margin: auto;
  }
}
.marker-box.switch_ .ant-form-item-children {
  line-height: 18px;
  display: flex;
  width: 100%;
  align-items: center;
}
.s_n .ant-select-selection-selected-value {
  white-space: pre-wrap;
}
</style>

<style>
/**调整其select的内容不换行**/
.ant-select-dropdown-menu-item.select-break-all {
  white-space: normal;
  word-break: break-all;
}
</style>
