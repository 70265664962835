<template>
  <div>
    <!-- 审核进度：身份验证成功，出现：去结算，取消理赔
      审核进度：获准入院，无按钮
      审核进度：待审核，点出现：取消理赔、变更金额、打印付费凭条
      审核进度：报批保险人，出现：打印付费凭条
      审核进度：补件，出现：上传资料、打印付费凭条
      审核进度：已结案，出现打印理解解释、打印付费凭条，如果是5630还需要出现上传发票
      审核进度：审核中，出现：打印付费凭条
        /**
        appLoading
        01: 去结算,
        05: 上传资料,
        07: 打印理赔解释,
        08: 上传发票,
        10: 金额变更
        */
      -->
    <div v-if="type == '1'">
      <a
        v-if="record.appLoading == '01'"
        @click="handleToSettle(true, record)"
        >{{ $t('btns[15]') || '去结算' }}</a
      >
      <a-popover
        v-if="
          ['03', '02', '01'].indexOf(record.appLoading) == -1 ||
            (['01'].indexOf(record.appLoading) > -1 && extraCheck)
        "
        class="more_popover_"
        placement="topRight"
      >
        <template slot="content">
          <ul class="more_list_wrap_">
            <!-- <li v-if="['07'].includes(record.appLoading)"
              @click="handleReconsider(record)">
              {{ $t("btns[19]") || "发起复议" }}
            </li> -->
            <li
              v-if="['07'].indexOf(record.appLoading) > -1"
              @click="
                handlePrint(record, $t('btns[13]') || '打印理赔解释', 'EOB')
              "
            >
              {{ $t('btns[13]') || '打印理赔解释' }}
            </li>
            <li
              v-if="['04', '05', '10'].indexOf(record.appLoading) > -1"
              @click="handleUpload(record, $t('btns[11]') || '上传资料')"
            >
              {{ $t('btns[11]') || '上传资料' }}
            </li>
            <li
              v-if="['08'].indexOf(record.appLoading) > -1"
              @click="handleUpload(record, $t('btns[10]') || '上传发票')"
            >
              {{ $t('btns[10]') || '上传发票' }}
            </li>
            <!-- v-if="['01', '04'].indexOf(record.appLoading) > -1 && extraCheck" -->
            <li
              v-if="['01', '04', '10'].indexOf(record.appLoading) > -1"
              @click="showConfirm(record)"
            >
              {{ $t('btns[7]') || '取消理赔' }}
            </li>

            <li
              v-if="['10'].indexOf(record.appLoading) > -1"
              @click="handleToSettle(false, record)"
            >
              {{ $t('btns[12]') || '金额变更' }}
            </li>
            <!-- v-if="['09', '06', '05', '07', '04'].indexOf(record.appLoading) > -1" -->
            <li
              @click="
                handlePrint(record, $t('btns[6]') || '打印付费凭条', 'POS40')
              "
            >
              {{ $t('btns[6]') || '打印付费凭条' }}
            </li>
            <!-- 正式 -->
          </ul>
        </template>
        <a style="margin-left: 12px;">{{ $t('btns[14]') || '更多' }}</a>
      </a-popover>
    </div>
    <div v-else>
      <!-- <a-button
        v-if="['07'].includes(record.processCode)"
        @click="handleReconsider(record)"
        type="primary"
        >{{ $t('btns[19]') || '发起复议' }}</a-button
      > -->
      <a-button
        v-if="record.processCode == '01'"
        @click="handleToSettle(true, record)"
        type="primary"
        >{{ $t('btns[15]') || '去结算' }}</a-button
      >
      <a-button
        v-if="['07'].indexOf(record.processCode) > -1"
        @click="handlePrint(record, $t('btns[13]') || '打印理赔解释', 'EOB')"
        class="mrg_l15_"
        type="primary"
        >{{ $t('btns[13]') || '打印理赔解释' }}</a-button
      >
      <a-button
        v-if="['09', '06', '05', '07', '04'].indexOf(record.processCode) > -1"
        @click="handlePrint(record, $t('btns[6]') || '打印付费凭条', 'POS40')"
        class="mrg_l15_"
        type="primary"
        >{{ $t('btns[6]') || '打印付费凭条' }}</a-button
      >
      <a-button
        v-if="['05'].indexOf(record.processCode) > -1"
        @click="handleUpload(record, $t('btns[11]') || '上传资料')"
        class="mrg_l15_"
        type="primary"
        >{{ $t('btns[11]') || '上传资料' }}</a-button
      >
      <a-button
        v-if="['08'].indexOf(record.processCode) > -1"
        @click="handleUpload(record, $t('btns[10]') || '上传发票')"
        class="mrg_l15_"
        type="primary"
        >{{ $t('btns[10]') || '上传发票' }}</a-button
      >
      <a-button
        v-if="['01', '04'].indexOf(record.processCode) > -1 && !hideCancelBtn"
        @click="showConfirm(record)"
        class="mrg_l15_"
        type="primary"
        >{{ $t('btns[7]') || '取消理赔' }}</a-button
      >
      <a-button
        v-if="['10'].indexOf(record.processCode) > -1"
        @click="handleToSettle(false, record)"
        class="mrg_l15_"
        type="primary"
        >{{ $t('btns[12]') || '金额变更' }}</a-button
      >
    </div>
  </div>
</template>

<script>
import Bus from '@/utils/Bus'
import { MESSAGE_CHANNEL } from '@/utils/constants'
import moment from 'moment'

export default {
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      hideCancelBtn: 0
    }
  },
  computed: {
    extraCheck() {
      return this.record.transmissionDate >= moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    handleUpload(record, title) {
      const uploadType = ['04', '10'].includes(record.appLoading)
        ? 4
        : title == (this.$t('btns[11]') || '上传资料')
        ? 2
        : 3
      Bus.$emit(MESSAGE_CHANNEL, {
        type: 'handleUpload',
        data: record,
        title,
        // eslint-disable-next-line no-constant-condition
        uploadType,
        onSuccess: () => {
          this.$emit('onSuccess')
        }
      })
    },
    showConfirm(record) {
      Bus.$emit(MESSAGE_CHANNEL, {
        type: 'showConfirm',
        data: record,
        onSuccess: () => {
          this.$emit('onSuccess')
        }
      })
    },

    // 发起复议
    handleReconsider(record) {
      Bus.$emit(MESSAGE_CHANNEL, {
        type: 'showReconsider',
        data: record,
        onSuccess: () => {
          this.$emit('onSuccess')
        }
      })
    },

    handlePrint(record, title, printType) {
      // 打印理赔、结算
      Bus.$emit(MESSAGE_CHANNEL, {
        type: 'handlePrint',
        data: record,
        title,
        printType
      })
    },
    handleToSettle(isSettle, record) {
      // 去结算、变更
      Bus.$emit(MESSAGE_CHANNEL, {
        type: 'handleToSettle',
        data: record,
        isSettle
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mrg_l15_ {
  margin-left: 15px !important;
}
</style>
