import axios from 'axios'
import qs from 'qs'
import Bus from '@/utils/Bus'

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。'
}
const errorHandler = error => {
  const { response = {} } = error

  const errortext = codeMessage[response.status] || response.statusText
  const { status } = response
  if (status === 401) {
    window.notification.error({
      message: '未登录或登录已过期，请重新登录。'
    })
    return
  }
  if (!status) {
    return
  }
  window.notification.error({
    message: `请求错误 ${status}: ${error.config.url}`,
    description: errortext
  })
}
const _hideLoading = function() {
  Bus.$emit('hide-loading')
}
console.log(process.env.NODE_ENV, 'process.env.NODE_ENV')
var instance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/api' : '/api_portal',
  timeout: 5 * 60 * 1000,
  headers: { 'X-Custom-Header': 'foobar' }
})
// Add a request interceptor
instance.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    if (
      config.method.toLowerCase() === 'post' &&
      config.data &&
      config.data.formEncode
    ) {
      config.data = qs.stringify(config.data)
    }
    if (config.url.indexOf('uploadIdCardInfo') > -1) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'multipart/form-data'
      }
    }
    Bus.$emit('show-loading')
    return config
  },
  function(error) {
    console.log(error, '请求失败啊啊啊啊')
    // Do something with request error
    return Promise.reject(error)
  }
)
// Add a response interceptor
instance.interceptors.response.use(
  function(response) {
    // Do something with response data
    let { code, msg, msgEng, returnCode, returnMsg } = response.data
    returnCode = code || returnCode
    returnMsg = msg || msgEng || returnMsg
    _hideLoading()
    if (returnCode && returnCode != '200' && returnCode != '0000') {
      if (response.config.url.indexOf('resetPwd') == -1) {
        if (returnCode == '203') {
          // 跳转微信2.0后台
          let arr = [
            'getUdeskCallLogs',
            'getUdeskDisableCalls',
            'getUdeskCallLogsFile',
            'updateUdeskCallLogs',
            'insertUdeskDisableCall',
            'updateUdeskDisableCall'
          ]
          for (let i = 0; i < arr.length; i++) {
            if (response.config.url.indexOf(arr[i]) > -1) {
              window.location.href =
                window.location.href.indexOf('provide') > -1
                  ? 'https://provider.medilink-global.com.cn/eccsmanage/'
                  : 'http://eccs.run4wd.com/eccsmanage/'
              return false
            }
          }
          window.localStorage.clear()
          var num = Math.ceil(Math.random() * 10)
          window.router.push({ name: 'login', params: { num } })
        } else if (
          response.config.url.indexOf('calimApp/patch') > -1 ||
          response.config.url.indexOf('createIclaims') > -1 ||
          response.config.url.indexOf('preLicensing/apply') > -1 ||
          response.config.url.indexOf('/preLicensing/patch') > -1 ||
          response.config.url.indexOf('web/auth/getProviderList') > -1 ||
          response.config.url.indexOf('getBaiduMapInfo') > -1
        ) {
          return response
        } else if (response.config.url.indexOf('login') == -1) {
          window.message.error(returnMsg)
        }
        return Promise.reject(response)
      } else {
        return response
      }
    }
    return response
  },
  function(error) {
    // Do something with response error
    console.log('Error:0', error)
    if (error.response) {
      // 请求已发出，但服务器响应的状态码不在 2xx 范围内
      errorHandler(error)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message)
    }
    if (error.message.indexOf('timeout') > -1) {
      window.notification.error({
        message: `请求超时`
      })
    }
    _hideLoading()
    return Promise.reject(error)
  }
)

export default instance
