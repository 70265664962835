<template>
  <div class="page_">
    <a-form :form="searchForm" layout="horizontal" @submit="handleSearchForm">
      <a-form-item
        class="c_form_item_ pad15_ h58_"
        :label="$t('labels[0]') || '查询项'"
      >
        <a-row style="margin-left: 12px;">
          <a-col :span="16">
            <a-radio-group @change="onAChange" v-model="aValue">
              <a-radio :value="1">{{ $t('cols[4]') || '批次号' }}</a-radio>
              <a-radio :value="2">{{ $t('cols[3]') || '案件号' }}</a-radio>
            </a-radio-group>
          </a-col>
          <a-col class="p_static_" :span="8">
            <div class="right_">
              <a-button @click="createCasePath" type="primary"
                >{{ $t('btns[4]') || '新建批次' }}
              </a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-item>
      <a-row class="c_form_item_ h58_" :gutter="12">
        <a-col :span="6" class="marLeft_20">
          <a-form-item :label="$t('labels[0]') || '查询内容'">
            <a-input
              @change="handleContentChangeWithCommon"
              v-decorator="[
                'content',
                {
                  initialValue: searchContent
                }
              ]"
              :placeholder="cPlaceholder"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="2" class="marLeft_20">
          <a-button html-type="submit" type="primary">{{
            $t('btns[0]') || '查询'
          }}</a-button>
        </a-col>
        <a-col :span="2">
          <a-button type="primary" ghost @click="resetConditions">{{
            $t('btns[18]') || '重置'
          }}</a-button>
        </a-col>
        <a-col :span="14">
          <div class="right_">
            <a-checkbox @change="onSelectAll">{{
              $t('btns[3]') || '勾选全部记录'
            }}</a-checkbox>
            <a-button @click="exportAuthData" type="primary">{{
              $t('btns[4]') || '导出数据'
            }}</a-button>
          </div>
        </a-col>
      </a-row>
    </a-form>
    <div style="width: 100%;overflow-x: auto;">
      <a-table
        :rowKey="record => record.id"
        style="margin-top: 20px;"
        :pagination="pagination"
        @change="handlePageChange"
        :columns="columns"
        :dataSource="data"
        :row-selection="rowSelection"
      >
        <template slot="batchNo" slot-scope="text, record">
          <a
            href="javascript:;"
            style="margin-right:25px"
            @click="memeger(record)"
            >{{ record.batchNo }}</a
          >
        </template>
        <template slot="authorizationCode" slot-scope="text, record">
          <router-link :to="`authDetail/${record.id}`">{{ text }}</router-link>
        </template>
        <template slot="estBills" slot-scope="text">
          {{ text | addCommas }}
        </template>
        <template slot="discount" slot-scope="text">
          {{ text ? Number(text) + '%' : '-' }}
        </template>
        <template slot="do" slot-scope="text, record">
          <a
            href="javascript:;"
            style="margin-right:10px"
            :disabled="record.discountStatus != '-1'"
            @click="memeger(record)"
            >管理</a
          >
          <a-popconfirm
            :disabled="record.discountStatus != '-1'"
            title="是否确定删除当前批次记录"
            @confirm="() => onDelete(record.batchNo)"
          >
            <a
              href="javascript:;"
              style="margin-right:10px"
              :style="
                record.discountStatus == '-1' ? 'color:rgb(213, 44, 44)' : ''
              "
              :disabled="record.discountStatus != '-1'"
              >删除</a
            >
            <a
              href="javascript:;"
              @click="confirmTemp(record)"
              :disabled="record.discountStatus != '1'"
              >确认折扣</a
            >
          </a-popconfirm>
        </template>
      </a-table>
    </div>
    <claim-about-modals></claim-about-modals>
    <a-spin class="loading_" size="large" :spinning="spinning"></a-spin>
  </div>
</template>
<script>
import commonMixin from '@/mixins/common'
import authorizedMixin from '@/mixins/authorized'
import ClaimAboutModals from '../components/ClaimAboutModals'
import moment from 'moment'

export default {
  data() {
    const columns = [
      // {
      //   title: "",
      //   key: "id",
      //   scopedSlots: { customRender: "check" },
      //   width: "50px"
      // },
      {
        title: this.$t('common.index'),
        width: '50px',
        customRender: (t, r, index) => {
          return parseInt(
            `${(this.pagination.current - 1) * this.pagination.pageSize +
              (index + 1)}`
          )
        }
      },
      {
        title: this.$t('cols[4]') || '批次号',
        dataIndex: 'batchNo',
        key: 'batchNo',
        width: '150px',
        scopedSlots: { customRender: 'batchNo' },
        sorter: true
      },
      {
        title: this.$t('cols[3]') || '案件量',
        dataIndex: 'totalNum',
        key: 'totalNum',
        sorter: true
      },
      {
        title: this.$t('cols[1]') || '折扣后赔付总额',
        dataIndex: 'approvedAmount',
        key: 'approvedAmount',
        sorter: true
      },
      {
        title: this.$t('preAuthCols[1]') || '项目内赔付总额',
        dataIndex: 'intraItemApprovedAmount',
        key: 'intraItemApprovedAmount',
        sorter: true
      },
      {
        title: this.$t('preAuthCols[2]') || '就诊均值',
        dataIndex: 'averageAdmissionAmount',
        key: 'averageAdmissionAmount',
        scopedSlots: { customRender: 'estBills' },
        sorter: true
      },
      {
        title: this.$t('preAuthCols[3]') || '除外项目折后赔付总额',
        dataIndex: 'extraItemApprovedAmount',
        key: 'extraItemApprovedAmount',
        sorter: true
      },
      {
        title: this.$t('claimCols[7]') || 'ASP管控赔付额',
        dataIndex: 'aspAmount',
        key: 'aspAmount',
        sorter: true
      },
      {
        title: this.$t('claimCols[8]') || 'Pay To Provider',
        dataIndex: 'payToProvider',
        key: 'payToProvider',
        sorter: true
      },
      {
        title: '折扣率',
        dataIndex: 'discount',
        key: 'discount',
        sorter: true,
        scopedSlots: { customRender: 'discount' }
      },
      {
        title: this.$t('preAuthCols[4]') || '创建日期',
        dataIndex: 'createTime',
        key: 'createTime',
        sorter: true,
        customRender: text => moment(text).format('YYYY-MM-DD')
      },
      {
        width: 180,
        title: this.$t('cols[7]') || '操作',
        key: 'do',
        scopedSlots: { customRender: 'do' }
      }
    ]
    return {
      checkedVals: [],
      columns,
      data: [],
      waitSize: undefined,
      refuseSize: undefined,
      approvalSize: undefined,
      patchPageSize: undefined,
      id: 1,
      aValue: 1,
      searchForm: this.$form.createForm(this),
      cPlaceholder: this.$t('tips[10]') || '请输入批次号',
      pagination: {
        pageSize: 10,
        total: 0,
        currentPage: 1,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '150', '200'],
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      },
      conFlag: '',
      dateFormat: ['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYYMMDD'],
      pageOrderBy: '',
      searchContent: ''
    }
  },
  components: {
    'claim-about-modals': ClaimAboutModals
  },
  mixins: [commonMixin, authorizedMixin],
  mounted() {
    this.fetchData()
  },

  computed: {
    // 多选;
    rowSelection() {
      return {
        selectedRowKeys: this.checkedVals,
        onChange: this.handleSelectChange
      }
    }
  },
  methods: {
    handleSelectChange(keys) {
      this.checkedVals = keys
    },
    handlePageChange(page, filter, sorter) {
      if (!this.data || !this.data.length) {
        return false
      }
      this.pagination.current = page.current
      let { field, order } = sorter
      order = order ? (order === 'ascend' ? 'asc' : 'desc') : undefined
      this.pageOrderBy = order ? `"${field}" ${order}` : ''
      this.fetchData()
    },
    fetchData(returnOptions) {
      let params = {
        pageNo: this.pagination.current,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        claimsStatus: this.conFlag,
        pageOrderBy: this.pageOrderBy
      }
      this.searchForm.validateFields((err, options) => {
        if (!err) {
          if (options) {
            switch (this.aValue) {
              case 1:
                params.batchNo = options.content
                break
              case 2:
                params.claimsId = options.content
                break
              case 3:
                params.patientName = options.content
                break
              case 4:
                params.policyNo = options.content
                break
              case 5:
                break
            }
            if (options.rangeDate && options.rangeDate.length > 1) {
              params.admissionDateBegin = options.rangeDate[0].format(
                'YYYY-MM-DD'
              )
              params.admissionDateEnd = options.rangeDate[1].format(
                'YYYY-MM-DD'
              )
            }
            if (options.idType) {
              params.idType = options.idType
            }
            if (options.birthDate) {
              params.birthDate = options.birthDate
            }
          }
        }
      })
      if (returnOptions) {
        return params
      }
      this.$apis
        .queryBatchReportList(params)
        .then(res => res.data)
        .then(data => {
          const {
            total,
            pageSize,
            approvalSize,
            refuseSize,
            waitSize,
            list
          } = data.data
          this.data = list
          this.pagination.total = total
          this.patchPageSize = pageSize
          this.approvalSize = approvalSize
          this.refuseSize = refuseSize
          this.waitSize = waitSize
        })
    },
    handleSearchForm(e) {
      e && e.preventDefault()
      this.pagination.current = 1
      this.fetchData()
    },
    onSelectAll(e) {
      if (e.target.checked) {
        this.checkedVals = this.data.map(item => item.claimsId)
      } else {
        this.checkedVals = []
      }
    },
    onAChange(e) {
      const val = e.target.value
      let str = ''
      this.searchContent = ''
      this.searchForm.setFieldsValue({
        content: ''
      })
      switch (val) {
        case 1:
          str = this.$t('tips[21]') || '请输入批次号'
          this.searchContent = ''
          this.searchForm.setFieldsValue({
            content: ''
          })
          break
        case 2:
          str = this.$t('tips[11]') || '请输入案件号'
          break
        case 3:
          str = this.$t('tips[12]') || '请输入客户姓名'
          break
        case 4:
          str = this.$t('tips[13]') || '请输入客户保单号'
          break
        case 5:
          str = this.$t('tips[14]') || '请输入客户病历号'
          break
      }
      this.cPlaceholder = str
    },
    exportAuthData() {
      const params = this.fetchData(1)
      this.$apis.batchReportExportData(params).then(res => {
        const blob = new Blob([res.data], { type: 'application/zip' })
        const filename = res.headers['content-disposition']
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        ;[downloadElement.download] = [filename.split('=')[1]]
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放blob对象
      })
    },
    resetConditions() {
      this.searchContent = ''
      this.searchForm.setFieldsValue({
        content: '',
        rangeDate: []
      })
      switch (this.aValue) {
        case 1:
          this.searchContent = ''
          this.searchForm.setFieldsValue({
            content: ''
          })
          break
        case 2:
          this.searchForm.setFieldsValue({
            idType: ''
          })
          break
        case 3:
          this.searchForm.setFieldsValue({
            birthDate: null
          })
          break
      }
    },
    createCasePath() {
      this.$router.push({ path: `/createCaseBatch/` })
    },
    confirmTemp(record) {
      if (!record.discount) return this.$message.error('当前案件暂无折扣率')
      if (record.discountStatus != '1')
        return this.$message.error('当前案件暂时无法确认')
      let _this = this
      this.$confirm({
        title: `当前案件批次折扣率为${record.discount + '%' || 0},是否确认`,
        // content: h => <div style="color:red;">Some descriptions</div>,
        okText: '确定',
        cancelText: '取消',
        async onOk() {
          const result = await _this.$apis.confirmBatchDiscount({
            batchNo: record.batchNo,
            discount: record.discount
          })
          if (result.data.code === 200) {
            _this.$message.success('确认成功')
            _this.fetchData()
          } else {
            _this.$message.error(result.data.msg || '确认失败')
          }
        }
      })
    },
    memeger(data) {
      sessionStorage.setItem('batchData', JSON.stringify(data))
      this.$router.push({ path: `/managerCaseBatch/${data.batchNo}` })
    },
    async onDelete(batchNo) {
      const result = await this.$apis.removeBatchReport({ batchNo })
      if (result.data.code === 200) {
        this.$message.success('删除成功')
        this.fetchData()
      }
    }
  }
}
</script>
