import gwCN from './gw-zh-cn'

export default {
  ...gwCN,
  title: '',
  login: {
    a: '',
    b: '',
    c: '',
    d: '',
    e: '',
    f: '',
    g: '',
    h: '',
    dl: '',
    wj: '',
    yhm: '',
    mm: '',
    yzm: '',
    xgmm: '',

    // 中间带公众号PC
    welcome: '',
    lWelcome: '',
    loType: '',
    aPlaceholder: '',
    apwd: '',
    remember: '',
    forgot: '',
    login: '',
    loginout: ''
  },
  dashboard: {
    upload: '理赔件批量上传',
    documents:
      '请按以下顺序排放理赔材料∶理赔申请表（写上理赔号）+账单+病历（化验报告）+其它',
    format: '请使用JPG格式',
    claimDocuments: '理赔材料',
    selectFile: '选择文件',
    remark: '备注',
    confirm: '确认',
    cancel: '取消',
    zipNotEmpty: '文件不能为空',
    tips: '上传成功'
  },
  menus: ['', '', '', '', '', '', ''],
  labels: [
    '',
    '',
    '',
    '',
    '', // 4
    '',
    '',
    '',
    '',
    '',
    '', // 10
    '',
    '', // 全部授权
    '',
    '', // 14
    '',
    '',
    '',
    '',
    '',
    '', // 20
    '',
    '',
    '',
    '',
    '', // 25
    '',
    '', // 27
    '',
    '',
    '', // 30
    '', // 31
    '',
    '',
    '',
    '', //35
    '',
    '',
    '',
    '',
    '', //40
    '',
    '',
    '',
    '',
    '', // 45
    '',
    '',
    '',
    '',
    '', // 50
    '',
    '',
    '',
    '',
    '', // 55
    '',
    '',
    '',
    '',
    '', // 60
    '',
    '',
    '会员备注',
    '',
    '', // 65
    '疫苗结算：',
    '已消耗{amount}元'
  ],
  tips: [
    '',
    '',
    '',
    '',
    '', // 4
    '',
    '',
    '',
    '',
    '',
    '', // 10
    '',
    '', // 全部授权
    '',
    '', // 14
    '',
    '',
    '',
    '',
    '',
    '', // 20
    '',
    '',
    '',
    '',
    '', // 25
    '',
    '', // 27
    '',
    '',
    '年住院天数', // 30
    '', // 31
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '', // 50
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '', // 60
    ''
  ],
  judge: [
    '', //0
    '',
    ''
  ],
  btns: [
    '',
    '',
    '',
    '',
    '', // 5
    '',
    '', // 打印
    '',
    '',
    '', // 10
    '',
    '',
    '',
    '',
    '', // 15
    '',
    '', // 授权
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ],
  p: ['', '', '', '', '', '', ''],
  cols: [
    '',
    '',
    '',
    '',
    '', // 5
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '' // 账单号
  ],
  claimCols: [
    '',
    '',
    '',
    '',
    '', // 5
    '',
    '',
    '',
    '',
    '', // 10
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ],
  preAuthCols: [
    '',
    '',
    '',
    '',
    '', // 4
    '',
    '',
    '',
    '',
    '', // 9
    '',
    ''
  ],
  claimDetail: [
    '',
    '',
    '',
    '',
    '', // 5
    '',
    '',
    '',
    '',
    '', // 10
    '',
    '',
    '',
    '',
    '', // 15
    '',
    '',
    '',
    '',
    '', // 20
    '',
    '',
    '',
    '',
    '', // 25
    '',
    '',
    '',
    '', //
    '',
    '',
    '',
    '',
    '',
    ''
  ],
  Modals: [
    '',
    '',
    '',
    '',
    '', // 4
    '',
    '',
    '',
    '',
    '',
    '', // 10
    '',
    '', // 全部授权
    '',
    '', // 14
    '',
    '',
    '',
    '',
    '',
    '', // 20
    '',
    '',
    '',
    '',
    '', // 25
    '',
    '', // 27
    '',
    '',
    '', // 30
    '', // 31
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '', //39
    '',
    '',
    '',
    '',
    '',
    '', // 45
    '',
    '',
    '申请预授权',
    '继续申请',
    '住院', // 50
    '预授权申请',
    '预计入院日期',
    '预计出院日期',
    '预估金额',
    '病历资料', // 55
    '预授权回复邮箱'
  ],
  colon: '：',
  //pc公众号-->
  index: [
    '', //0
    '',
    '',
    '',
    '', // 4
    '',
    '',
    '',
    '',
    '',
    '', // 10
    '',
    '', //
    '',
    '', // 14
    '',
    '',
    '',
    '',
    '',
    '', // 20
    '',
    '',
    '',
    '',
    '', // 25
    '',
    '', // 27
    '',
    '',
    '', // 30
    '', // 31
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ],
  menuList: [
    '首页',
    '我的账户',
    '个人信息',
    '保险信息',
    '修改密码',
    '业务办理', //5
    '理赔办理',
    '预授权办理',
    '直付医院列表',
    '其他功能',
    '文件下载', //10
    '',
    '系统设置',
    '功能',
    '就医评价',
    '预约列表', // 15
    '隐私政策'
  ],
  onlineChat: '在线客服',
  OnlineCusSerCenter: '在线客服中心',
  territory: '境内',
  abroad: '境外',
  enter: '点击进入',
  InsuranceInfo: [
    '', //0
    '',
    '',
    '',
    '', // 4
    '',
    '',
    '',
    '',
    '',
    '', // 10
    '',
    '', //
    '',
    '', // 14
    '',
    '',
    '',
    '',
    '',
    '', // 20
    '',
    '',
    '',
    '',
    '', // 25
    '',
    '', // 27
    '',
    '',
    '', // 30
    '责任类别', // 31
    '等待期',
    '赔付标准'
  ],
  claim: [
    '', //0
    '',
    '',
    '',
    '', // 4
    '',
    '',
    '',
    '',
    '',
    '', // 10
    '',
    '', //
    '',
    '', // 14
    '',
    '',
    '',
    '',
    '',
    '', // 20
    '',
    '',
    '',
    '',
    '', // 25
    '',
    '', // 27
    '',
    '',
    '', // 30
    '',
    '',
    '',
    '', //34
    '',
    '',
    '', //37
    '',
    '',
    '', //40
    '',
    '',
    '',
    '', //44
    '', //45
    '',
    '',
    '',
    '',
    '', //50
    '',
    '',
    '',
    '退保日期',
    '我已阅读并同意', //55
    '《反保险欺诈协议》',
    '《理赔申请确认条款》',
    '请先同意阅读须知内容',
    '确认',
    '', //60
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ],
  bankInfo: [
    '开户行名称',
    '支行名称',
    '银行卡号',
    '操作',
    '户名',
    '银行卡信息',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ],
  claimStep: ['理赔须知', '填写申请', '上传资料', '完成'],
  relationship: ['本人', '配偶', '子女', '父母'],
  medicalLocation: ['境内', '境外'],
  personalInfo: [
    '',
    '',
    '',
    '',
    '', //4
    '',
    '',
    '',
    '',
    '', //9
    '',
    '',
    '',
    '',
    '', //14
    '', //15
    '',
    '',
    '',
    '',
    '', //20
    '',
    '',
    '',
    '',
    '', //25
    '',
    '',
    '',
    '',
    '', //30
    '',
    '',
    '',
    '',
    '', //35
    '',
    '',
    '',
    '',
    '', //40
    '',
    '',
    '',
    '',
    '', //45
    '',
    '',
    '',
    '',
    '', // 50
    ''
  ],
  claimDetails: [
    '',
    '',
    '',
    '',
    '', //4
    '',
    '',
    '',
    '',
    '自助理赔进度查询', //9
    '理赔案件受理进度、历史记录状态查看', //10
    '自助理赔申请',
    '门诊医疗 住院医疗 住院补贴',
    '待缴自付额查询',
    '对需要自己承担的费用进行缴纳',
    '自助理赔服务指南', //15
    '操作指引、应备材料、流程指引',
    '理赔材料补充',
    '对理赔反馈问题进行补充上传',
    '事后理赔申请表下载',
    '进行索赔时，相关单证下载', //20
    '',
    '',
    '',
    '',
    '', //25
    '',
    '',
    '',
    '',
    '', //30
    ''
  ],
  alertPrompt: [
    '温馨提示',
    '撤销后，您将无法再编辑该案件，确定撤销吗?',
    '确认撤销',
    '取消',
    '',
    '理赔服务指南中文版', //5
    '',
    '',
    '请上传带圆点的必传项',
    '请选择与出险人关系',
    '医疗机构，请输入0~200个字符', //10
    '请选择就诊日期',
    '请选择保险责任',
    '请填写申请金额',
    '',
    '', //15
    '请输入大于0的数',
    '就诊日期不在保障范围内',
    '备注：请输入0~125个汉字、数字或字母(500字节)',
    '请选择授权类别',
    '请输入预估住院天数', //20
    '请输入预估医疗费用',
    '请选择诊断内容',
    '请输入姓名',
    '请选择性别',
    '请输入手机号',
    '请输入手机验证码',
    '请选择证件类型',
    '请输入证件号码',
    '请选择证件有效期'
  ],
  statusText: [
    '全部',
    '已撤销',
    '审核中',
    '已结案',
    '审核完成',
    '一年',
    '半年',
    '三个月',
    '待付款',
    '已付款',
    '未结算',
    '已结算',
    '已提现',
    '赔付',
    '拒付'
  ],
  preauthText: [
    '预授权申请',
    '请您关注保险条款中约定的授权条目',
    '预授权材料补充',
    '对授权反馈问题进行补充上传',
    '预授权进度查询',
    '授权案件受理进度、历史记录状态查看', //5
    '预授权服务指南',
    '操作指引、应备材料、流程指引',
    '预授权单证下载',
    '进行预授权申请时，相关单证下载',
    '', //10
    '',
    '',
    '',
    '',
    '' //15
  ],
  moduleList: {
    Index: '首页',
    Info: '我的账户 > 个人信息',
    Account: '我的账户 > 保险信息',
    UpdatePwd: '我的账户 > 修改密码',
    ClaimsBusiness: '业务办理 > 理赔办理',
    Benefits: '我的账户 > 保险信息 > 保险福利',
    ClaimApply: '业务办理 > 理赔办理 > 自助理赔申请',
    ClaimQuery: '业务办理 > 理赔办理 > 理赔进度查询',
    PreAuthBusiness: '业务办理 > 预授权办理',
    PreAuthApply: '业务办理 > 预授权办理 > 预授权申请',
    PreAuthQuery: '业务办理 > 预授权办理 > 预授权进度查询',
    HospitalList: '业务办理 > 直付医院列表',
    HospitalDetail: '业务办理 > 直付医院列表 > 医院详情',
    PreDetail: '预授权办理 > 预授权进度查询 > 预授权详情',
    PreAuthDetail: '预授权办理 > 预授权进度查询 > 预授权详情',
    ClaimDetail: '理赔办理 > 理赔进度查询 > 理赔详情',
    AppClaimDetail: '理赔办理 > 理赔进度查询 > 理赔详情',
    ClaimSupplement: '业务办理 > 理赔办理 > 理赔材料补充',
    ClaimSupplementDetail: '业务办理 > 理赔办理 > 理赔材料补充 > 材料补充详情',
    ClaimUnPaidQuery: '业务办理 > 理赔办理 > 待缴自负额查询',
    MedicalRecords: '业务办理 > 就医评价查询',
    SubscribeLists: '业务办理 > 预约列表 ',
    SubscribeDetails: '业务办理 > 预约列表 > 预约单详情',
    Subscribe: '业务办理 > 预约列表 > 填写预约单',
    MedicalEvaluation: '业务办理 > 就医评价提交',
    PreAuthSupplement: '业务办理 > 预授权办理 > 预授权材料补充',
    PreAuthSupplementDetail:
      '业务办理 > 预授权办理 > 预授权材料补充 > 材料补充详情',
    FileDownload: '其他功能 > 文件下载',
    ClaimUnPaidDetail: '业务办理 > 理赔办理 > 待缴自负额查询 > 待缴自负额详情',
    privacyAgreementInner: '其他功能 > 隐私政策'
  },
  medicalInstitutionSearch: {
    all: '全部',
    area: '全部地区',
    plan: '全部产品',
    property: '性质',
    class: '类别',
    level: '等级',
    placeHolder: '请输入医院名称',
    hProperty: '医院性质',
    hClass: '医院类别'
  },
  time: {
    all: '全部',
    year: '一年',
    halfYear: '半年',
    month: '三个月'
  },
  property: {
    all: '全部',
    p1: '民营',
    p2: '公立',
    p3: '外资'
  },
  level: {
    l1: '全部',
    l2: '三级甲等',
    l3: '三级乙等',
    l4: '三级丙等',
    l5: '二级甲等',
    l6: '二级乙等',
    l7: '二级丙等',
    l8: '一级甲等',
    l9: '一级乙等',
    l10: '一级丙等',
    l11: '未评级'
  },
  class: {
    c1: '全部',
    c2: '综合医院',
    c3: '专科医院',
    c4: '综合诊所',
    c5: '专科诊所',
    c6: '体验中心',
    c7: '药房',
    c8: '妇产医院',
    c9: '儿童医院',
    c10: '儿童诊所',
    c11: '妇儿医院',
    c12: '骨科医院',
    c13: '康复诊疗...',
    c14: '眼科医院',
    c15: '专眼科诊所',
    c16: '中医诊所',
    c17: '肿瘤诊所',
    c18: '中医医院'
  },
  sublist: {
    appointing: '预约中',
    appoined: '已完成',
    orderNumber: '预约单号',
    Name: '医疗机构名称',
    Section: '预约科室',
    Doctors: '预约医生',
    Attendees: '就诊人',
    Time: '就诊日期和时间',
    Status: '预约状态',
    Operation: '操作',
    look: '查看',
    cancel: '取消预约',
    again: '再次预约',
    cancelText: '是否取消预约？',
    oktext: '是',
    canceltext: '否',
    tipmsg: '提示',
    tipContent: '您确定要取消本次预约吗？',
    InsuranceCard: '保险卡',
    placeInsuranceCard: '请输入保险卡号',
    errorInsuranceCard: '保险卡不能为空！',
    thePatient: '就诊人',
    placethePatient: '请输入就诊人！',
    errorthePatient: '就诊人不能为空！',
    contact: '联系方式',
    placecontact: '请填写就诊人的联系方式',
    errorcontact: '联系方式不能为空！',
    medicalInstitution: '医疗机构',
    placemedicalInstitution: '请选择医疗机构',
    errormedicalInstitution: '医疗机构不能为空！',
    Department: '科室',
    placeDepartment: '请填写期望预约科室（非必填）',
    errorDepartment: '科室不能为空！',
    Doctor: '医生',
    placeDoctor: '请填写期望预约医生（非必填）',
    errorDoctor: '医生不能为空！',
    ExpectedDate: '预约日期',
    placeExpectedDate: '预约日期',
    errorExpectedDate: '预约日期不能为空！',
    ExpectedTime: '预约时间',
    placeExpectedTime: '预约时间',
    errorExpectedTime: '预约时间',
    remarks: '备注',
    placeremarks:
      '如有备选医院、备选科室、备选医生、备选日期和时间，可填写在此处（非必填，最多输入80个汉字，160个字符）',
    submitData: '提交预约信息',
    cancelapply: '取消预约',
    cacelbtn: '取消',
    P: '处理中',
    I: '处理中',
    O: '已受理',
    C: '预约完成',
    L: '预约取消',
    F: '预约失败',
    yuyueBtn: '预约',
    symptom: '症状：',
    placesymptom: '请输入您的症状（必填，最多输入80个汉字，160个字符）',
    healthInfo1:
      '接触史：就诊人和陪同者健康码中健康状态是否为“未见异常（绿色）”？近期是否有中高风险境外、境内旅居史？近期是否接触过疑似新冠病毒感染者？',
    healthInfo2:
      '疑似症状：就诊人3日内是否体温≥37.3℃（明确疾病导致长期发热除外）或存在干咳、咽痛、咯痰、嗅觉味觉减退等疑似症状？',
    healthInfoFlag1: '是',
    healthInfoFlag2: '否',
    des1: '补充信息',
    des2: '请简要说明相关情况',
    prompt: '该医疗机构暂不支持周六周日预约，请重新选择日期',
    Languageofvisit: '就诊语言',
    cardDate: '保险有效期'
  },
  hdetail: {
    card: '保险卡',
    province: '请选择省',
    city: '请选择市',
    detail: '查看详情',
    nodata: '暂无数据',
    des: '医院介绍',
    des1: '医院简介',
    service: '相关服务',
    question1: '如何到达直付就医部门',
    question2: '特色科室',
    question3: '住院服务',
    question4: '直付就医流程',
    question5: '就医体验',
    question6: '其他科室',
    question7: '查看就医体验',
    to: '至',
    hospitalProperty: '医院性质',
    fullhours: '24小时',
    emergency: '急诊',
    hospitalClass: '医院类别',
    specificClass: '专科类别',
    openTime: '开放时间',
    docPrompt: '就医提示',
    parkInfo: '停车信息',
    baseInfo: '基本信息',
    address: '医院地址',
    phone: '电话',
    level: '医院等级',
    lanuage: '语言',
    fold: '收起',
    unfold: '展开',
    unknowname: '匿名用户',
    back: '返回',
    medicalRecordQuery: '就医评价查询',
    medicalRecordQuery2: '就医评价查看',
    writeExperience: '填写就医体验',
    patient: '就诊人',
    date: '就诊日期',
    diagnosis: '诊断',
    tit: '服务满意度',
    placeHolder: '谈谈您的就医感受吧，限200字',
    submit: '匿名提交',
    submitBtn: '提交就医体验',
    nomore: '没有更多了哦'
  },
  preAuth: {
    tit: '多案件授权',
    place: '医疗机构',
    material: '补充材料',
    des: '授权解释',
    des1: '理赔解释',
    materialStatus: '有材料待补充',
    serNum1: '理赔编号',
    preAuditCon: '预授权审核结论',
    offcialserNum: '正式理赔编号',
    serCard: '保险卡号',
    claimProgress: '理赔进度', //理赔进度
    pay: '直付',
    reasonDes: '不通过原因',
    claimMoney: '赔付金额',
    paidMoney: '自负金额',
    paidRemark: '付款标识',
    claimsResult: '理赔结论',
    preDoctorTime: '就诊日期',
    preDoctorTime1: '预计就诊日期',
    preDoctorTime2: '预计住院日期',
    judge: '诊断',
    inHospitalTime: '预估住院天数',
    medicalPay: '预估医疗费用',
    authCategory: '授权类别',
    reviewProgress: '审核进度',
    reviewResult: '审核结论',
    needSupple: '需要补充',
    preAuthNo: '授权编号',
    sickInfo: '病人信息',
    insured: '被保险人',
    predate: '预计就诊日期',
    preDetail: '预授权详情',
    newApply: '新增预授权申请',
    serPerson: '保单号/出险人',
    need: '需要补充'
  },
  checkout: {
    tit: '中间带收银台',
    name: '商品名称',
    ordername: '自付额支付',
    order: '订单编号',
    amount: '订单金额',
    date: '订单日期',
    weChat: '微信支付',
    alipay: '支付宝支付',
    card: '银行卡支付',
    mode: '请选择支付方式',
    paymentof: '待支付',
    confirm: '确认支付',
    unit: '元',
    symbol: '￥'
  },
  cashierDesk: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ],
  ruleCon: [
    '反保险欺诈提协议',
    '诚信是保险合同基本原则，涉嫌保险诈骗将承担以下责任：',
    '【刑事责任】进行保险诈骗犯罪活动，可能会受到拘役、有期徒刑，并处罚金或没收财产的刑事处罚，保险事故的鉴定人、证明人故意提供虚假的证明文件，为他人犯罪提供条件的，以保险诈骗罪的共犯论处',
    '【行政责任】进行保险诈骗活动，尚不构成犯罪的，可能会受到15日以下的拘留、5000元以下罚款的行政处罚；保险事故的鉴定人、证明人故意提供虚假的证明文件，为他人诈骗提供条件的，也会受到相应行政处罚。',
    '【民事责任】故意或因重大过失未履行如实告知义务，保险公司不承担赔偿或给付保险金的责任', //4
    '理赔申请确认条款',
    '我同意，在任何情况下，保险保障所不包含的医疗费用及服务等将由我本人足额支付。我同意由保险公司或者中间带直接联系我并通过银行转账的方式收取。如果我不能按时支付，我将承担由此产生的额外费用，包括合理的法律诉讼费用、保险公司、中间带或医疗机构向我收取此费用过程所产生的其他费用等。',
    '我同意因理赔或相关服务需要，保险公司、医疗机构及中间带可以分享与我本人有关的保险福利、健康、医疗信息及资料。我同意任何医生、医疗机构、药剂师、保险公司、雇主、工会或协会将我就诊治疗、接受护理的相关病历、病史等资料信息（包括复印件）提供给中间带。我同意因理赔或相关服务需要，由保险公司或者中间带代我处理理赔款。我在此确认以上声明和授权真实完整，我本人愿意进一步提供其他所需信息。',
    '对于发生在事先约定的医疗机构内，针对特定的或本保险人已经事先担保的医疗项目，本人在此授权该医疗机构或预先指定的第三方代表本人向保险人索赔，保险人应该直接支付给该医疗机构或指定的第三方。',
    '以上授权的复印件与原件视为同等效力。',
    '' //10
  ],
  pay: {
    pay1: '个人自负额',
    pay2: '家庭自负额',
    status: '',
    type: '',
    wait: '待付保单',
    paid: '已付保单'
  },
  category: ['门诊', '住院'],
  fileList: [
    '中间带事先授权申请表',
    'MGU事先授权申请表',
    '中间带会员事后理赔申请单',
    'MGU会员事后理赔申请单',
    '表单下载',
    '使用指南查看',
    'member portal使用指南',
    'member portal会员操作手册'
  ],
  wordsDes: [
    '什么是自动理赔？',
    '自助理赔是指传统的报销模式，被保险人在就诊时全额向医疗机构支付医疗费用，随后持发票、病例、检查报告、申请表等资料再向保险公司或保险公司授权的第三方机构提出报销申请。',
    '什么是预授权？',
    '1、无论是直接付费服务，还是事后理赔，对特定的医疗治疗项目，保险公司均需要您事先获得授权批准，否则可能导致您额外花费了部分非合理且必需的费用而无法在保险保障范围内得到赔偿。甚至，应授权而未授权的案例会造成理赔金额的降级赔付。',
    '2、常见需申请预授权的医疗项目: 所有住院医疗保障； 复杂牙科治疗；门诊肿瘤放化疗治疗； 全麻下的门诊手术； 家庭护理；羊水穿刺检查（35岁以下的孕妇）； 购买拐杖、轮椅和助走器；加强CT/立体定向放射治疗装置（指伽马刀）；其他保险计划中约定需预先批准的医疗项目。',
    '3、您应在接受上述特定治疗项目前通知医院向中间带提交事先授权申请表，以便中间带客户联络中心在您预定开始治疗日期前至少3个工作日收到该申请表。中间带及您的保险公司将自收到该完整填写的申请表后的48小时内予以书面回复。若您的医疗费用过高或住院天数过长，中间带及您的保险公司可能需要更长的时间来进行审核，一般情况下将在5个工作日内给予答复。另外，出于授权审核的需要如批复过程中需要补充您其它的诊疗资料，还请您和你的主治医生协助提供，以便尽早完成授权审核。'
  ],
  unpaid: {
    totalMoney: '总金额',
    payMoney: '赔付金额',
    personMoney: '自负金额',
    waitPay: '待缴自负金额',
    detail: ''
  },
  cardUpload: {
    unDate: '已过期，重新上传',
    upload: '去上传',
    tit1: '上传证件照',
    tit2: '手动录入',
    type1: '身份证',
    type2: '护照',
    type3: '其他',
    upload1: '上传身份证正面',
    upload2: '上传身份证反面',
    upload3: '上传护照',
    upload4: '上传其他类型图片',
    upload1p: '请上传身份证正面',
    upload2p: '请上传上传身份证反面',
    upload3p: '请上传护照',
    upload4p: '上传其他类型图片'
  },
  extra: ['', '', ''],
  FirstPDate: '首保日期',
  message: {
    upgrade: '系统升级中，请点击确认后在新的页面中使用crtl+P打印文件',
    doctorRequired: '立案时请务必录入医生！',
    existErr: '存在不享受直付的门诊'
  },
  common: {
    confirm: '确定',
    cancel: '取消',
    index: '序号',
    confirmCancelTip: '确认删除此条数据？'
  },
  uploadModal: {
    remark: '备注：',
    input: '请输入...',
    select: '请选择',
    needFill: '是否有资料需联系客服补充：'
  },
  paramLabel: {
    expend: '消耗',
    remain: '剩余',
    times: '次'
  },
  // 优惠券模块
  coupons: {
    labels: ['全部', '生效中', '已失效', '卡名称', '卡号', '有效期'],
    btns: ['查看详情', '立即使用', '确认核销']
  }
}
