<template>
  <div class="myWelDetail_" :style="{ marginTop: '20px' }">
    <!-- part-1 保单信息-->
    <div class="list_">
      <div class="head_">
        <div class="flex tab-div">
          {{ $t('Modals[25]') || '保单信息' }}
        </div>
        <div class="h_right_ inner">
          <!-- 立案时请务必录入医生！ -->
          <div class="dialog" v-show="showDialog">
            <p class="title">{{ $t('sublist.tipmsg') }}</p>
            <p>{{ $t('message.doctorRequired') }}</p>
            <div class="text-left">
              <a-button type="primary" @click="verify">{{
                $t('common.confirm')
              }}</a-button>
            </div>
          </div>
          <div class="h_right_btn" @click="verify('preAuth')">
            {{ $t('Modals[48]') || '申请预授权' }}
          </div>
          <div class="h_right_btn" @click="showDialog = true">
            {{ $t('Modals[22]') || '身份验证' }}
          </div>
          <div class="h_right_btn" @click="printOutpatient">
            {{ $t('Modals[23]') || '门诊理赔单' }}
          </div>
          <div class="h_right_btn" @click="printInHospital">
            {{ $t('Modals[24]') || '住院理赔单' }}
          </div>
        </div>
      </div>
      <div class="content_">
        <div class="list_item">
          <a-row style="width:100%" :gutter="10">
            <a-col :span="6">
              <div>
                {{ $t('Modals[26]') || '被保险人' }}：{{ detail.policyVo.name }}
              </div>
            </a-col>
            <a-col :span="6">
              <div>
                {{ $t('Modals[2]') || '证件号' }}：{{
                  detail.policyVo.idNumber
                }}
              </div>
            </a-col>
            <a-col :span="6">
              <div>
                {{ $t('cols[4]') || '保险卡号' }}：{{ detail.policyVo.cardNo }}
              </div>
            </a-col>
            <a-col :span="6">
              <div>
                {{ $t('Modals[38]') || '会员标识' }}：
                <span style="color: red;">{{
                  detail.policyVo.memberClass
                }}</span>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="list_item">
          <a-row style="width:100%" :gutter="10">
            <a-col :span="6">
              <div>
                {{ $t('cols[1]') || '出生日期' }}：{{
                  detail.policyVo.birthDate
                }}
              </div>
            </a-col>
            <a-col :span="6">
              <div>
                {{ $t('claimDetail[21]') || '年龄' }}：{{ detail.policyVo.age }}
              </div>
            </a-col>
            <a-col :span="6">
              <div>
                {{ $t('cols[2]') || '性别' }}：{{ detail.policyVo.gender }}
              </div>
            </a-col>
            <a-col :span="6">
              <div>
                {{ $t('claimDetail[22]') || '主被保险人' }}：{{
                  detail.policyVo.mainInsure
                }}
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="list_item">
          <a-row style="width:100%" :gutter="10">
            <a-col :span="6">
              <div>
                {{ $t('cols[8]') || '公司名称' }}：{{
                  detail.policyVo.corporation
                }}
              </div>
            </a-col>
            <a-col :span="6">
              <div>
                {{ $t('labels[4]') || '保单号' }}：{{
                  detail.policyVo.policyNo
                }}
              </div>
            </a-col>
            <a-col :span="6">
              <div>MRN：{{ detail.policyVo.mrn }}</div>
            </a-col>
            <a-col :span="6">
              <div class="mainColor" v-if="data.groupCode && data.planType">
                【{{ data.groupCode }}】{{ data.planType }}
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="list_item">
          <a-row style="width:100%" :gutter="10">
            <a-col :span="6">
              <div>
                <!--如果参数cancelTime不为空,那么保单终止日期用cancelTime,并且标记颜色  -->
                {{ $t('labels[16]') || '保单年有效期' }}：{{
                  detail.policyVo.effectTime
                }}
                -
                <span :class="{ red_: detail.policyVo.cancelTime != '' }">
                  {{
                    !detail.policyVo.cancelTime
                      ? detail.policyVo.endTime
                      : detail.policyVo.cancelTime
                  }}
                </span>
              </div>
            </a-col>
            <a-col :span="6">
              <div>
                {{ $t('FirstPDate') }}：{{ detail.policyVo.firstInsureDate }}
              </div>
            </a-col>
            <a-col :span="6">
              <div>
                {{ $t('labels[18]') || '保单直付区域' }}：{{
                  detail.policyVo.directArea
                }}
              </div>
            </a-col>
            <a-col :span="6">
              <div>
                {{ $t('labels[25]') || '免赔额' }}：
                <span style="color:red">{{ detail.policyVo.deductible }}</span>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="list_item">
          <a-row style="width:100%" :gutter="10">
            <a-col :span="6">
              <div>{{ '高医AM' }}：{{ detail.policyVo.amName || '-' }}</div>
            </a-col>
            <a-col :span="6">
              <div>{{ '总限次' }}：{{ filterNull(data.limitTime) + '次' }}</div>
            </a-col>
            <a-col :span="6">
              <div>{{ '消耗' }}：{{ filterNull(data.useTimes) + '次' }}</div>
            </a-col>
            <a-col :span="6">
              <div>
                {{ '剩余' }}：{{ filterNull(data.remainingTime) + '次' }}
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="list_item">
          <a-row style="width:100%" :gutter="10">
            <a-col :span="12">
              <div>{{ 'PE(核保规则)' }}：{{ data.uaRuleDesc || '-' }}</div>
            </a-col>
            <a-col :span="6">
              <div v-if="!detail.policyVo.cashlessFacilityStatus">
                直付状态：-
              </div>
              <div
                v-else
                :class="
                  detail.policyVo.cashlessFacilityStatus != 'Y' ? 'red' : ''
                "
              >
                {{ '直付状态' }}：{{
                  detail.policyVo.cashlessFacilityStatus == 'Y'
                    ? '开启'
                    : '关闭'
                }}
              </div>
            </a-col>
            <a-col :span="6">
              <div>{{ '保司名称' }}：{{ data.companyName || '-' }}</div>
              <div class="red font_small">注意：寿险公司需要收集客户签字</div>
            </a-col>
          </a-row>
        </div>
        <div
          v-if="isExpensive || data.serviceCount || selfPayPhysiciansVOS.length"
          class="list_item border_btm_none"
        >
          <a-row style="width:100%">
            <a-col :span="6" v-if="isExpensive">
              <div class="red_">
                {{ $t('Modals[46]') || '昂贵医院' }}
              </div>
            </a-col>
            <a-col :span="6" v-if="data.serviceCount">
              <span class="red_"
                >增值服务已使用：{{ data.serviceCount }}次</span
              >
            </a-col>
            <a-col :span="12" v-if="selfPayPhysiciansVOS.length">
              <span class="red_">{{
                `计划一， 部分外聘医生需自付${selfPayPhysiciansVOS[0].selfPayAmount}挂号费`
              }}</span>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <!-- 增值服务 -->
    <div class="list_">
      <a-tabs type="card" v-model="tabKey" @change="changeTab">
        <a-tab-pane key="3" :tab="$t('labels[60]') || '会员备注'">
          <div class="content_">
            <div class="tab-cnt-word">{{ data.otherPreExisting }}</div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="4" :tab="$t('Modals[39]') || '预授权备注'">
          <div class="content_">
            <div
              class="tab-cnt-word"
              v-html="
                (data.preauth || '')
                  .replace(/\n/g, '<br>')
                  .replace(/<img/gi, `<img style='width:100%;height:auto;'`)
              "
            ></div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="1" :tab="$t('labels[47]') || '增值服务'">
          <div class="content_" v-if="data.serviceContent">
            <div class="tab-cnt-word">{{ data.serviceContent }}</div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('labels[59]') || '附件'">
          <div class="content_">
            <!-- 预览图片区域 -->
            <div class="preview-img" v-if="fileList && fileList.length">
              <div
                v-for="(item, i) in fileList"
                :key="i"
                class="mar-top10 preview-container"
              >
                <div class="preview-img-item">
                  <img
                    v-if="item.isImg"
                    :src="item.url"
                    title="点击放大"
                    @click="showImage(item)"
                  />
                  <a-icon
                    v-else
                    class="file-icon"
                    type="file"
                    title="点击查看文件"
                    @click="previewFile(item)"
                  />
                  <div class="file-name">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>

    <!-- 共享限额 -->
    <div class="list_">
      <div class="head_">
        <div>{{ $t('labels[47]') || '共享限额' }}</div>
        <div class="h_right_">
          <div
            class="h_right_btn"
            :style="{ width: '68px', color: '#46464F' }"
            @click="showData(limitsData)"
          >
            {{
              limitsData.isShow
                ? $t('labels[20]') || '收起'
                : $t('labels[21]') || '展开'
            }}
          </div>
        </div>
      </div>
      <div class="content_" v-if="limitsData.isShow">
        <div v-for="(item, index) in limitsData.limitAmount || []" :key="index">
          <div class="list_item" style="background-color: #f6f7f9;">
            <div class="flex_1">
              <span>
                {{
                  `${$t('labels[48]') || '以下费用共用'}${dealLimitShowText(
                    item.frequencyDesc
                  )}${$t('labels[52]') || item.limitDesc}`
                }}{{ item.maxvalue | addCommas }}
              </span>
            </div>
            <div class="flex_1">
              <span
                >{{ $t('labels[53]') || '已用额度'
                }}{{ item.consump || 0 | addCommas }}</span
              >
            </div>
          </div>
          <div class="content_">
            <div
              class="list_item"
              v-for="(a, i) in item.list || []"
              :key="a.benefitCode + i"
            >
              <div class="flex_1">
                <span>
                  {{
                    a.coverageDesc && a.benefitDesc
                      ? a.coverageDesc + '：' + a.benefitDesc
                      : a.coverageDesc || a.benefitDesc
                  }}
                </span>
              </div>
              <div class="flex_1">
                <span
                  >{{ $t('labels[54]') || '已用'
                  }}{{ a.consump || 0 | addCommas }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- part-3 普通门诊责任-->
    <div
      class="list_"
      v-for="(item, index) in detail.coverageDtoList"
      :key="index"
    >
      <div class="head_">
        <div>{{ item.loaDesc }}</div>
        <div class="h_right_">
          <div
            class="h_right_btn"
            :style="{ width: '68px', color: '#46464F' }"
            @click="showData(item, index)"
          >
            {{
              item.isShow
                ? $t('labels[20]') || '收起'
                : $t('labels[21]') || '展开'
            }}
          </div>
        </div>
      </div>
      <div v-show="item.isShow">
        <div class="content_">
          <div class="list_item">
            <div class="flex_1">
              {{ $t('labels[19]') || '是否开放直付' }}：
              <span
                v-if="item.cashlessFacility != '无直付'"
                class="themeColor_"
                >{{ item.cashlessFacility }}</span
              >
              <span v-else style="color: #E6002C;">{{
                item.cashlessFacility
              }}</span>
            </div>
            <div class="flex_1">
              {{ $t('labels[22]') || '直付刷卡上限' }}：
              <span v-if="parseInt(item.remainingAmt) < parseInt(item.edcMax)">
                {{ item.remainingAmt | addCommas }}{{ item.currcd }}
              </span>
              <span v-else>{{ item.edcMax | addCommas }}{{ item.currcd }}</span>
            </div>
          </div>
          <div class="list_item">
            <div class="flex_1">
              {{ $t('labels[23]') || '事先授权' }}：
              <span :class="item.preauthRequest != '无' ? 'red_' : ''">{{
                item.preauthRequest
              }}</span>
            </div>
            <div class="flex_1">
              {{ $t('labels[45]') || '等待期' }}：{{ item.watingPeroid }}
            </div>
          </div>
          <div class="list_item">
            <div class="flex flex_1">
              <div>
                {{ $t('labels[24]') || '年限额' }}：{{
                  item.conditionType > 50 ? $t('labels[42]') || '独立限额' : ''
                }}{{ item.amtIncurred | addCommas }}{{ item.currcd }}
                <span
                  class="red_"
                  v-if="
                    item.consumeAmt ||
                      item.consumeAmt === 0 ||
                      item.remainingAmt ||
                      item.remainingAmt === 0
                  "
                >
                  【
                  <span v-if="item.consumeAmt || item.consumeAmt === 0">
                    {{ $t('paramLabel.expend') }}{{ item.consumeAmt | addCommas
                    }}{{ item.currcd }}
                  </span>
                  <span v-if="item.remainingAmt || item.remainingAmt === 0">
                    ，{{ $t('paramLabel.remain')
                    }}{{ item.remainingAmt | addCommas }}{{ item.currcd }}
                  </span>
                  】
                </span>
              </div>
              <div v-if="Number(item.amtIncurredByDay)">
                {{ $t('labels[64]') || '日限额' }}：{{
                  item.amtIncurredByDay | addCommas
                }}{{ item.currcd }}
                <span
                  class="red_"
                  v-if="
                    Number(item.consumeAmtByDay) ||
                      Number(item.remainingAmtByDay)
                  "
                >
                  【
                  <span
                    v-if="item.consumeAmtByDay || item.consumeAmtByDay === 0"
                  >
                    {{ $t('paramLabel.expend')
                    }}{{ item.consumeAmtByDay | addCommas }}{{ item.currcd }}
                  </span>
                  <span
                    v-if="
                      item.remainingAmtByDay || item.remainingAmtByDay === 0
                    "
                  >
                    ，{{ $t('paramLabel.remain')
                    }}{{ item.remainingAmtByDay | addCommas }}{{ item.currcd }}
                  </span>
                  】
                </span>
              </div>
            </div>
            <div class="flex_1">
              {{ $t('labels[25]') || '免赔额' }}：
              <span style="color:red">
                {{
                  item.deductible == '无' || item.deductible == 'N/A'
                    ? detail.policyVo.deductible
                    : item.deductible
                }}
              </span>
            </div>
          </div>
          <div class="list_item">
            <div class="flex_1">
              <div class="flex">
                {{ $t('labels[26]') || '赔付比例' }}：
                <div
                  :class="
                    item.compensationRatio == '0%' ||
                    item.compensationRatio == '0'
                      ? 'red_'
                      : ''
                  "
                  v-html="dealWithStr(item.compensationRatio)"
                ></div>
              </div>
            </div>
            <div class="flex_1">
              {{ $t('labels[27]') || '限次' }}：{{ item.limitTime | addCommas }}
              <span
                class="red_"
                v-if="
                  item.useTimes ||
                    item.useTimes === 0 ||
                    item.remainingTime ||
                    item.remainingTime === 0
                "
              >
                【
                <span v-if="item.useTimes || item.useTimes === 0">
                  {{ $t('paramLabel.expend') }}{{ item.useTimes | addCommas
                  }}{{ $t('paramLabel.times') }}
                </span>
                <span v-if="item.remainingTime || item.remainingTime === 0">
                  ，{{ $t('paramLabel.remain')
                  }}{{ item.remainingTime | addCommas
                  }}{{ $t('paramLabel.times') }}
                </span>
                】
              </span>
            </div>
          </div>
          <!-- 测试环境8000142801933899有数据 -->
          <div
            class="list_item"
            v-if="item.yiMiaoConsump || item.yiMiaoConsump === 0"
          >
            <div class="flex_1 flex">
              {{ $t('labels[66]') }}
              <div>
                {{ $t('labels[67]', { amount: item.yiMiaoConsump }) }}
              </div>
            </div>
          </div>
        </div>
        <!-- part-2 费用项目列表-->
        <!-- v-if="item.benefits.length !== 0" -->
        <div class="head_">
          <div>{{ $t('labels[28]') || '费用项目列表' }}</div>
        </div>
        <div
          class="content_"
          v-for="(ele, index) in item.benefits"
          :key="index + 'benefits'"
        >
          <div class="list_item" v-if="ele.shareQuota">
            <div class="flex_1">
              <span class="themeColor_">
                {{ $t('labels[43]') || '以下费用共用年度限额'
                }}{{ ele.shareQuota | addCommas }}{{ ele.currcd }}
              </span>
              <span class="free">
                【{{ $t('labels[43]') || '剩余'
                }}{{ ele.remainingAmt | addCommas }}{{ ele.currcd }}】
              </span>
            </div>
          </div>
          <!-- :class="[index + 1 == ele.list.length ? 'border_btm_none' : '']" -->
          <div
            class="list_item"
            v-for="(list, index) in ele.list"
            :key="index + 'list'"
          >
            <div class="flex_1">{{ list.loaDesc }}</div>
            <div class="flex_1">
              <div
                class="list_item border_left"
                :class="[
                  index + 1 == list.list.length ? 'border_btm_none' : ''
                ]"
                v-for="(child, index) in list.list"
                :key="index + 'child'"
              >
                <div class="flex_1">
                  <div
                    v-if="
                      child.limitName == $t('tips[30]') &&
                        parseInt(child.limitNameDesc) == 999
                    "
                  >
                    <span v-if="child.limitName">{{ child.limitName }}：</span>
                    {{ $t('Modals[33]') || '无上限' }}
                  </div>
                  <div v-else>
                    <span v-if="child.limitName">{{ child.limitName }}：</span>
                    <span
                      :class="
                        child.limitNameDesc == '0%' ||
                        child.limitNameDesc == '0'
                          ? 'red_'
                          : ''
                      "
                    >
                      {{ child.limitNameDesc }}
                    </span>
                    <span
                      class="red_"
                      v-if="child.consumeAmt === 0 || child.consumeAmt"
                    >
                      {{ $t('paramLabel.expend')
                      }}{{ child.consumeAmt | addCommas }}{{ ele.currcd }}
                    </span>
                    <span
                      class="red_"
                      v-if="child.useTimes === 0 || child.useTimes"
                    >
                      {{ $t('paramLabel.expend')
                      }}{{ child.useTimes | addCommas
                      }}{{ $t('paramLabel.times') }}
                    </span>
                  </div>
                </div>
                <div class="flex_1">
                  <div
                    class="list_item border_left"
                    style="border-bottom: none;"
                  >
                    <span
                      class="red_"
                      v-if="child.remainingAmt === 0 || child.remainingAmt"
                    >
                      {{ $t('paramLabel.remain')
                      }}{{ child.remainingAmt | addCommas }}{{ ele.currcd }}
                    </span>
                    <span
                      class="red_"
                      v-if="child.remainingTime === 0 || child.remainingTime"
                    >
                      {{ $t('paramLabel.remain')
                      }}{{ child.remainingTime | addCommas
                      }}{{ $t('paramLabel.times') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 身份验证模态框 -->
    <a-modal
      :title="$t('Modals[22]') || '身份验证'"
      :footer="null"
      wrapClassName="myModal_ myWelDetail_"
      v-model="visibleChange"
    >
      <div class="list_" :style="{ border: '0' }">
        <div class="content_ content_1">
          <div class="list_item">
            <div class="mrg_r40">
              {{ $t('cols[0]') || '病人姓名' }}：{{ detail.policyVo.name }}
            </div>
            <div class="mrg_r40">
              {{ $t('cols[1]') || '出生日期' }}：{{ detail.policyVo.birthDate }}
            </div>
            <div class="mrg_r40">
              {{ $t('cols[2]') || '性别' }}：{{ detail.policyVo.gender }}
            </div>
          </div>
          <div class="list_item">
            <div class="mrg_r40" :style="{ marginRight: '56px' }">
              {{ $t('Modals[2]') || '证件号' }}
            </div>
            <div class="mrg_r40">{{ detail.policyVo.idNumber }}</div>
          </div>
          <div class="list_item">
            <div class="mrg_r40">{{ $t('cols[8]') || '公司名称' }}</div>
            <div class="mrg_r40">{{ detail.policyVo.corporation }}</div>
          </div>
          <div class="list_item border_btm_none">
            <div class="list_item mrg_r40 border_btm_none no-padding">
              <span class="label-name"
                >{{ $t('labels[12]') || '就诊日期' }}：</span
              >
              <a-date-picker
                @change="onChange"
                :default-value="moment()"
                :disabled-date="disabledDate"
                v-model="verifyData.admissionDate"
                format="YYYY-MM-DD HH:mm:ss"
                style="width: 240px;"
                :placeholder="$t('Modals[21]') || '请选择'"
              ></a-date-picker>
            </div>
            <div class="list_item border_btm_none no-padding">
              <span class="label-name">
                <span class="red">*</span>
                {{ $t('labels[55]') || '医生' }}：
              </span>
              <a-auto-complete
                v-model="verifyData.doctorId"
                style="width: 240px;"
                disabled
                optionLabelProp="label"
              >
                <template slot="dataSource">
                  <a-select-option
                    v-for="item in physicianList"
                    :value="item.PHYSICIAN_ID"
                    :key="item.PHYSICIAN_ID"
                    :label="item.PHYSICIAN_NAME"
                  >
                    {{ item.PHYSICIAN_NAME }}
                  </a-select-option>
                </template>
              </a-auto-complete>
            </div>
          </div>
          <div class="list_item">
            <span class="label-name">{{ $t('cols[14]') || '账单号' }}</span>
            <a-input style="width: 240px;" v-model="verifyData.hospBillNo" />
          </div>
          <div class="list_item">
            <span class="label-name">MRN：</span>
            <a-input style="width: 240px;" v-model="verifyData.mrn" />
          </div>
        </div>
        <div class="content_">
          <a-input
            style="width: 100px;"
            v-model="medicalSearch"
            @change="medicalSearchEnter"
            placeholder="请输入"
            allow-clear
          />
          <a-tabs
            class="myTabs_"
            :tabBarGutter="0"
            v-model="activeKey"
            tab-position="left"
            :style="{ height: '200px', 'overflow-y': 'scroll' }"
            @change="callback"
            type="card"
          >
            <a-tab-pane
              v-for="(item, index) in topDepNameList"
              :key="index"
              :tab="item['TOP_DEP_NAME']"
            >
              <a-button
                class="btn_"
                @click="handleActive(ele['DEP_CODE'])"
                v-for="ele in depNameList"
                :class="[isActiveCode == ele['DEP_CODE'] ? 'active_' : '']"
                :key="ele['DEP_CODE']"
              >
                {{ ele['DEP_NAME'] }}
              </a-button>
            </a-tab-pane>
          </a-tabs>
          <div
            class="head_"
            :style="{
              padding: '14px 0',
              background: 'none',
              fontSize: '17px',
              justifyContent: 'center'
            }"
          >
            <div class="h_right_">
              <a-button
                class="mar-left10"
                :style="{
                  color: '#fff',
                  background: '#00BD59',
                  width: '450px',
                  borderRadius: '5px',
                  height: '45px'
                }"
                :loading="checkIdLoading"
                type="primary"
                @click="checkIdCard"
              >
                {{ $t('Modals[22]') || '身份验证' }}
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 身份验证模态框 -->

    <!-- 选择医生信息模态框 -->
    <a-modal
      :title="$t('Modals[22]') || '请选择医生信息'"
      :footer="null"
      width="300px"
      wrapClassName="verify-physicain"
      v-model="isSelectPhysicianShow"
    >
      <a-auto-complete
        v-model="selectPhysician"
        style="width: 100%"
        :filter-option="filterOption"
        allow-clear
        optionLabelProp="label"
        @change="changeDoctor"
      >
        <template slot="dataSource">
          <a-select-option
            v-for="item in physicianList"
            :value="item.PHYSICIAN_ID"
            :key="item.PHYSICIAN_ID"
            :label="item.PHYSICIAN_NAME"
          >
            {{ item.PHYSICIAN_NAME }}
          </a-select-option>
        </template>
      </a-auto-complete>
      <div class="btns">
        <a-button @click="selectPhysicianSubmit">{{
          $t('common.confirm')
        }}</a-button>
        <a-button
          class="mar-left10"
          type="primary"
          @click="selectPhysicianCancel"
          >{{ $t('common.cancel') }}</a-button
        >
      </div>
    </a-modal>
    <!-- 选择医生信息模态框 -->

    <!-- 身份验证结果 -->
    <a-modal
      :title="$t('Modals[22]') || '身份验证'"
      :footer="null"
      wrapClassName="myModal_ myWelDetail_"
      v-model="visibleModal3"
    >
      <div class="list_" :style="{ border: '0' }">
        <div class="content_ content_1">
          <div class="list_item">
            <div class="mrg_r40">
              {{ $t('cols[0]') || '病人姓名' }}：{{ detail.policyVo.name }}
            </div>
            <div class="mrg_r40">
              {{ $t('cols[1]') || '出生日期' }}：{{ detail.policyVo.birthDate }}
            </div>
            <div class="mrg_r40">
              {{ $t('cols[2]') || '性别' }}：{{ detail.policyVo.gender }}
            </div>
          </div>
          <div class="list_item">
            <div class="mrg_r40" :style="{ marginRight: '56px' }">
              {{ $t('Modals[2]') || '证件号' }}
            </div>
            <div class="mrg_r40">{{ detail.policyVo.idNumber }}</div>
          </div>
          <div class="list_item">
            <div class="mrg_r40">{{ $t('cols[8]') || '公司名称' }}</div>
            <div class="mrg_r40">{{ detail.policyVo.corporation }}</div>
          </div>
          <div class="list_item" v-if="!isPreAuth">
            <div class="mrg_r90">
              {{ $t('Modals[3]') || '操作日期' }}：{{
                querySettleData.operatDate
              }}
            </div>
            <div class="mrg_r40">APP#：{{ querySettleData.terminalId }}</div>
          </div>
        </div>
        <div class="head_ patch_">
          <div>
            {{ verifyData.depName }}>{{
              !isPreAuth
                ? $t('Modals[9]') || '付费结算'
                : $t('Modals[48]') || '申请预授权'
            }}
          </div>
        </div>
        <div class="content_">
          <div class="dd_">
            <iframe
              class="scrollbar_"
              style="width: 100%;min-height:300px;"
              :srcdoc="settleResultHtml"
            ></iframe>
            <!-- <div style="text-align: center;" v-else>{{settleResultHtml}}</div> -->
          </div>
          <div
            class="head_"
            :style="{
              padding: '14px 0',
              background: 'none',
              fontSize: '17px',
              justifyContent: 'center',
              marginTop: '20px'
            }"
          >
            <div class="h_right_" v-if="settleIsPass">
              <div
                class="h_right_btn"
                @click="handleResult"
                :style="{
                  color: '#fff',
                  background: '#00BD59',
                  width: '450px',
                  borderRadius: '5px',
                  height: '45px'
                }"
              >
                {{
                  !isPreAuth
                    ? $t('Modals[9]') || '付费结算'
                    : $t('Modals[49]') || '继续申请'
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 身份验证结果 -->

    <!-- 门诊理赔单 -->
    <a-modal
      :footer="null"
      @cancel="printModalVisible1 = false"
      width="1000px"
      :visible="printModalVisible1"
      :title="$t('Modals[12]') || '打印'"
    >
      <div style="height: 800px;overflow-y: auto;">
        <iframe
          id="print-el-01"
          style="width: 854px;height: 2421px;display:block;"
          :src="ptintUrl1"
        ></iframe>
      </div>
      <a-popconfirm
        placement="top"
        :ok-text="$t('common.confirm')"
        :cancel-text="$t('common.cancel')"
        @confirm="handlePrint('01', ptintUrl1)"
      >
        <template slot="title">
          <p style="font-size: 12px; padding: 10px">
            {{ $t('message.upgrade') }}
          </p>
        </template>
        <a-button
          :style="{ margin: '10px auto', display: 'block' }"
          type="primary"
        >
          {{ $t('Modals[12]') || '打印' }}
        </a-button>
      </a-popconfirm>
    </a-modal>

    <!-- 住院理赔单 -->
    <a-modal
      :footer="null"
      @cancel="printModalVisible2 = false"
      width="1000px"
      :visible="printModalVisible2"
      :title="$t('Modals[12]') || '打印'"
    >
      <div style="height: 800px;overflow-y: auto;">
        <iframe
          id="print-el-02"
          style="width: 854px;height: 2421px;display:block;"
          :src="ptintUrl2"
        ></iframe>
      </div>
      <a-popconfirm
        placement="top"
        :ok-text="$t('common.confirm')"
        :cancel-text="$t('common.cancel')"
        @confirm="handlePrint('02', ptintUrl2)"
      >
        <template slot="title">
          <p style="font-size: 12px; padding: 10px">
            {{ $t('message.upgrade') }}
          </p>
        </template>
        <a-button
          :style="{ margin: '10px auto', display: 'block' }"
          type="primary"
        >
          {{ $t('Modals[12]') || '打印' }}
        </a-button>
      </a-popconfirm>
    </a-modal>
    <claim-about-modals />
    <!-- 预授权申请 -->
    <apply-pre-auth
      :preAuthShow="preAuthShow"
      :record="applyPreAuthRecord"
      @preAuthClose="preAuthClose"
    />
    <a-spin class="loading_" size="large" :spinning="spinning"></a-spin>
  </div>
</template>
<script>
import moment from 'moment'
import Bus from '@/utils/Bus'
import { MESSAGE_CHANNEL } from '@/utils/constants'
import commonMixin from '@/mixins/common'
import ClaimAboutModals from '../views/components/ClaimAboutModals'
import ApplyPreAuth from '../views/components/ApplyPreAuth'
import authorizedMixin from '@/mixins/authorized'
import { imgReg, getPreviewFile } from '@/utils'
import { api as viewerApi } from 'v-viewer'
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      empPlanId: '',
      showDialog: false,
      isSelectPhysicianShow: false,
      tabIdx: 0,
      printModalVisible1: false,
      printModalVisible2: false,
      isHospitalShow: true,
      visibleChange: false,
      visibleModal3: false, //身份验证结果
      verifyResult: '', //身份验证结果返回信息
      querySettleData: '', //门诊付费结算信息
      isPreAuth: false, // 是否预授权申请
      claimsId: '', //理赔编号 200
      settleIsPass: false,
      settleResultHtml: '',
      isNormalShow: true,
      ptintUrl1: '',
      ptintUrl2: '',
      topDepNameList: [],
      depNameList: [], //二级科室
      physicianList: [],
      verifyData: {
        depName: '', //选中的科室名
        coverageCode: '', //责任代码
        depDate: '', //选择的就诊时间,
        admissionDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        doctorId: '', // 选择的医生
        hospBillNo: '',
        mrn: '',
        departmentCode: ''
      },
      selectPhysician: '',
      isActiveCode: '',
      detail: {
        policyVo: {},
        coverageDtoList: {}
      },
      activeKey: 0,
      limitsData: {},
      checkIdLoading: false,
      isExpensive: false, // 是否昂贵医院标识
      medicalSearch: '', // 一级科室搜索
      tabKey: '3',
      fileList: [], // 附件列表
      applyPreAuthRecord: {}, // 申请预授权数据
      preAuthShow: false, // 预授权申请弹窗标识
      selfPayPhysiciansVOS: [] // 需自付医生费用的计划列表
    }
  },
  mixins: [commonMixin, authorizedMixin],
  components: {
    'claim-about-modals': ClaimAboutModals,
    'apply-pre-auth': ApplyPreAuth
  },
  computed: {
    // 过滤出图片数据
    justImgData() {
      return this.fileList.filter(item => {
        return item.isImg
      })
    }
  },
  mounted() {
    this.queryDetailById(this.data.empPlanId, this.data)
    console.log(this.data, 1037)
    this.empPlanId = this.data.empPlanId
    Bus.$on('empPlanId', async option => {
      this.isPreAuth = false
      this.empPlanId = option.data.empPlanId
      this.queryDetailById(option.data.empPlanId, option.data)
    })
  },
  methods: {
    moment,
    // 切换tab
    changeTab(val) {
      this.tabKey = val
      if (val === '2') {
        this.getAccessoryRemarkList()
      }
    },
    // 获取附件列表
    getAccessoryRemarkList() {
      this.fileList = []
      const params = {
        uploadType: 'ValService',
        key: this.detail.policyVo.corpCode
      }
      this.$apis.getAccessoryRemarkList(params).then(res => {
        ;(res.data.content || []).forEach((item, index) => {
          this.$set(this.fileList, index, item)
        })
        this.getImgsPreviewData()
      })
    },
    // 获取附件完整路径
    getImgsPreviewData() {
      this.fileList.forEach(async (item, index) => {
        const isImg = imgReg.test(item.filePath && item.filePath.toLowerCase())
        if (!item.fullPath) {
          // 图片类型且没有全路径地址 获取一下
          const { data } = await this.$apis.getSigUrl({
            filePath: item.filePath
          })
          const obj = {
            url: data || '',
            name: item.fileName,
            isImg
          }
          this.$set(this.fileList, index, obj)
        }
      })
    },
    // 身份验证切换医生
    changeDoctor(val) {
      if (!val) {
        return
      }
      let obj = this.selfPayPhysiciansVOS.find(item => {
        return item.coverageCode == this.verifyData.coverageCode
      })
      if (obj) {
        let hasSlefPaiedDoctors = obj.selfPayPhysicianIds
          ? obj.selfPayPhysicianIds.split(',')
          : []
        console.log(hasSlefPaiedDoctors, val)
        if (hasSlefPaiedDoctors.length && hasSlefPaiedDoctors.includes(val)) {
          this.$message.warning(
            `计划一，在该医生下就诊客户需自付${obj.selfPayAmount}挂号费`
          )
        }
      }
    },
    // 查看图片放大图
    showImage(data) {
      let index = 0
      // 因为预览图片只是针对图片 index也应该是参照全部图片的index来界定
      for (let i = 0; i < this.justImgData.length; i++) {
        if (this.justImgData[i].url === data.url) {
          index = i
          break
        }
      }
      this.viewer = viewerApi({
        options: {
          fullscreen: false,
          navbar: true,
          title: false,
          toolbar: true,
          url: 'url',
          initialViewIndex: index,
          className: 'view-reset'
        },
        images: this.justImgData
      })
    },
    // 文件预览
    previewFile(row) {
      const { url } = row
      getPreviewFile(row, !!url, url => {
        row.url = url
      })
    },
    // 身份验证-就诊日期只能选择7天前
    disabledDate(currentDate) {
      if (this.isPreAuth) {
        return ''
      }
      return (
        (currentDate && currentDate < moment().subtract('7', 'days')) ||
        currentDate > moment()
      )
    },
    filterNull(val) {
      if (val === null) {
        return 0
      }
      return val
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toUpperCase()
          .indexOf(input.toUpperCase()) >= 0
      )
    },
    dealLimitShowText(prop) {
      const code = {
        每日: this.$t('labels[49]'),
        每年: this.$t('labels[50]'),
        每月: this.$t('labels[51]')
      }
      return code[prop] || prop
    },
    dealWithStr(str) {
      let str1 = '',
        str2 = '',
        str3 = ''
      let extraText = ['0%', '0', '无'].includes(str)
        ? ''
        : '具体费用赔付比例，以下方费用项目列表为准'
      if (str && str.indexOf('：') > -1) {
        str1 = str.split('：')[0]
        str2 = str.split('：')[1]
        if (str2.indexOf('当前次数') > -1) {
          let arr = str2.split(',')
          str3 = arr[arr.length - 1]
          arr.pop()
          str2 = arr.join(',')
        }
        return `<div style="display: flex; algin-items: center">
          <p style="margin-bottom: 0;">${str1}：</p>
          <p style="display:flex;flex-direction: column;margin-bottom:0;">
            <span style="color: red;">${str2}。</span>
            <span style="color: red;">${str3}。${extraText}</span>
          </p>
        </div>`
      } else if (str) {
        return `<div style="display: flex; algin-items: center">
          <p style="margin-bottom: 0;">${str}。<span style="color: red;">${extraText}</span></p>
        </div>`
      }
    },
    medicalSearchEnter() {
      // 设置activeKey index
      let index = this.topDepNameList.findIndex((m, n) => {
        let item = m.TOP_DEP_NAME
        let val = this.medicalSearch.trim()
        if (!val) {
          return -1
        }
        if (item.indexOf(val) > -1) {
          return n
        }
      })
      if (index > -1) {
        this.activeKey = index
        this.callback(index)
      }
    },
    handleResult() {
      let record = {
        // claimsId: this.claimsId
        claimsId: this.verifyResult['Claims_id']
      }
      // 如果是预授权申请
      if (this.isPreAuth) {
        this.handlePreAuth(record)
      } else {
        this.handleToSettle(true, record)
      }
      this.visibleModal3 = false
    },
    querySettleAbout() {
      let data = {
        calimsId: this.verifyResult['Claims_id'],
        meFlag: '1'
      }
      this.$apis.querySettleAbout(data).then(res => {
        this.querySettleData = res.data.data
      })
    },
    handleActive(code) {
      this.isActiveCode = code
      this.verifyData.doctorId = ''
      this.verifyData.departmentCode = ''
      this.selectPhysician = ''
      this.isSelectPhysicianShow = true
      this.depNameList.map(item => {
        if (item['DEP_CODE'] == code) {
          this.verifyData.depName = item['DEP_NAME']
          this.verifyData.coverageCode = item['COVERAGE_CODE']
        }
      })
    },
    async selectPhysicianSubmit() {
      if (!this.selectPhysician) {
        this.$message.error(this.$t('Modals[22]') || '请选择医生信息')
        return
      }
      // 医生信息不允许输入“无”或“其他”
      if (this.selectPhysician === '无' || this.selectPhysician === '其他') {
        this.$message.error('医生不能为“无”或“其他”，请填写正确的医生名称')
        return
      }

      // 根据接口返回是否需要立案
      let isOk = await this._checkProvider()
      if (!isOk) return
      this.verifyData.doctorId = this.selectPhysician.trim()
      this.isSelectPhysicianShow = false
    },
    // 判断是否符合立案条件
    _checkProvider() {
      let data = {
        cardNo: this.detail.policyVo.cardNo,
        ...this.verifyData,
        depDate: undefined
      }
      if (this.verifyData.depName == '' || this.verifyData.coverageCode == '') {
        this.$message.error(this.$t('labels[44]') || '请选择科室')
        return
      }
      return new Promise((resolve, reject) => {
        this.$apis
          .checkProvider(data)
          .then(res => {
            const { data } = res || {}
            if (data.code == '200') {
              resolve(true)
            } else {
              this.$message.error(data.msg)
              reject(false)
            }
          })
          .catch(() => {
            reject(false)
          })
      })
    },
    selectPhysicianCancel() {
      this.isSelectPhysicianShow = false
    },
    onChange(date, dateString) {
      this.verifyData.admissionDate = dateString
    },
    callback(key) {
      let _name = this.topDepNameList[key]
      let data = {
        level: 2,
        name: (_name && _name['TOP_DEP_NAME']) || ''
      }
      this.$apis.getCaseInfoList(data).then(res => {
        const data = res.data.data || {}
        this.depNameList = data.caseInfoList || []
      })
    },
    getCaseInfoListLevel1() {
      let data = {
        level: 1,
        name: ''
      }
      this.$apis.getCaseInfoList(data).then(res => {
        const data = res.data.data || {}
        this.visibleChange = true
        this.topDepNameList = data.caseInfoList || []
        this.physicianList = data.physicianList || []
        this.callback(0) //一级科室为空，测试数据使用
      })
    },
    // 身份校验
    checkIdCard() {
      if (!this.verifyData.doctorId) {
        // 校验医生必填
        this.$message.error(this.$t('Modals[22]') || '请选择医生信息')
        return
      }
      let data = {
        cardNo: this.detail.policyVo.cardNo,
        ...this.verifyData,
        depDate: undefined
      }

      if (this.verifyData.depName == '' || this.verifyData.coverageCode == '') {
        this.$message.error(this.$t('labels[44]') || '请选择科室')
      } else {
        this.checkIdLoading = true
        const apiName = this.isPreAuth ? 'preAuth' : 'checkIdCard'
        this.$apis[apiName](data)
          .then(res => {
            let resData = res.data.data || {}
            this.verifyResult = resData
            if (resData.Pass === 'Y') {
              this.$message.success('验证成功')
              this.settleIsPass = true
              // 如果不是预授权申请
              if (!this.isPreAuth) {
                this.querySettleAbout()
              }
            } else {
              this.$message.error('验证失败')
              this.settleIsPass = false
            }
            this.queryDetailById(this.empPlanId)
            this.settleResultHtml = resData.HtmlText || resData.MessageText
            this.visibleChange = false
            this.visibleModal3 = true
          })
          .finally(() => {
            this.checkIdLoading = false
          })
      }
    },
    // 身份验证
    verify(type) {
      // 判断是否预授权申请
      this.isPreAuth = type === 'preAuth'
      this.showDialog = false
      this.medicalSearch = '' // 清空上次的输入值
      this.activeKey = 0
      //判断是否有一个不是享受直付的
      let hasOne = (this.detail.coverageDtoList || []).every(item => {
        return item.cashlessFacility == '享受直付'
      })
      if (!hasOne) {
        this.$notification.warning({
          message: this.$t('sublist.tipmsg'),
          description: this.$t('message.existErr'),
          duration: 10
        })
      }
      this.verifyData.doctorId = ''
      this.verifyData.departmentCode = ''
      this.verifyData.mrn = this.detail.policyVo.mrn || ''
      this.verifyData.hospBillNo = ''
      this.selectPhysician = ''
      this.getCaseInfoListLevel1()
    },
    queryDetailById(data, items) {
      this.showSpin()
      this.$apis
        .queryCoverageDetailById(data)
        .then(res => {
          let content = res.data.data
          let arr = []
          this.detail.policyVo = content.policyVo
          this.selfPayPhysiciansVOS =
            content.policyVo.selfPayPhysiciansVOS || []
          if (items) {
            this._getCommonLimits(items)
          }
          // 如果不是预授权申请
          if (!this.isPreAuth) {
            content.coverageDtoList.map((item, index) => {
              let str = false
              if (index == 0) {
                str = true
                this.queryBenefitByCoverage(item, index)
                // 获取昂贵医院标识
                this.getProviderTypeByMember(item)
              }
              arr.push({
                isShow: str,
                ...item
              })
            })
          }
          this.detail = {
            policyVo: content.policyVo,
            coverageDtoList: arr
          }
          this.claimsId = content.policyVo.claimsId
        })
        .finally(() => {
          this.hideSpin()
        })
    },
    queryBenefitByCoverage(data, index) {
      if (data.benefits && data.benefits.length) {
        return true
      }
      this.$apis
        .querybenefitDetailByCoverage({
          ...data,
          startDate: this.detail.policyVo.effectTime,
          endDate: this.detail.policyVo.endTime
        })
        .then(res => {
          const val = res.data
          this.$set(
            this.detail.coverageDtoList[index],
            'benefits',
            val.data || []
          )
        })
    },
    // 获取昂贵医院标识
    getProviderTypeByMember(data) {
      if (!(data.subRecordNo || data.recordNo || data.providerCode)) {
        return
      }
      const params = {
        subRecordNo: data.subRecordNo,
        recordNo: data.recordNo,
        providerCode: data.providerCode
      }
      this.$apis.getProviderTypeByMember(params).then(res => {
        const content = res.data.content
        this.isExpensive = content && content === '11'
      })
    },
    showData(data, index) {
      data.isShow = !data.isShow
      if (data.isShow && (index || index === 0)) {
        this.queryBenefitByCoverage(data, index)
      }
    },
    printOutpatient() {
      this.showSpin()
      let data = {
        cardNo: this.detail.policyVo.cardNo,
        corpCode: this.detail.policyVo.corpCode,
        printType: 'OPCLAIMFORM'
      }
      this.$apis
        .print(data)
        .then(res => {
          this.ptintUrl1 = res.data.data.printUrl
          this.printModalVisible1 = true
        })
        .finally(() => {
          this.hideSpin()
        })
    },
    printInHospital() {
      this.showSpin()
      let data = {
        cardNo: this.detail.policyVo.cardNo,
        corpCode: this.detail.policyVo.corpCode,
        printType: 'HOSPCLAIMFORM'
      }
      this.$apis
        .print(data)
        .then(res => {
          let protocol = window.location.protocol.replace(':', '')
          if (res.data.data.printUrl.indexOf('https') > -1) {
            this.ptintUrl2 = res.data.data.printUrl.replace('https', protocol)
          } else {
            this.ptintUrl2 = res.data.data.printUrl.replace('http', protocol)
          }
          this.printModalVisible2 = true
        })
        .finally(() => {
          this.hideSpin()
        })
    },
    handleToSettle(isSettle, record) {
      // 去结算、变更
      Bus.$emit(MESSAGE_CHANNEL, {
        type: 'handleToSettle',
        data: record,
        isSettle
      })
    },
    // 预授权申请
    handlePreAuth(record) {
      this.preAuthShow = true
      this.applyPreAuthRecord = record
    },
    // 关闭预授权申请弹窗
    preAuthClose() {
      this.preAuthShow = false
    },
    handlePrint(elIndex, ptintUrl) {
      window.open(ptintUrl)
    },
    _getCommonLimits(data) {
      const { corpCode, planCode, memberControlNo } = data
      this.$apis
        .getCommonLimits({
          corpCode,
          planCode,
          memberControlNo,
          startDate: this.detail.policyVo.effectTime,
          endDate: this.detail.policyVo.endTime
        })
        .then(res => {
          const data = res.data || {}
          this.limitsData = data.data || {}
          this.$set(this.limitsData, 'isShow', true)
        })
    }
  },
  destroyed() {
    Bus.$off('empPlanId')
  }
}
</script>

<style scoped lang="scss">
.ml5 {
  margin-left: 5px;
}
.font_small {
  font-size: 12px;
}
.mainColor {
  color: #00ac51;
}

.pointer {
  cursor: pointer;
  text-decoration: underline;
}

.content-dialog {
  padding-bottom: 20px;

  img {
    width: 100% !important;
  }
}

.flex {
  display: flex;
}

.myWelDetail_ {
  .list_ {
    font-family: Source Han Sans SC;
    // height: 310px;
    width: 100%;
    border: 2px solid rgba(233, 237, 244, 1);
    border-radius: 8px;
    font-size: 14px;
    margin-bottom: 22px;

    .inner {
      position: relative;

      .dialog {
        position: absolute;
        top: -180px;
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 2px;
        border: 1px solid #f6f7f9;

        .title {
          font-weight: bold;
        }

        .text-left {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .head_ {
      padding: 14px;
      height: 20%;
      display: flex;
      background: #f6f7f9;
      justify-content: space-between;
      align-items: center;

      .tab-div {
        display: flex;
        align-items: center;

        .tab {
          margin-right: 20px;
          height: 40px;
          line-height: 40px;
        }
      }

      &.patch_ {
        // margin: -1px -24px 0;
        padding: 0;
        margin: 14px 0;
        padding-left: 9px;
        border-left: 3px solid #05be5c;
        background: #fff;
        font-size: 13px;
        color: #333333;
      }

      .h_right_ {
        display: flex;

        .h_right_btn {
          cursor: pointer;
          min-width: 100px;
          padding: 0 3px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(255, 255, 255, 1);
          font-weight: 500;
          color: #00c55d;
          border: 2px solid rgba(233, 237, 244, 1);
          border-radius: 3px;
          margin-right: 14px;
        }
      }
    }

    .content_1 {
      border-bottom: 1px solid rgba(233, 237, 244, 1);
      border-top: 1px solid rgba(233, 237, 244, 1);
    }

    .content_ {
      // height:80%;
      .tab-cnt-word {
        padding: 0 0 20px 20px;
      }
      .preview-img {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        margin-bottom: 10px;
        .preview-container {
          width: 300px;
          margin: 0 10px;
          display: flex;
          align-items: center;
          .preview-img-item {
            position: relative;
            text-align: center;
            width: 300px;
            max-height: 400px;
            overflow: hidden;
            img {
              width: 100%;
              max-height: 380px;
            }
            .file-icon {
              color: #1890ff;
              font-size: 100px;
              padding: 50px 0;
            }
          }
        }
      }
      .list_item {
        min-height: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid rgba(233, 237, 244, 1);
        padding-left: 14px;
        flex-wrap: wrap;

        .red {
          color: red;
        }

        .mrg_r90 {
          margin-right: 90px;
        }

        .mrg_r40 {
          margin-right: 40px;
        }

        .flex_1 {
          flex: 1;

          .free {
            color: red;
          }
        }

        .flex_3 {
          flex: 3;
        }
      }

      .list_item.short {
        width: 50%;
        flex-wrap: nowrap;
      }

      .label-name {
        min-width: 140px;
      }

      .list_item_2 {
        height: 180px;
      }

      .listItem_left {
        height: 100%;
        border-right: 1px solid rgb(233, 237, 244);
        display: flex;
        align-items: center;
      }

      .paddLeft_32 {
        padding-left: 32%;
        display: inline-block;
      }

      .btn_ {
        cursor: pointer;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(234, 235, 237, 1);
        border-radius: 6px;
        padding: 5px 10px;
        margin: 10px 10px 0 0;
        display: inline-block;
        overflow: hidden;
      }
    }
  }
}

.border_btm_none {
  border-bottom: none !important;
}

.no-padding {
  padding-left: 0 !important;
}

.themeColor_ {
  color: #00c55d !important;
}

.active_ {
  background: #00bd59 !important;
  color: #fff !important;
}

.border_left {
  border-left: 1px solid #e9edf4;
  padding-left: 0;
}

.myTabs_::-webkit-scrollbar,
.scrollbar_::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.myTabs_::-webkit-scrollbar-track,
.scrollbar_::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}

.myTabs_::-webkit-scrollbar-thumb,
.scrollbar_::-webkit-scrollbar-thumb {
  background: #00bd59;
  border-radius: 10px;
}

.tip_ {
  color: #00bd59;
  margin-left: 5px;
}

.red_ {
  color: red;
}

.dd_ {
  white-space: pre-wrap;
}

.verify-physicain {
  .btns {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
