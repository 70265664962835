import { render, staticRenderFns } from "./SubMenu.vue?vue&type=template&id=cdd5f844&scoped=true&functional=true"
import script from "./SubMenu.vue?vue&type=script&lang=js"
export * from "./SubMenu.vue?vue&type=script&lang=js"
import style0 from "./SubMenu.vue?vue&type=style&index=0&id=cdd5f844&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "cdd5f844",
  null
  
)

export default component.exports