import { addCommas } from '@/utils/business'

export default {
  data() {
    return {
      searchContent: '8000-1428',
      spinning: false
    }
  },
  filters: {
    addCommas
  },
  methods: {
    showSpin() {
      this.spinning = true
    },
    hideSpin() {
      this.spinning = false
    },
    handleContentChange(e) {
      if (this.aValue == 1) {
        if (this._st) {
          clearTimeout(this._st)
          this._st = null
        }
        let value = e.target.value
        value = value.split('-').join('')
        const transfromValue = value.replace(/(\d{4})(?=\d+)/g, function(val) {
          return val + '-'
        })
        this._st = setTimeout(() => {
          clearTimeout(this._st)
          this.searchForm.setFieldsValue({
            content: transfromValue
          })
        }, 300)
      }
    },
    handleContentChangeWithCommon(e) {
      if (this.aValue == 1) {
        if (this._st) {
          clearTimeout(this._st)
          this._st = null
        }
        let value = e.target.value
        const transfromValue = value
        this._st = setTimeout(() => {
          clearTimeout(this._st)
          this.searchForm.setFieldsValue({
            content: transfromValue
          })
        }, 300)
      }
    },
    /** 公共的码表反解析，根据key拿到name
     *
     * **/
    formatCommon(val, list = [], obj = { key: 'name', val: 'value' }) {
      if (val !== 0 && !val) {
        return
      }
      const item = list.find(item => {
        return item[obj.val] == val
      })
      return item ? item[obj.key] : ''
    }
  }
}
