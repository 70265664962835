<template>
  <div>
    <a-modal
      :title="$t('Modals[51]') || '预授权申请'"
      :footer="null"
      :closable="false"
      :maskClosable="false"
      wrapClassName="apply-preAuth_modal"
      v-model="preAuthShow"
      width="900px"
    >
      <div class="list_">
        <div class="content_">
          <div class="list_item">
            <div class="mrg_r40">
              {{ $t('cols[0]') || '病人姓名' }}：{{ preAuthInfo.patientName }}
            </div>
            <div class="mrg_r40">
              {{ $t('cols[1]') || '生日' }}： {{ preAuthInfo.birthDate }}
            </div>
            <div class="mrg_r40">
              {{ $t('cols[2]') || '性别' }}：{{
                preAuthInfo.gender == 'F'
                  ? $t('Modals[19]') || '女'
                  : $t('Modals[20]') || '男'
              }}
            </div>
          </div>
          <div class="list_item">
            <div class="mrg_r40">
              {{ $t('cols[3]') || '证件号' }}： {{ preAuthInfo.idNumber }}
            </div>
            <div class="mrg_r40">
              {{ $t('Modals[3]') || '操作日期' }}：{{ preAuthInfo.operatDate }}
            </div>
          </div>
          <div class="list_item">
            <div class="mrg_r40">
              {{ $t('cols[7]') || '公司名称' }}：{{ preAuthInfo.coporation }}
            </div>
            <div class="mrg_r40">APP#：{{ preAuthInfo.approval }}</div>
          </div>
        </div>
        <div class="head_ patch_">
          <div>{{ preAuthInfo.coverageName }}</div>
        </div>
        <div class="content_">
          <a-form :form="applyForm" @submit="handleSubmit">
            <a-form-item
              class="list_item1"
              :label="($t('Modals[7]') || '疾病代码') + ' (ICD)'"
            >
              <a-select
                class="select_"
                showSearch
                v-decorator="[
                  'icd_code',
                  {
                    initialValue: valueOfICD,
                    rules: [{ required: true, message: '请选择疾病代码!' }]
                  }
                ]"
                :placeholder="$t('labels[38]') || '可直接输入ICD代码或关键字'"
                :defaultActiveFirstOption="false"
                :showArrow="false"
                :filterOption="false"
                @search="handleSearch"
                @change="handleICDChange"
                :notFoundContent="null"
              >
                <a-select-option
                  v-for="d in dataOfSearchICD"
                  :key="d.value"
                  :disabled="d.disabled"
                  >{{ d.text }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item
              class="list_item1"
              :label="$t('Modals[52]') || '预计入院日期'"
            >
              <a-date-picker
                class="no_border_input_ mini_m_"
                v-decorator="[
                  'admission_date',
                  {
                    rules: [{ required: true, message: '请选择预计入院日期' }]
                  }
                ]"
                :placeholder="$t('Modals[52]') || '请选择预计入院日期'"
                valueFormat="YYYY-MM-DD"
                :format="dateFormat"
              ></a-date-picker>
            </a-form-item>
            <a-form-item
              class="list_item1"
              :label="$t('Modals[53]') || '预计出院日期'"
            >
              <a-date-picker
                class="no_border_input_ mini_m_"
                v-decorator="[
                  'discharge_date',
                  {
                    rules: [{ required: true, message: '请选择预计出院日期!' }]
                  }
                ]"
                :placeholder="$t('Modals[53]') || '请选择预计出院日期'"
                valueFormat="YYYY-MM-DD"
                :format="dateFormat"
              ></a-date-picker>
            </a-form-item>
            <a-form-item
              class="list_item1"
              :label="$t('Modals[54]') || '请输入预估金额'"
            >
              <a-input-number
                class="no_border_input_"
                v-decorator="[
                  'due_total',
                  { rules: [{ required: true, message: '请输入预估金额!' }] }
                ]"
                :placeholder="$t('Modals[54]') || '请输入'"
                valueFormat="YYYY-MM-DD"
                :min="0"
              ></a-input-number>
            </a-form-item>
            <a-form-item
              class="list_item1"
              :label="$t('Modals[56]') || '预授权回复邮箱'"
            >
              <a-input
                class="no_border_input_"
                v-decorator="[
                  'preEmail',
                  {
                    initialValue: providerInfo.preEmail,
                    rules: [
                      {
                        validator: emailValidator,
                        message: '邮箱格式不正确，请重新填写！',
                        trigger: 'blur'
                      }
                    ]
                  }
                ]"
                :placeholder="$t('Modals[56]') || '请输入'"
              ></a-input>
            </a-form-item>
            <a-form-item :label="$t('Modals[55]') || '病历资料'">
              <a-upload
                class="upload-list-inline"
                :multiple="true"
                :fileList="fileList"
                :remove="handleRemove"
                :beforeUpload="beforeUpload"
              >
                <a-button
                  class="theme_color mrg_r20"
                  :style="{ width: 'auto' }"
                >
                  <a-icon type="folder-open" />{{
                    $t('tips[43]') || '点击选择上传文件'
                  }}</a-button
                >
                <span class="red tips">
                  <!-- <a class="example mrg_r20">{{
                    $t('tips[61]') || '上传示例'
                  }}</a> -->
                  <span>{{
                    $t('labels[65]') || '注意：请上传PDF或者图片格式!'
                  }}</span>
                </span>
              </a-upload>
            </a-form-item>
            <div class="btn-box">
              <a-button class="mrg_r20" @click="close">{{
                $t('labels[39]') || '取消'
              }}</a-button>
              <a-button class="mrg_r20" html-type="submit" type="primary">{{
                $t('labels[56]') || '确定'
              }}</a-button>
            </div>
          </a-form>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import commonMixin from '@/mixins/common'
import { emailReg } from '@/utils'
import { mapState } from 'vuex'
let timeout, currentValue
export default {
  mixins: [commonMixin],
  props: {
    // 弹窗标识
    preAuthShow: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      valueOfICD: '', //疾病代码
      dataOfSearchICD: [], // 疾病代码数据
      preAuthInfo: {},
      applyForm: this.$form.createForm(this),
      dateFormat: ['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYYMMDD'],
      fileList: []
    }
  },
  watch: {
    preAuthShow(val) {
      if (val) {
        this.initData()
      }
    }
  },
  computed: {
    ...mapState({ providerInfo: state => state.providerInfo })
  },
  mounted() {
    console.log(this.providerInfo, 223)
  },
  methods: {
    // 邮箱校验
    emailValidator(rule, value, callback) {
      if (!emailReg.test(value)) {
        callback('邮箱格式不正确，请重新填写！')
      } else {
        callback()
      }
    },
    close() {
      this.$emit('preAuthClose')
      this.$emit('fetchData')
    },
    initData() {
      this.showSpin()
      // 清空疾病代码
      this.valueOfICD = undefined
      this.dataOfSearchICD = []
      this.fetchSettleAbout(this.record.claimsId)
        .then(data => {
          const { icdCode, description } = data.data
          if (icdCode) {
            this.valueOfICD = icdCode
            this.dataOfSearchICD = [
              {
                value: icdCode,
                text: description
              }
            ]
          }
          this.preAuthInfo = data.data
        })
        .finally(() => {
          this.hideSpin()
        })
    },
    // 查询相关信息
    fetchSettleAbout(claimsId) {
      return this.$apis
        .querySettleAbout({
          calimsId: claimsId,
          meFlag: '1'
        })
        .then(res => res.data)
    },
    // 接口查询疾病数据
    fetchICDByKeyword(value, callback) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      currentValue = value

      timeout = setTimeout(() => {
        this.$apis
          .searchICD({
            keyWord: value
          })
          .then(res => res.data)
          .then(data => {
            if (currentValue === value) {
              const result = data.data
              let arr = []
              result.forEach(r => {
                arr.push({
                  value: r.icdCode,
                  text: r.description
                })
              })
              if (arr.length < 1) {
                arr = [
                  {
                    value: '',
                    text: this.$t('tips[35]') || '未查询到此疾病',
                    disabled: true
                  }
                ]
              }
              callback(arr)
            }
          })
      }, 300)
    },
    // 查询疾病数据
    handleSearch(value) {
      if (value.length >= 2) {
        this.fetchICDByKeyword(value, data => (this.dataOfSearchICD = data))
      }
    },
    // 选择疾病数据
    handleICDChange(value) {
      this.valueOfICD = value
      this.fetchICDByKeyword(value, data => (this.dataOfSearchICD = data))
    },
    // 删除附件
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    // 附件上传前
    beforeUpload(file) {
      this.fileList = [...this.fileList, file]
      return false
    },
    // 处理参数
    handleParams(values) {
      const formData = new FormData()
      formData.append('claims_id', this.record.claimsId)
      const fields = [
        'admission_date',
        'discharge_date',
        'icd_code',
        'due_total',
        'preEmail'
      ]
      fields.forEach(key => {
        formData.append(key, values[key])
      })
      this.fileList.forEach(file => {
        formData.append('files', file, file.name)
      })
      return formData
    },
    handleSubmit(e) {
      e && e.preventDefault()
      this.applyForm.validateFields((error, values) => {
        if (!error) {
          values.admission_date = `${values.admission_date} 00:00:00`
          values.discharge_date = `${values.discharge_date} 00:00:00`
          const params = this.handleParams(values)
          this.submitData(params)
        }
      })
    },
    // 预授权申请接口调用保存
    submitData(params) {
      this.$apis.savaAuthDischargeInfo(params).then(res => {
        const data = res.data
        const that = this
        if (data.code === 200) {
          this.$success({
            title: '创建成功',
            content:
              '如案件审核中需要补充任何信息，请后续关注Auto-email 提醒并至“事先授权”菜单查看案件审核详情！',
            okText: '确定',
            onOk() {
              that.close()
            }
          })
        } else {
          this.$error({
            title: '创建失败',
            okText: '确定',
            content:
              '当前客户无法创建预授权申请，烦请拨打医疗专线咨询详情！医疗专线：400-169-2988'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.apply-preAuth_modal {
  width: 1000px;
  .ant-form-item {
    display: flex;
    .ant-form-item-control-wrapper {
      flex: 1;
    }
    .select_ {
      min-width: 400px;
    }
  }
  .mrg_r20 {
    margin-right: 20px;
  }
  .example {
    color: red;
    text-decoration: underline;
    cursor: pointer;
  }
  .example::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background: red;
    border-radius: 50%;
    margin-right: 2px;
  }
  .red {
    color: red;
  }
  .content_ {
    // height:80%;
    .list_item {
      height: 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid rgba(233, 237, 244, 1);
      .mrg_r40 {
        margin-right: 40px;
      }
    }
    .list_item1 {
      border-bottom: 1px solid rgba(233, 237, 244, 1);
      margin-bottom: 0;
    }
    .list_btn {
      display: flex;
      align-items: center;
      .btn_ {
        cursor: pointer;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(234, 235, 237, 1);
        border-radius: 6px;
        padding: 0px 10px;
        margin-right: 10px;
        white-space: nowrap;
      }
    }
  }
  .list_ {
    color: rgba(38, 38, 38, 1);
    font-family: Source Han Sans SC;
    width: 100%;
    font-size: 14px;
    margin-bottom: 22px;
    .head_ {
      padding: 14px 0;
      height: 20%;
      display: flex;
      background: #f6f7f9;
      justify-content: space-between;
      align-items: center;
      &.patch_ {
        padding: 0;
        margin: 14px 0;
        padding-left: 9px;
        border-left: 3px solid #05be5c;
        background: #fff;
        font-size: 13px;
        color: #333333;
      }
    }
  }
  .no_border_input_ {
    flex: 1;
    border: none;
    box-shadow: none;
    &:hover,
    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
    ::v-deep {
      .ant-input {
        border: none;
        box-shadow: none;
      }
    }
  }
  .btn-box {
    text-align: right;
  }
}
</style>
