
import Vue from "vue";
import VueI18n from "vue-i18n"
import LangENUS from "./source/en-us"
import LangZHCN from "./source/zh-cn"
import api from "@/api";
import moment from "moment";
import "moment/locale/zh-cn";
import {  getLang, setLang } from "@/utils/authorized";

moment.locale("zh-cn");

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: getLang() || "en-us",
    messages: {
        "en-us": LangENUS,
        "zh-cn": LangZHCN
    }
})

// 查询当前语言
export const getLanguageL = () => {

  if(window.location.href.indexOf("pcadmin") > -1) {
    api
      .getLanguageL()
      .then(res => res.data)
      .then(data => {
          let langNum = data.data.language;
          const lang = langNum == "01" ? "zh-cn" : "en-us";
          setLang(lang);
          moment.locale(lang);
      });
  }
}

export default i18n;
