<template>
  <div class="page_">
    <a-row :gutter="0">
      <a-col :span="24">
        <a-radio-group
          @change="handleStatusChange"
          v-model="conFlag"
          class="c_form_item_ no_top_mrg_ pad"
          size="large"
        >
          <a-radio-button value="1">
            {{ $t('labels[6]') || '全部记录' }}
          </a-radio-button>
          <a-radio-button
            value="2"
            title="立案后案件请及时快递材料，超90天的案件，系统会自动做取消处理，感谢理解和配合！"
          >
            {{ $t('labels[7]') || '未收件' }}
            <span v-if="unReceMessageSize > 0" class="tab_num_">
              {{ unReceMessageSize }}
            </span>
          </a-radio-button>
          <a-radio-button value="3">
            {{ $t('labels[8]') || '补件' }}
            <span v-if="refsize > 0" class="tab_num_ margin-right5">{{
              refsize
            }}</span>
            <a
              href="https://ssish.oss-cn-hangzhou.aliyuncs.com/medilink/%E8%A1%A5%E4%BB%B6%E8%B5%84%E6%96%99Portal%E4%B8%8A%E4%BC%A0%E6%8C%87%E5%8D%97.pdf"
              target="_blank"
            >
              <img class="why-icon" src="../assets/why_icon.png" alt="icon" />
            </a>
          </a-radio-button>
          <a-radio-button value="4"
            >{{ $t('labels[9]') || '无发票'
            }}<span v-if="noBillSize > 0" class="tab_num_">{{
              noBillSize
            }}</span></a-radio-button
          >
          <a-radio-button value="8"
            >{{ $t('statusText[2]') || '审核中'
            }}<span
              v-if="underReviewSize && underReviewSize > 0"
              class="tab_num_"
              >{{ underReviewSize }}</span
            ></a-radio-button
          >
          <a-radio-button value="5"
            >{{ $t('statusText[3]') || '已结案'
            }}<span
              v-if="closedCaseSize && closedCaseSize > 0"
              class="tab_num_"
              >{{ closedCaseSize }}</span
            ></a-radio-button
          >
          <a-radio-button value="6"
            >{{ $t('statusText[13]') || '赔付' }}
            <span v-if="claimSize && claimSize > 0" class="tab_num_">{{
              claimSize
            }}</span>
          </a-radio-button>
          <a-radio-button value="7"
            >{{ $t('statusText[14]') || '拒付' }}
            <span
              v-if="claimsRejectedSize && claimsRejectedSize > 0"
              class="tab_num_"
              >{{ claimsRejectedSize }}</span
            >
          </a-radio-button>
        </a-radio-group>
      </a-col>
    </a-row>
    <a-form :form="searchForm" layout="horizontal" @submit="handleSearchForm">
      <a-form-item
        :label="$t('labels[0]') || '查询项'"
        class="c_form_item_ pad15_ h58_"
      >
        <a-row :gutter="12">
          <a-col :span="16">
            <a-radio-group
              style="margin-left: 12px;"
              @change="onAChange"
              v-model="aValue"
            >
              <a-radio :value="1">{{ $t('cols[4]') || '保险卡号' }}</a-radio>
              <a-radio :value="2">{{ $t('cols[3]') || '证件号' }}</a-radio>
              <a-radio :value="3">{{ $t('cols[0]') || '姓名' }}</a-radio>
              <a-radio :value="4">{{ $t('labels[4]') || '保单号' }}</a-radio>
              <a-radio :value="5">{{ $t('labels[5]') || '病历号' }}</a-radio>
              <a-radio :value="6">{{
                $t('labels[10]') || '中间带交易号'
              }}</a-radio>
              <a-radio :value="7">{{
                $t('preAuth.serNum1') || '理赔编号'
              }}</a-radio>
              <a-radio :value="8">{{ $t('cols[14]') || '账单号' }}</a-radio>
            </a-radio-group>
          </a-col>
          <a-col class="p_static_" :span="8">
            <div class="right_">
              <a-checkbox @change="onSelectAll">{{
                $t('btns[3]') || '勾选全部记录'
              }}</a-checkbox>
              <a-button @click="batchPrint" type="primary">{{
                $t('btns[5]') || '批量打印理赔解释'
              }}</a-button>
              <a-button
                @click="exportData"
                style="margin-left: 24px;"
                type="primary"
                >{{ $t('btns[4]') || '导出数据' }}</a-button
              >
            </div>
          </a-col>
        </a-row>
      </a-form-item>
      <a-row class="c_form_item_ h58_" :gutter="12">
        <a-col v-if="aValue == 2" :span="6" class="marLeft_20">
          <a-form-item :label="$t('claimDetail[20]') || '证件类型'">
            <a-select
              v-decorator="['idType']"
              :placeholder="$t('Modals[21]') || '请选择'"
            >
              <a-select-option key="1" value="00">{{
                $t('labels[34]') || '身份证'
              }}</a-select-option>
              <a-select-option key="2" value="01">{{
                $t('labels[35]') || '护照'
              }}</a-select-option>
              <a-select-option key="3" value="02">{{
                $t('labels[36]') || '其他'
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item :label="$t('labels[0]') || '查询内容'">
            <a-input
              @change="handleContentChange"
              v-decorator="[
                'content',
                {
                  initialValue: searchContent
                }
              ]"
              :placeholder="cPlaceholder"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col v-if="aValue == 3" :span="6" class="marLeft_20">
          <a-form-item :label="$t('cols[1]') || '出生日期'">
            <a-date-picker
              v-decorator="['birthDate']"
              :placeholder="$t('Modals[21]') || '请选择'"
              valueFormat="YYYY-MM-DD"
              :format="dateFormat"
            ></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col :span="7" class="marLeft_20" v-if="aValue !== 7">
          <a-form-item :label="$t('labels[11]') || '就诊日期'">
            <a-range-picker
              v-decorator="['rangeDate', { initialValue: initRangeDate }]"
              :placeholder="[
                $t('tips[27]') || '开始时间',
                $t('tips[28]') || '结束时间'
              ]"
              valueFormat="YYYY-MM-DD"
              :format="dateFormat"
              :allowClear="false"
            ></a-range-picker>
          </a-form-item>
        </a-col>

        <a-col :span="2" class="marLeft_20">
          <a-button html-type="submit" type="primary">{{
            $t('btns[0]') || '查询'
          }}</a-button>
        </a-col>
        <a-col :span="2">
          <a-button type="primary" ghost @click="resetConditions">{{
            $t('btns[18]') || '重置'
          }}</a-button>
        </a-col>
      </a-row>
    </a-form>
    <a-table
      :rowKey="record => record.claimsId"
      style="margin-top: 20px;"
      :scroll="{ x: 1600 }"
      :columns="columns"
      :dataSource="data"
      @change="handlePageChange"
      :pagination="pagination"
    >
      <template slot="check" slot-scope="text, record, index">
        <a-checkbox
          :checked="selectedRowKeys.indexOf(record.claimsId) > -1"
          @change="e => handleRecordSelect(e, record, index)"
        ></a-checkbox>
      </template>
      <template slot="claimsId" slot-scope="text, record">
        <router-link :to="`/claimDetail/${text}?cardNo=${record.cardNo}`">{{
          text
        }}</router-link>
      </template>
      <template
        v-for="col in [
          'dueTotal',
          'paidToClaimant',
          'partienPrepaid',
          'selfPaidMemoney',
          'paidByClaimant'
        ]"
        :slot="col"
        slot-scope="text"
      >
        {{ text | addCommas }}
      </template>
      <template slot="do" slot-scope="text, record">
        <claim-buttons
          v-on:onSuccess="fetchData"
          :record="record"
          type="1"
        ></claim-buttons>
      </template>
    </a-table>
    <claim-about-modals></claim-about-modals>
    <a-spin class="loading_" size="large" :spinning="spinning"></a-spin>
  </div>
</template>

<script>
import commonMixin from '@/mixins/common'
import ClaimAboutModals from './components/ClaimAboutModals'
import ClaimButtons from './components/ClaimButtons'
import authorizedMixin from '@/mixins/authorized'
import Bus from '@/utils/Bus'
import { MESSAGE_CHANNEL } from '@/utils/constants'
import moment from 'moment'

export default {
  data() {
    const columns = [
      {
        title: '',
        scopedSlots: { customRender: 'check' },
        width: '50px'
      },
      {
        title: this.$t('common.index'),
        width: '50px',
        customRender: (t, r, index) => {
          return parseInt(
            `${(this.pagination.current - 1) * this.pagination.pageSize +
              (index + 1)}`
          )
        }
      },
      {
        title: this.$t('claimCols[0]') || '理赔编号',
        dataIndex: 'claimsId',
        width: '110px',
        key: 'claimsId',
        sorter: true,
        scopedSlots: { customRender: 'claimsId' }
      },
      {
        title: this.$t('cols[0]') || '姓名',
        dataIndex: 'patientName',
        width: '110px',
        key: 'patientName',
        sorter: true
      },
      {
        title: this.$t('cols[8]') || '公司名称',
        dataIndex: 'corpName',
        width: '110px',
        key: 'corpName',
        sorter: true
      },
      {
        title: this.$t('cols[4]') || '保险卡号',
        dataIndex: 'cardNo',
        width: '110px',
        key: 'cardNo',
        sorter: true
      },
      {
        title: this.$t('cols[1]') || '生日',
        dataIndex: 'birthDate',
        width: '110px',
        key: 'birthDate',
        sorter: true
      },
      {
        title: this.$t('labels[11]') || '就诊日期',
        dataIndex: 'admissionDate',
        width: '110px',
        key: 'admissionDate',
        sorter: true
      },
      {
        title: this.$t('claimCols[1]') || '账单金额',
        dataIndex: 'dueTotal',
        width: '110px',
        key: 'dueTotal',
        scopedSlots: { customRender: 'dueTotal' },
        sorter: true
      },
      {
        title: this.$t('claimCols[1]') || 'MRN',
        dataIndex: 'mrn',
        key: 'mrn',
        width: '110px',
        scopedSlots: { customRender: 'mrn' },
        sorter: true
      },
      {
        title: this.$t('cols[14]') || '账单号',
        dataIndex: 'hospBillNo',
        key: 'hospBillNo',
        width: '110px',
        sorter: true
      },
      {
        title: this.$t('claimCols[2]') || '赔付金额',
        dataIndex: 'paidToClaimant',
        key: 'paidToClaimant',
        width: '110px',
        scopedSlots: { customRender: 'paidToClaimant' },
        sorter: true
      },
      {
        title: this.$t('claimCols[3]') || '自负金额',
        dataIndex: 'paidByClaimant',
        width: '110px',
        key: 'paidByClaimant',
        scopedSlots: { customRender: 'paidByClaimant' },
        sorter: true
      },
      {
        title: this.$t('claimCols[4]') || '客户已付金额',
        dataIndex: 'partienPrepaid',
        width: '110px',
        key: 'partienPrepaid',
        scopedSlots: { customRender: 'partienPrepaid' },
        sorter: true
      },
      {
        title: this.$t('claimCols[5]') || '应收自付额',
        dataIndex: 'selfPaidMemoney',
        width: '110px',
        key: 'selfPaidMemoney',
        scopedSlots: { customRender: 'selfPaidMemoney' },
        sorter: true
      },
      {
        title: this.$t('labels[10]') || '中间带交易号',
        dataIndex: 'approval',
        width: '110px',
        key: 'approval',
        sorter: true
      },
      {
        title: this.$t('claimCols[6]') || '中间带付款日期',
        dataIndex: 'chequeDate',
        width: '110px',
        key: 'chequeDate',
        sorter: true
      },
      {
        title: this.$t('claimCols[7]') || '保险人',
        dataIndex: 'longTime',
        width: '110px',
        key: 'longTime',
        sorter: true
      },
      {
        title: this.$t('claimCols[8]') || '保险责任',
        dataIndex: 'coverageName',
        width: '110px',
        key: 'coverageName',
        sorter: true
      },
      {
        title: this.$t('claimDetail[10]') || '赔付结论',
        dataIndex: 'claimsResult',
        width: '110px',
        key: 'claimsResult',
        sorter: true
      },
      {
        title: this.$t('claimCols[10]') || '审核进度',
        dataIndex: 'appLoadingName',
        width: '110px',
        key: 'appLoadingName',
        sorter: true
      },
      {
        title: this.$t('claimCols[10]') || '医院名称',
        dataIndex: 'providerName',
        width: '110px',
        key: 'providerName'
      },
      {
        width: 100,
        title: this.$t('cols[7]') || '操作',
        dataIndex: 'do',
        key: 'do',
        fixed: 'right',
        scopedSlots: { customRender: 'do' }
      }
    ]
    return {
      initRangeDate: [
        moment()
          .subtract('year', 1)
          .format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      conFlag: '1',
      // visibleChange: false,
      // changeCostForm: this.$form.createForm(this),
      showUploadDialog: false, // 批量上传弹框显示
      selectedRowKeys: [],
      columns,
      data: [],
      unReceMessageSize: undefined,
      noBillSize: undefined,
      refsize: undefined,
      closedCaseSize: undefined,
      underReviewSize: undefined,
      claimSize: undefined,
      claimsRejectedSize: undefined,
      id: 1,
      aValue: 1,
      searchForm: this.$form.createForm(this),
      cPlaceholder: this.$t('tips[10]') || '请输入保险卡号',
      pagination: {
        pageSize: 10,
        total: 0,
        currentPage: 1,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '150', '200'],
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      },
      pageOrderBy: '',
      dateFormat: ['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYYMMDD']
    }
  },
  mixins: [commonMixin, authorizedMixin],
  components: {
    'claim-about-modals': ClaimAboutModals,
    'claim-buttons': ClaimButtons
  },
  watch: {
    // 监听筛选条件
    conFlag: function(val) {
      // 表格数据是否有mrn
      const isMrn = this.columns.some(item => item.dataIndex === 'mrn')
      // MRN字段对象
      const obj = {
        title: 'MRN',
        dataIndex: 'mrn',
        key: 'mrn',
        sorter: true
      }
      // 如果选择 未收件 || 补件
      if (['1', '2', '3'].indexOf(val) > -1) {
        // MRN字段不存在，则插入到表头数据
        if (!isMrn) {
          this.columns.splice(3, 0, obj)
        }
      } else {
        if (!isMrn) {
          return true
        }
        // 如果选择的是其他，MRN字段存在，则去除
        this.columns = this.columns.filter(item => item.dataIndex !== 'mrn')
      }
    }
  },
  mounted() {
    this.fetchData()
    this.$apis.getMedicialMedicialInfo({}).then(res1 => {
      const {
        unReceMessageSize,
        noBillSize,
        refsize,
        closedCaseSize,
        underReviewSize,
        claimSize,
        claimsRejectedSize
      } = res1.data.data
      this.unReceMessageSize = unReceMessageSize
      this.noBillSize = noBillSize
      this.refsize = refsize
      this.closedCaseSize = closedCaseSize
      this.underReviewSize = underReviewSize
      this.claimSize = claimSize
      this.claimsRejectedSize = claimsRejectedSize
    })
  },
  methods: {
    moment,
    showPrintModal(printUrl) {
      // 打印理赔、结算
      Bus.$emit(MESSAGE_CHANNEL, {
        type: 'handlePrint',
        data: {
          printUrl,
          batchPrint: true
        },
        title: this.$t('btns[5]') || '批量打印理赔解释',
        printType: 'EOB'
      })
    },
    batchPrint() {
      this.$apis
        .batchPrint({
          cIds: this.selectedRowKeys.join(','),
          printType: '4022'
        })
        .then(res => res.data)
        .then(data => {
          this.showPrintModal(data.data.printUrl)
          // this.printUrl = data.data.printUrl
        })
    },
    handleStatusChange() {
      this.pagination.current = 1
      this.fetchData()
    },
    handlePageChange(page, filters, sorter) {
      if (!this.data || !this.data.length) {
        return false
      }
      this.pagination.current = page.current
      let { field, order } = sorter
      field = field === 'appLoadingName' ? 'apploading' : field
      order = order ? (order === 'ascend' ? 'asc' : 'desc') : undefined
      this.pageOrderBy = order ? field + ' ' + order : ''
      this.fetchData()
    },
    handleSearchForm(e) {
      e && e.preventDefault()
      this.pagination.current = 1
      this.fetchData()
    },
    exportData() {
      const params = this.fetchData(1, 1)
      this.$apis
        .exportData({
          ...params
        })
        .then(res => {
          const blob = new Blob([res.data], { type: 'application/zip' })
          const filename = res.headers['content-disposition']
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) //创建下载的链接
          downloadElement.href = href
          ;[downloadElement.download] = [filename.split('=')[1]]
          document.body.appendChild(downloadElement)
          downloadElement.click() //点击下载
          document.body.removeChild(downloadElement) //下载完成移除元素
          window.URL.revokeObjectURL(href) //释放blob对象
        })
    },
    fetchData(no, returnOptions = 0) {
      let params = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        conFlag: this.conFlag,
        pageOrderBy: this.pageOrderBy
      }
      let content = undefined
      this.searchForm.validateFields((err, options) => {
        if (!err) {
          if (options) {
            switch (this.aValue) {
              case 1:
                content = options.content.split('-').join('')
                params.cardNo = content == '80001428' ? '' : content
                break
              case 2:
                params.idNumber = options.content
                break
              case 3:
                params.name = options.content
                break
              case 4:
                params.policyNo = options.content
                break
              case 5:
                params.disabilityNo = options.content
                break
              case 6:
                params.approval = options.content
                break
              case 7:
                params.claimsId = options.content
                break
              case 8:
                params.hospBillNo = options.content
                break
            }
            if (options.rangeDate && options.rangeDate.length > 1) {
              params.startTime = options.rangeDate[0]
              params.endTime = options.rangeDate[1]
            }
            if (options.idType) {
              params.idType = options.idType
            }
            if (options.birthDate) {
              params.birthDate = options.birthDate
            }
          }
        }
      })
      if (returnOptions) {
        return params
      }
      this.$apis
        .getMedicialRecordList(params)
        .then(res => res.data.data)
        .then(data => {
          this.data = data.medicialRecordVoList
          this.pagination.total = data.totalSize
        })
        .catch(() => {
          this.data = []
          this.pagination.total = 0
        })
    },
    handleRecordSelect(e, record, index) {
      if (this.selectedRowKeys.indexOf(record.claimsId) > -1) {
        this.selectedRowKeys.splice(index, 1)
      } else {
        this.selectedRowKeys.push(record.claimsId)
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    onSelectAll(e) {
      if (e.target.checked) {
        this.selectedRowKeys = this.data.map(item => item.claimsId)
      } else {
        this.selectedRowKeys = []
      }
    },
    onAChange(e) {
      const val = e.target.value
      let str = ''
      this.searchContent = ''
      this.searchForm.setFieldsValue({
        content: ''
      })
      switch (val) {
        case 1:
          str = this.$t('tips[10]') || '请输入保险卡号'
          this.searchContent = '8000-1428'
          this.searchForm.setFieldsValue({
            content: '8000-1428'
          })
          break
        case 2:
          str = this.$t('tips[11]') || '请输入客户证件号'
          break
        case 3:
          str = this.$t('tips[12]') || '请输入客户姓名'
          break
        case 4:
          str = this.$t('tips[13]') || '请输入客户保单号'
          break
        case 5:
          str = this.$t('tips[14]') || '请输入客户病历号'
          break
        case 6:
          str = this.$t('tips[40]') || '请输入凭单上APP#后面的数字'
          break
        case 7:
          str = this.$t('tips[60]') || '请输入理赔编号'
          break
      }
      this.cPlaceholder = str
    },
    resetConditions() {
      this.searchContent = ''
      this.searchForm.setFieldsValue({
        content: '',
        rangeDate: [
          moment()
            .subtract('year', 1)
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ]
      })
      switch (this.aValue) {
        case 1:
          this.searchContent = '8000-1428'
          this.searchForm.setFieldsValue({
            content: '8000-1428'
          })
          break
        case 2:
          this.searchForm.setFieldsValue({
            idType: ''
          })
          break
        case 3:
          this.searchForm.setFieldsValue({
            birthDate: null
          })
          break
      }
    }
  }
}
</script>
<style>
.upload-list-inline .ant-upload-list div {
  display: inline-block;
  margin-right: 20px;
  width: 300px;
}
</style>
<style lang="scss" scoped>
.why-icon {
  width: 18px;
  vertical-align: middle;
  margin-top: -4px;
}
.margin-right5 {
  margin-right: 5px;
}
.page_ {
  .pad {
    padding: 10px 15px;
  }
}
.algin_left {
  text-align: right;
}
</style>
