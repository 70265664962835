import Vue from 'vue'
import Antd from './Antd'
import Viewer from './Viewer'
import Map from './Map/index.vue'
import UserAvatar from './UserAvatar.vue'
import WelfareDetail from './WelfareDetail.vue'

const components = {
  Map,
  UserAvatar,
  WelfareDetail
}

export default () => {
  Antd()
  Viewer()
  for(let i in components) {
    Vue.component(i, components[i])
  }
}