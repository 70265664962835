export default {
  HOME: [
    "Home page",
    `Strive to be China's best health insurance solution provider`,
    "Unique MediLink-Global",
    "One of the best global Third-Party Administration (TPA) services providers of international health insurance",
    "NETWORK HOSPITAL", // 5
    "North America",
    "Africa",
    "Europe",
    "Asia",
    "Middle East", // 10
    "Oceania",
    "South America",
    "226 countries and regions",
    "Network of China",
    "cities", // 15
    "Seeking In-network medical institutions | Join our network",
    "PRODUCTS AND SERVICES",
    "Provide Third-Party Administration (TPA) services of high-end health insurance",
    "Third-Party Administration (TPA) services",
    "PARTNER", // 20
    "Serve dozens of insurance clients, covering renowned domestic insurance companies and wholly foreign-owned insurance companies",
    "{0} panel providers",
    "medical institutions in ",
    "Membership services",
    "Corporate health services", // 25
    "Network organization inquiries",
    "MediLinkOnline",
    "Network of hospitals around the world",
    "MediLink 24H",
    "Official wechat account, click follow", // 30
    "Health problems, one step in place",
    "More",
    "Check the medical network",
    "Contact: 400 880 8820",
    "", //35
    "Click to view the American Hospital Network"
  ],
  about: [
    "About MediLink-Global",
    "Profile",
    "Development History",
    "Business Model",
    "Partners", // 5
    "Strive to be China's best health insurance solution provider",
    "About MediLink-Global",
    `MediLink (Beijing) TPA Services Co., Ltd. was founded in Beijing, China in 2005. It originated from Medilink-Global, Malaysia, which was famous for the leading ECCS system in the industry. MediLink officially became one of the group members of Nanyan Group following the acquisition completed in the end of 2019.
        As a traditional professional third party administration(TPA) company, MediLink has been focusing on customer services, upgrading core technology, creating data value and ensuring the security of customer’s data since its creation. We always place the independence, professionalism, client interests at the centre of our heart to provide TPA, direct-billing network and corporate health services to iclaims and hotlines for various insurance companies, multinational reinsurers and insurance brokers. And we have a good reputation with a leading brand name in the market.
        Currently, there are nearly 40 domestic and foreign insurance companies choose MediLink as their  direct-billing service provider for their members. Futhermore, there are more than 150,000 members and 3,200 corporate clients have received services from MediLink not only in China region but globally as well.`,
    "Mission",
    /*10*/ "Help medical insurance systems achieve maximum value through technological innovation and resource integration", // 10
    "Vision",
    "Our ambition is to develop, deploy and operate an electronic medical card network to become a global leader and a conductor as well as standards-setter in the TPA industry.",
    "Values",
    "Year"
  ],
  about1: [
    "Ensure the security and professionalism of data processing; bring greater value to partners; provide high-quality services for members",
    "Development History",
    "MediLink(Beijing)TPA Services co., Ltd was founded in Beijing, China.",
    "Established the direct-billing service and keeps the only provider in the market of real-time claim solution until now. ",
    /*5*/ "Golden sponsor of the Kuala Lumpur conference of Asian Insurance Medicine Seminar.",
    'Gained the "ISO9001:2008 system certification certificate" and "ISO / IEC27001 information security management system certification certificate".',
    "Golden sponsor of the Hong Kong conference of Asia Insurance Medicine Seminar.",
    "Joined Nanyan and became one of its subsidiaries in November 2019.",
    "Works with more than 40 insurance companies, providing professional health insurance services to more than 150,000 members and 3,200 large enterprises.",
    "Business model:", // 10
    "Insurant",
    "Enjoy the cash-less services of medical institutions and feel the convenience of receiving medical treatment",
    "Eliminate troublesome claims procedures;",
    "Avoid the embarrassment of cash insufficient .",
    "Medical", // 15
    "Improve patients’ experience of receiving medical treatment and their satisfaction with the  institutions;",
    "Bring stable business volume",
    "Cooperative relationship between international-standard medical institutions and insurance companies.",
    "Insurance companies",
    "Enhance the attractiveness of insurance products to policy holders;", // 20
    "Reduce administrative expenses in claims management;",
    "Achieve safe and convenient claims and settlement, reduce the risk of medical expenditure.",
    "Partners"
  ],
  videoCenter: ["Video Center", "Company activities"],
  PAS: [
    "Products and Services",
    "Third-Party Administration (TPA) services",
    "Membership Services",
    "Health Services",
    "Search Medical Networks", // 5
    "Third-Party Administration (TPA) services",
    "professional Third-Party Administration (TPA) services",
    "Service overview",
    "Medilink is principally involved in providing Third Party Claims Administration (TPA) services by operating a state-of-the-art electronic network infrastructure to facilitate healthcare claims processing.Medilink is presently the only Third Party Administrator with regional presence operating in China, having deployed China first secured and trusted electronic network and provides third-party health insurance management services for Chinese and foreign insurance companies and self-insurance companies in China, Greater China, Asia and even the world .",
    /*10*/ `The Medilinkl System facilitates online real-time eligibility verification and confirmation of benefits as well as automated adjudication of out-patient healthcare claims. Each member carries an electronic card which is presented to the registration desk in the Physicians Office when seeking treatment. The card shall be swiped at the EDC sends electronic signals to Medilink's back-end shall return an impulse back to the EDC Terminal within seconds.`,
    "Service introduction:",
    `On-line Pre-consultation eligibility verification On-line Post-consultation claims data capture and validation`,
    `Claim payment processing including direct payment service`,
    `Administrative arrangements for financial guarantees`,
    /*15*/ `Post-admission follow-up Pre-discharge - adjudication of hospital bills`,
    `Communication of disapproval or non-admissibility of medical diagnosis Post-discharge claims documentation`,
    `Benefits for members:`,
    /*18*/ `Online real-time identity verification`
  ],
  PAS1: [
    `Proven technology in terms of security, reliability, availability & confidentiality`,
    `Timely and Effective online real-time Reporting`,
    `Hassle free Registration and Paperless Administration`,
    `State of-the-art secured and trusted electronic healthcard network`,
    /*5*/ `Wide selection of network healthcare providers`,
    `Know-how and cross Regional Expertise to constantly conduct professional utilization review for better cost containment`,
    `Special service:`,
    `Introduction of the electronic claims management system`,
    `The MediLink system provides management services of medical claims and medical data for insurance companies, enterprises and third-party management institutions by supporting the electronic medical card network.`,
    /*10*/ `Safe and reliable techniques:`,
    `Each EDC terminal deployed in the medical institutions is installed with proprietary software of MediLink to verify the medical cards distributed by MediLink. Just like a credit card verification program, when the cardholder goes for medical advice, the front desk staff of the medical institution can use the MediLink’s medical card on the designated ECD terminal to confirm the cardholder’s identity.`,
    `The Medilink-Global’s Claims Management System (E.C.C.S.), which runs on the Oracle Database platform, is a complete suite of highly customized system designed for healthcare claims processing and healthcare data management. The software manages various databases pertaining to the Healthcare Payer and its members, their Health Plans, Benefits, Coverage, Exclusions, Specified Conditions and Conditions.`,
    `Benefits for users:`,
    `Electronic real-time identity confirmation and benefits confirmation`,
    /*15*/ `Flexible, universe and highly customizable`,
    `Provide highest-standard services through latest and best applications and techniques`,
    `Safe and reliable electronic medical network`,
    `How the system works:`,
    `The MediLink system consists of the front-end electronic data collection terminal (EDCT) network and the back data center supported by our customized electronic claims processing system.`,
    /*20*/ `Dental clinics`,
    `Medical management organizations (HMO)`,
    `Membership Services`,
    `Telephone consultation service`,
    "pharmacies",
    /*25*/ "examination rooms",
    "clinics",
    "hospitals",
    "insurance companies",
    "appraisal companies",
    /*30*/ "enterprise groups",
    "third-party management agencies (TPA)"
  ],
  PAS2: [
    `Professional trained gservice team`,
    `Member insurance benefits inquiry`,
    `Networked medical institution recommendation`,
    `Networked medical institution appointment and arrangement`,
    /*5*/ `Member-related questions and answers`,
    `Networked hospital information inquiry`,
    `Telephone Consultation Service`,
    `Telephone answer within 15 seconds`,
    `Failure rate `,
    /*10*/ `Single call processing rate`,
    `Hospital on-site service`,
    `List of resident hospitals`,
    `Official Accounts service`,
    `Scan the QR Code below，or search for【MedilinkOnline】on Wechat Service Platform. Click 【Follow】to enjoy online services.benefits enquiries、Hospital enquiries、Claims processing 、Pre-authorization `,
    /*15*/ `Corporate Health Services`,
    "(call back within two hours)",
    "on-site service in main networked hospitals",
    "member welfare inquiry",
    "identity confirmation",
    /*20*/ "medical guidance and language assistance",
    "self-paid amount collection",
    "deduction of insured amount",
    "Peking Union Medical College Hospital",
    "Shanghai east Hospita tongji  University",
    /*25*/ "Shanghai Landseed Hospital",
    "The first affiliated hospital soochou university",
    "China-Japan Friendship Hospital",
    "Ruijin Hospital",
    "Shanghai Jiaotong University School of Medicine",
    /*30*/ "Huashan Hospital Affiliated to Fudan University",
    "Children’s Hospital of Shanghai",
    "Beijing Anzhen Hospital",
    "Shanghai huadong Hospital",
    "Renji Hospital Affiliated to Shanghai Jiaotong University School of Medicine",
    /*35*/ "Suzhou Kowloon Hospital",
    "Shanghai Longhua Hospital",
    "benefits enquiries",
    "Claims processing",
    "Hospital search",
    "Hospital navigation",
    "FAQ"
  ],
  PAS3: [
    `Service overview`,
    `Maintaining health and treating diseases have always been the two main focus of MediLink-Global. While providing third-party management services for health insurance products, MediLink-Global focuses on tapping medical resources from its direct payment network and strives to provide insurance companies, corporate groups and individuals with value-added health-promoting and convenient medical services. By cooperating with medical institutions and partners, MediLink-Global summarizes and organizes online medical resources to form a variety of health and medical services to help customers get a healthy and convenient medical consultation.`,
    `Cross-border Medical Services`,
    `Among today's health conscious society, seeking healthcare treatment in a foreign country should be made as easy as seeking treatment in own country.`,
    /*5*/ `Medilink thus offers cross border healthcare solutions to all eligible members via its strategic tie-ups with leading healthcare providers in South East Asia, China and from as far as in the USA`,
    `The logistics and complexity involved in traveling from one country to another in seeking medical treatment is easy`,
    `How we work`,
    `Medilink-Global has special arrangements with selected healthcare providers not only in providing advice and also in facilitating and arranging for specialized medical treatment (secondary or tertiary care) at reputable medical institutions.In short, Medilink-Global will act as a one stop co-ordinator and facilitator for the Member in seeking advice on a particular specialized medical treatment.The actual expenses incurred when seeking medical services at these selected medical shall be the sole responsibility of the member.`,
    `Our services`,
    /*10*/ "identify renowned medical institution",
    "arrange medical appointment",
    "arrange specialized treatment",
    `Benefits for members`,
    `convenient access to MEDILINK's pre-established medical network specializing in secondary and tertiary care medical treatment. `,
    /*15*/ `Convenient access to the cross-border medical information.`,
    `On-stop arrangement for cross-border treatments. `,
    `Telephone consultation`,
    `membership confirmation`,
    `initiation of the service network`,
    /*20*/ `detailed service arrangement`,
    `diagnosis and treatment`
  ],
  TCMN: [
    "Traditional Chinese Medicine Network",
    "Service overview",
    `TCM, namely Traditional Chinese medicine, originating in China. Today, the number of people seeking TCM treatment is increasing rapidly. The theory of TCM originated thousands of years ago, which was formed through continuous exploration of nature, the universe and the human body. TCM treatment includes Chinese herbal medicine, acupuncture, diet therapy, finger-pressing massage, etc. Qigong and Taijiquan are also closely related to TCM. Though widely accepted in South Asia, TCM is only regarded as an alternative therapy in the medical system in many Western countries. After developing its business in China, MediLink-Global now can provide members with TCM medical institutions that can provide TCM treatments for various diseases.`,
    `How to realize?`,
    `MediLink-Global can arrange a selected TCM medical institution to provide medical advices as well as arrange treatments for various diseases.`,
    `MediLink-Global will collect your needed information and feedback to the doctor to recommend you to a corresponding and appropriate TCM medical institution based on your situation.`,
    `MediLink-Global will further help members seek treatments.`,
    `All the expenses incurred in the actual TCM treatments should be afforded by the member.`
  ],
  BFM: [
    "Benefits for Members",
    "Provide convenience for members to visit selected TCM medical institutions",
    "Traveling to China for medical cares will be easy with the assistance of MediLink-Global.",
    "Members can plan their treatments in advance and enjoy a relaxing trip.",
    "Access of TCM information will be easy via MediLink-Global.",
    "Second Medical Opinion",
    "Service overview",
    "The Second Medical Opinion service allows you obtain advices on your disease from the best experts of the world’s top medical institutions without leaving your home.",
    "How to get a second medical opinion?",
    "As a valid member of the Second Medical Opinion service, if you are diagnosed (or suspected) to have a serious disease, you can apply for a second medical opinion.",
    "Your disease must be a type included in the disease list we have provided. Once the requirements are met, MediLink-Global will collect all relevant medical records with your assistance and send them to the selected medical institution for a second medical opinion, based on your authorization.",
    "Generally speaking, the second medical opinion will be completed within ten working days after the selected medical institution has received the materials.",
    ""
  ],
  SOS: [
    "Scope of Services",
    "Provide reliable medical information for you, your family and your doctor.",
    "The related staff of the Second Medical Opinion service will complete the application for a second medical opinion for you, to save you from the trouble of long-distance travel for medical care.",
    "Usually the second medical opinion will be completed within ten working days after the selected medical institution received the materials.",
    "After the member received the second medical opinion, MediLink-Global will continue to provide hospitalization arrangements and other services within our capability.",
    ""
  ],
  BFM1: [
    "Benefits for Members",
    "Save the travel expenses and accommodation expenses for members seeking a second medical opinion.",
    "Relieve the psychological pressure of members and let members better understand the second medical opinion.",
    "Pre-existing disease or not, a second medical opinion can be applied for any disease as long as it’s included in the disease list of the Second Medical Opinion service.",
    ""
  ],
  EMA: [
    "Emergency medical assistance",
    "Service content introduction",
    "Emergency medical assistance (MEA) service is to provide assistance services to people who encounter sudden illness, accidental injury or have medical needs during travel, including transferring patients to medical institutions for treatment or transporting them back to their place of residence. The aim of the MEA service is to provide timely and appropriate solutions for people in urgent need of medical assistance or arrange them in time to transfer them to more suitable locations for treatment.",
    "Our services",
    /*5*/ "telephone doctor consultation service",
    "medical recommendation and appointment arrangement",
    "arrangement of doctor visits",
    "arrangement of admission guarantee",
    "arrangement of emergency medical evacuation",
    /*10*/ "medical condition tracking and conveying information to family members",
    "transporting remains abroad back to the country",
    "How we work?",
    "Emergency Medical Assistance",
    "The Emergency Medical Assistance of MediLink-Global covers the world. When an emergency medical need occurs, the assistance service can be quickly activated and the corresponding service can be arranged based on the detailed situation of the vehicle asking for help.",
    /*15*/ "24-Hour Multi-lingual Service",
    "MediLink-Global provides 24-hour multi-lingual assistance service. MediLink-Global can promptly provide online or on-site emergency medical assistance services or arrangements for members traveling abroad.",
    "Benefits for Members",
    "Overseas travel at ease and with peace of mind.",
    "Conveniently enjoy the XXX of MediLink-Global all over Southeast Asia, China and other countries",
    ""
  ],
  IC: [
    "Information center",
    "Recent news",
    "Media reports",
    "FAQs",
    "File download", // 4
    "September 2020 ",
    "August 2020 ",
    "July 2020 ",
    "June 2020 ",
    " | MediLink-Global New Direct Billing Hospital", //9
    "May 2021",
    "April 2021",
    "March 2021",
    "February 2021",
    "For more information", // 14
    "(a)",
    "(b)",
    "October 2020 ",
    "November 2020 ", //18
    "December 2020",
    "New Year's Day Holiday | Holiday Arrangements For Some Direct Billing Hospital In The MediLink-Global",
    "January 2021", //21
    "June 2021",
    "July 2021",
    "August 2021",
    "September 2021",
    "October 2021",
    "November 2021",
    "December 2021"
  ],
  FAQ: [
    "FAQs",
    "What is TPA?",
    "Third Party Administrators",
    `Third Party Administrators, TPA for short. It is widely adopted in European and American countries as a successful operating model of the financial service industry. It can make up for the gap between the insurance industry and medical institutions, bring benefits to both insurers and the insured. The insured  will get better medical and insurance services, while the management cost of the insurers will be reduced and the medical institutions will have a stable patient source (customer source) and income. Since the goal of multi-win can be easily achieved just with the help of TPA, it has been widely recognized by insurance companies, enterprises and individuals and medical institutions although it’s only a new thing in our country.`,
    `The origin of TPA?`, // 5
    `Because it is very difficult to control the risk from group health insurance, 
        insurance companies usually only provide very limited products with high prices, 
        therefore some large corporate groups have to set up their own health coverage 
        with company’s resources. Although this method is able to avoid complicated 
        insurance claim procedures and it is good to control the cash flow in the company, 
        but this system cannot handle some special risks, and sometimes due to the problems 
        during the internal claim procedure, the cost is also hard to be controlled... 
        Moreover, it is also hard to efficiently control medical expense risks since 
        insurance companies have not formed a community of shared interests with medical 
        service providers. More importantly, due to the fact that currently insurance 
        companies lack data accumulation related to diseases and medical expenses, 
        the actuarial foundation is not solid and because of their insufficient talent 
        accumulation and low degree of specialization, these companies are gradually 
        paying more attention to how to meet the needs of more and more customers 
        for better health care and are looking for guidance to better find the way of 
        health care. The emergence of TPA can alleviate this unbalance between supply 
        and demand to a certain extent. Because TPA is only responsible for management 
        and because insurance companies can accumulate valuable data through TPA, laying 
        a solid foundation for future development of health insurance products and setting 
        reasonable prices becomes very important. Clients trust their own health insurance 
        plans with a third-party management company that is experienced in this industry to 
        manage and let the latter choose a highly professional insurance company to be 
        responsible for underwriting and assuming the risks of payment. TPAs obtain 
        corresponding management expenses from insurance companies by providing professional 
        management services.`,
    `Who are benefited from third-party management?`,
    `Policyholders will benefit from the easier access to care and settlement of hospital bills. At network hospitals, policyholders will enjoy cashless settlement at discharge. Furthermore, the cost of medical insurance cover will be more affordable due to more efficient use of medical care.
        Insurers will be able to increase the service standards provided to their policyholders, thereby differentiating themselves from other insurers. Additionally, they will have more confidence in calculating the cost of providing medical insurance cover. This means insurers will be able to offer more affordable medical insurance cover to their customers.`,
    `What does direct-billing hospital mean?`,
    /*10*/ `direct-billing means that as long as you show your insurance card when seeking medical care in our networked hospitals, you can enjoy cash-free treatment and the hospital will bill us directly. You only need to pay the self-afforded part of the medicine expense and the expense not covered by the insurance.`,
    `File download`,
    "Medilink pre-authorization form ",
    "MGU pre-authorization form",
    "Medilink member reimbursement claim form",
    "MGU member reimbursement claim form"
  ],
  LOGIN: [
    "Login",
    "Member Login",
    "Institution Login",
    "Partner Login",
    /*5*/ "Head office address: 10th Floor, Jingtai tower, No. 24, Jianguomenwai Street, Chaoyang District, Beijing",
    "Contact: ",
    "Mail editor: ",
    "Fax: ",
    "Website: ",
    "Member/Institution/Partner Login"
  ],
  AMG: [
    "About MediLink-Global",
    "Profile",
    "Development History",
    "Business Model",
    "Partners", // 5
    "recruiting",
    "Video center",
    "Enterprise activity film",
    "Products and Services",
    "Third-Party Administration (TPA) services", // 10
    "Membership service",
    "The health service",
    "Check the Medical Provider Network",
    "The information center",
    "recent news", // 15
    "Media reports",
    "FAQs",
    "File download",
    "MedilinkOnline",
    "MediLink24H", // 20
    "TPA"
  ],
  ML: [
    "Direct-Billing Hospital network--Mainland area",
    "Join our network",
    "Mainland representative network hospital",
    "Please select province",
    "Please select city", // 5
    "Anhui",
    "Beijing",
    "Fujian",
    "Gansu",
    "Guangdong", // 10
    "Guangxi",
    "Guizhou",
    "Hainan",
    "Hebei",
    "Henan", // 15
    "Heilongjiang",
    "Hubei",
    "Hunan",
    "Jilin",
    "Jiangsu", // 20
    "Jiangxi",
    "Liaoning",
    "Inner Mongoria IM",
    "Ningxia",
    "Qinghai", // 25
    "Shandong",
    "Shanxi",
    "Shanxi",
    "Shanghai",
    "Sichuan", // 30
    "Tianjing",
    "Xinjiang",
    "Yunnan",
    "Zhejiang",
    "Chongqing", // 35
    "AoMen",
    "Hong Kong",
    "Taiwan",
    "Tibet"
  ],
  ML1: [
    "Please select nature",
    "all",
    "private",
    "public",
    "foreign", // 5
    "Please select level",
    "3A",
    "3B",
    "3C",
    "2A", // 10
    "2B",
    "2C",
    "1A",
    "1B",
    "1C", // 15
    "NO",
    "Please select category",
    "General Hospital",
    "Dental hospital",
    "Dental clinic", // 20
    "Specialized Hospital",
    "General Clinic",
    "specialist clinics",
    "Physical Examination Center",
    "PHARMACY", // 25
    "laboratory",
    "Maternity Hospital",
    "pediatric Hospital",
    "pediatric Clinic",
    "Women's and Children's Hospital", // 30
    "Orthopaedic Hospital",
    "rehabilitation Clinic",
    "eye hospital",
    "eye clinic",
    "TCM Clinic", // 35
    "Cancer Clinic",
    "TCM Hospital",
    "For more information",
    "Please enter the name of the hospital",
    "Please enter your departure"
  ],
  JOIN: [
    "How to join our network?",
    "Online Application Form",
    "MediLink Network department will contact you",
    "After the assessment, by the head of the network final review",
    "Collaboration feedback via email", // 5
    `If the intention of cooperation is reached, the contract will be signed offline
            Start filling out the application form`,
    "Apply now"
  ],
  OVERSEAS: [
    "Direct-Billing Hospital network--Overseas",
    "Representative Providers"
  ],
  distanceList: [
    "Within 5km",
    "Within 10km",
    "Within 15km",
    "Within 20km",
    "Within 30km",
    "The above of30km"
  ]
};
