<template functional>
  <a-sub-menu :key="props.menuInfo.key">
    <span slot="title">
      <span v-if="props.menuInfo.title === '业务查询'">
        <span>{{ props.menuInfo.title }}</span>
        <a
          class="mar_left10 underline"
          href="https://ssish.oss-cn-hangzhou.aliyuncs.com/eccs/background/Portal 实操指南（带授权版本）Cassie.pdf"
          target="_blank"
          >操作指南</a
        >
      </span>
      <span v-else>{{ props.menuInfo.title }}</span>
    </span>
    <template v-for="item in props.menuInfo.children">
      <a-menu-item v-if="!item.children" :key="item.key">
        <router-link :to="item.url">
          <span>{{ item.title }}</span>
        </router-link>
      </a-menu-item>
      <sub-menu v-else :key="item.key" :menu-info="item" />
    </template>
  </a-sub-menu>
</template>
<script>
export default {
  props: ['menuInfo']
}
</script>
<style lang="scss" scoped>
.mar_left10 {
  margin-left: 10px;
}
.underline {
  text-decoration: underline;
}
</style>
